import React from 'react'
import { Skeleton } from '@material-ui/lab';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        skeletonImage: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%'
        },
    })
);

const ProfileHeaderSkeleton: React.FC = () => {

    const classes = useStyles();

    return (
        <div className="relative px-3 desktop-container">
            <div className="relative w-full h-40 overflow-hidden rounded-t">
                <Skeleton variant="rect" className={classes.skeletonImage} />
            </div>
        </div>
    );
};

export default ProfileHeaderSkeleton;