import React, { useCallback, memo } from 'react'
import NotificationListener from './NotificationListener'
import { useUpsertFcmTokenMutation } from '../../generated/graphql';

const NotificationListenerContainer = memo(() => {

  const [upsertFcmToken] = useUpsertFcmTokenMutation();

  const onUpsertToken = useCallback(async (userId: number, deviceId: string, token: string) => {
		await upsertFcmToken({
			variables: {
				userId: userId,
				deviceId: deviceId,
				token: token
			}
		})
	}, [upsertFcmToken])

  return <NotificationListener onUpsertToken={onUpsertToken} />
})

export default NotificationListenerContainer
