import React, { createContext, useContext, useState, useCallback } from "react"

interface TabProviderContext {
    showTabs: boolean;
    onShowTabs: (show: boolean) => void
}

export const TabContext = createContext<TabProviderContext>({
    showTabs: true,
    onShowTabs: show => {return}
})

export const useTab = () => useContext(TabContext);

export const TabProvider: React.FC = ({ children }) => {

    const [showTabs, setShowTabs] = useState(true);

    const onShowTabs = useCallback((show: boolean) => {
        setShowTabs(show)
    }, [])

    return (
        <TabContext.Provider
            value={{
                showTabs,
                onShowTabs
            }}
        >
            {children}
        </TabContext.Provider>
    )
}