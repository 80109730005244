import React, { memo, useMemo, useCallback } from 'react';
import { createMuiTheme, ThemeProvider, Drawer } from '@material-ui/core';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { CheckoutProviderProps } from './context/CheckoutContext';
import CheckoutOverview from './CheckoutOverview';
import CheckoutPaymentMethod from './CheckoutPaymentMethod';
import CheckoutChangeCard from './CheckoutChangeCard';
import CheckoutAddCard from './CheckoutAddCard';
import CheckoutHeader from './CheckoutHeader';
import CheckoutChangePayPal from './CheckoutChangePayPal';
import CheckoutLoading from './CheckoutLoading';
import CheckoutAddPayPalAccount from './CheckoutAddPayPalAccount';
import CheckoutReceipt from './CheckoutReceipt';
import CheckoutError from './CheckoutError';
import { isPlatform } from '@ionic/react';


const Checkout: React.FC<CheckoutProviderProps> = memo((props) => {

    const { currentRoute, loading, onClose, showCheckout } = props;

    const theme = useMemo(() => {
        return createMuiTheme({
            palette: {
                type: 'light',
            },
        });
    }, []);

    const [ref, { height }] = useMeasure();

    const contentProps = useSpring({
        height,
        onRest: () => {
            if (currentRoute === 'add_card') {
                const event = new CustomEvent('checkoutFormAnimationEnd');
                window.dispatchEvent(event);
            }
        },
    });

    const onCheckoutClose = useCallback(() => {
        if (loading || currentRoute === "receipt") {
            return
        }
        onClose()
    }, [loading, currentRoute, onClose])

    return (
        <ThemeProvider theme={theme}>
            <Drawer anchor="bottom" open={showCheckout} onClose={onCheckoutClose}>
                <div className={`px-3 py-5 max-h-screen overflow-y-scroll w-full desktop-container ${isPlatform("capacitor") ? "safe-area-inset-bottom-padding" : ""}`}>
                    <animated.div style={{ ...contentProps }}>
                        <div ref={ref}>
                            <CheckoutHeader {...props} />
                            <div className="relative">
                                {currentRoute === 'overview' && <CheckoutOverview {...props} />}
                                {currentRoute === 'payment_method' && <CheckoutPaymentMethod {...props} />}
                                {currentRoute === 'change_card' && <CheckoutChangeCard {...props} />}
                                {currentRoute === 'add_card' && <CheckoutAddCard {...props} />}
                                {currentRoute === "change_paypal" && <CheckoutChangePayPal {...props} />}
                                {currentRoute === "add_paypal_account" && <CheckoutAddPayPalAccount {...props} />}
                                {currentRoute === "receipt" && <CheckoutReceipt {...props} />}
                                {currentRoute === "error" && <CheckoutError {...props} />}
                                {loading && <CheckoutLoading {...props} />}
                            </div>
                        </div>
                    </animated.div>
                </div>
            </Drawer>
        </ThemeProvider>
    );
});

export default Checkout;
