import React, { memo, useCallback, useMemo } from 'react';
import { CheckoutProviderProps } from './context/CheckoutContext';
import { ReactComponent as PayPalLogo } from 'payment-icons/min/single/paypal.svg';
import CreditCardLogo from './CreditCardLogo';
import { formatMoney, notEmpty } from '../../utils/Helpers';
import moment from 'moment-timezone';
import { BraintreeCreditCard, BraintreePayPalAccount, PaymentMethod } from '../../generated/graphql';
import { ReactComponent as VisaLogo } from 'payment-icons/min/single/visa.svg';
import { ReactComponent as AmExLogo } from 'payment-icons/min/single/amex.svg';
import { ReactComponent as MasterCardLogo } from 'payment-icons/min/single/mastercard.svg';
import { ReactComponent as ReleaseHubLogo } from '../../assets/social-logos/releasehub-dark.svg';
import { ReactComponent as AppleLogo } from '../../assets/social-logos/apple-black.svg';
import { ReactComponent as GooglePlayLogo } from '../../assets/social-logos/google-play.svg';
import { useAuth } from '../../firebase/Auth';
import { isPlatform } from '@ionic/react';

const CheckoutPaymentMethod: React.FC<CheckoutProviderProps> = memo(({ onGoForward, onSetBraintreePaymentMethod, onSetPaymentMethod, paymentMethod, customerData, onGetBraintreePaymentMethod, options }) => {

    const { user } = useAuth();

    const braintreePaymentMethod = useMemo(() => {
        return onGetBraintreePaymentMethod();
    }, [onGetBraintreePaymentMethod])

    const getPrimaryCard = useCallback(() => {
        // cards sorted by last updated
        const sortedCards = customerData?.user?.braintreeCustomer?.paymentMethods
            ?.filter((method) => method?.__typename === 'BraintreeCreditCard')
            .filter(notEmpty)
            .sort((left, right) => moment.utc(right.updatedAt).diff(moment.utc(left.updatedAt)));

        if (sortedCards?.length && sortedCards[0]) {
            const card = sortedCards[0] as BraintreeCreditCard;

            return (
                <div
                    className={`w-full p-2 border border-black border-solid rounded cursor-pointer ${paymentMethod === PaymentMethod.Braintree && braintreePaymentMethod?.token === card.token ? '' : 'border-opacity-10'}`}
                    onClick={() => onSetBraintreePaymentMethod(card)}
                >
                    <div className="flex">
                        <CreditCardLogo size={'sm'} type={card.cardType} />
                        <div className="flex flex-col w-full ml-3 text-sm">
                            <div className="flex items-center font-bold text-black">
                                <div className="ml-1">*{card.last4}</div>
                            </div>
                            <div className="text-black">{card.cardholderName}</div>
                            <div className="text-black">{card.expirationDate}</div>
                        </div>
                    </div>
                    <div className="h-px my-2 bg-black bg-opacity-10"></div>
                    <div className="flex justify-end">
                        <button
                            className="p-1 text-xs font-bold text-black uppercase border border-black border-solid rounded"
                            onClick={(e) => {
                                e.stopPropagation();
                                onGoForward('change_card');
                            }}
                        >
                            Change Card
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="w-full p-3 border border-black border-solid rounded cursor-pointer border-opacity-10" onClick={() => onGoForward('add_card')}>
                <div className="flex items-center">
                    <VisaLogo className={'w-auto h-8'} />
                    <AmExLogo className={'w-auto h-8 mx-3'} />
                    <MasterCardLogo className={'w-auto h-8'} />
                </div>
            </div>
        );
    }, [onGoForward, customerData, paymentMethod, braintreePaymentMethod, onSetBraintreePaymentMethod]);

    const getPrimaryPayPal = useCallback(() => {
        const sortedAccounts = customerData?.user?.braintreeCustomer?.paymentMethods
            ?.filter((method) => method?.__typename === 'BraintreePayPalAccount')
            .filter(notEmpty)
            .sort((left, right) => moment.utc(right.updatedAt).diff(moment.utc(left.updatedAt)));

        if (sortedAccounts?.length && sortedAccounts[0]) {
            const account = sortedAccounts[0] as BraintreePayPalAccount;

            const emailTeaser = account.email?.slice(0, 3);
            const emailDomain = account.email?.split('@')[1];

            return (
                <div
                    className={`w-full p-2 border border-black border-solid cursor-pointer rounded ${paymentMethod === PaymentMethod.Braintree && braintreePaymentMethod?.token === account.token ? '' : 'border-opacity-10'}`}
                    onClick={() => onSetBraintreePaymentMethod(account)}
                >
                    <div className={`flex items-center justify-between`}>
                        <PayPalLogo className="w-auto h-10" />
                        <div className="text-sm text-black">{`${emailTeaser}***@${emailDomain}`}</div>
                    </div>
                    <div className="h-px my-2 bg-black bg-opacity-10"></div>
                    <div className="flex justify-end">
                        <button
                            className="p-1 text-xs font-bold text-black uppercase border border-black border-solid rounded"
                            onClick={(e) => {
                                e.stopPropagation();
                                onGoForward('change_paypal');
                            }}
                        >
                            Change Account
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="flex items-center justify-between w-full p-2 border border-black border-solid rounded cursor-pointer border-opacity-10" onClick={() => onGoForward('add_paypal_account')}>
                <PayPalLogo className="w-auto h-10" />
            </div>
        );
    }, [customerData, onGoForward, onSetBraintreePaymentMethod, paymentMethod, braintreePaymentMethod]);

    return (
        <>
            <div className="grid grid-cols-1 gap-3">
                {options?.paymentMethods.has(PaymentMethod.InAppPurchase) && (
                    <>
                        <div className={`flex items-center justify-between w-full p-2 border cursor-pointer border-black border-solid rounded ${paymentMethod === PaymentMethod.InAppPurchase ? '' : 'border-opacity-10'}`}
                            onClick={() => onSetPaymentMethod(PaymentMethod.InAppPurchase)}
                        >
                            <div className="flex items-center my-1">
                                {isPlatform("ios") ? (
                                    <AppleLogo className="w-auto h-6 m-2" />
                                ) : (
                                    <GooglePlayLogo className="w-auto h-6 m-2" />
                                )}
                                <div className="ml-2 text-sm font-bold">In-App Purchase</div>
                            </div>
                        </div>
                    </>
                )}
                {options?.paymentMethods.has(PaymentMethod.Braintree) && (
                    <>
                        {getPrimaryCard()}
                        {getPrimaryPayPal()}
                    </>
                )}
                {options?.paymentMethods.has(PaymentMethod.Wallet) && (
                    <div className={`flex items-center justify-between w-full p-2 border cursor-pointer border-black border-solid rounded ${paymentMethod === PaymentMethod.Wallet ? '' : 'border-opacity-10'}`}
                        onClick={() => onSetPaymentMethod(PaymentMethod.Wallet)}
                    >
                        <div className="flex items-center my-1">
                            <ReleaseHubLogo className="w-auto h-6" />
                            <div className="ml-2 text-sm font-bold">Wallet</div>
                        </div>
                        <div>{formatMoney(user?.wallet)}</div>
                    </div>
                )}
            </div>
        </>
    );
});

export default CheckoutPaymentMethod;
