import React, { useEffect, memo } from 'react'
import { useHistory } from 'react-router-dom';
import { DynamicLinksEvent, DynamicLinks } from 'dynamic-links'

const DynamicLinkListener: React.FC = memo(() => {

    const history = useHistory();

    useEffect(() => {

        let ev = DynamicLinks.addListener('deepLinkOpen', (data: DynamicLinksEvent) => {
            if (data && data.url) {
                console.log("App:React.FC -> route", data.url)
                const route = data.url.split('https://encoreapp.co')[1];
                history.replace(route);
            }
        })

        return () => {
            ev.remove();
        }

    }, [history])

    return null
})

export default DynamicLinkListener
