import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/analytics";
import { FirebaseAppAuth } from 'firebase-app-auth';
import { isPlatform } from '@ionic/react';

const config = {
  apiKey: `${process.env.REACT_APP_FIREBASE_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
}

// Your web app's Firebase configuration
const app = firebase.initializeApp(config);
const analytics = firebase.analytics();

// configure for web login
if (!isPlatform("capacitor")) {
  FirebaseAppAuth.initializeFirebase(config);
}

export {
  app,
  analytics
};