import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: bigint;
};






export type Artist = {
  __typename?: 'Artist';
  id: Scalars['Int'];
  name: Scalars['String'];
  imageURL?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  spotifyId?: Maybe<Scalars['String']>;
  dynamicLinkEnding?: Maybe<Scalars['String']>;
  followers?: Maybe<Array<Maybe<UserArtist>>>;
  followerCount?: Maybe<Scalars['Int']>;
  /** Determines if a user follows an artist using ctx.user */
  following?: Maybe<Scalars['Boolean']>;
  releases?: Maybe<Array<Maybe<ArtistRelease>>>;
  releaseCount?: Maybe<Scalars['Int']>;
  rumors?: Maybe<Array<Maybe<ArtistRelease>>>;
  rumorCount?: Maybe<Scalars['Int']>;
  genres?: Maybe<Array<Maybe<Genre>>>;
};


export type ArtistReleasesArgs = {
  first?: Maybe<Scalars['Int']>;
};


export type ArtistRumorsArgs = {
  first?: Maybe<Scalars['Int']>;
};

export type ArtistRelease = {
  __typename?: 'ArtistRelease';
  artistId?: Maybe<Scalars['Int']>;
  releaseId?: Maybe<Scalars['Int']>;
  artist?: Maybe<Artist>;
  release?: Maybe<Release>;
};

export type ArtistInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  imageURL?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  spotifyId: Scalars['String'];
  genres: Array<Maybe<Scalars['String']>>;
};

export enum BraintreeTransactionStatus {
  AuthorizationExpired = 'authorization_expired',
  Authorized = 'authorized',
  Authorizing = 'authorizing',
  SettlementPending = 'settlement_pending',
  SettlementDeclined = 'settlement_declined',
  Failed = 'failed',
  GatewayRejected = 'gateway_rejected',
  ProcessorDeclined = 'processor_declined',
  Settled = 'settled',
  Settling = 'settling',
  SubmittedForSettlement = 'submitted_for_settlement',
  Voided = 'voided'
}

export type BraintreeTransaction = {
  __typename?: 'BraintreeTransaction';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BraintreeTransactionStatus>;
  type?: Maybe<Scalars['String']>;
  processorResponseText?: Maybe<Scalars['String']>;
};

export type BraintreeCreditCard = {
  __typename?: 'BraintreeCreditCard';
  cardholderName?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BraintreePayPalAccount = {
  __typename?: 'BraintreePayPalAccount';
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BraintreePaymentMethod = BraintreePayPalAccount | BraintreeCreditCard;

export type BraintreeCustomer = {
  __typename?: 'BraintreeCustomer';
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCards?: Maybe<Array<Maybe<BraintreeCreditCard>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<BraintreePaymentMethod>>>;
  paypalAccounts?: Maybe<Array<Maybe<BraintreePayPalAccount>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BraintreeTransactionInput = {
  userId: Scalars['Int'];
  token: Scalars['String'];
  sku: Scalars['String'];
  deviceData: Scalars['String'];
};

export type BraintreeValidatedResponse = {
  __typename?: 'BraintreeValidatedResponse';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  transaction?: Maybe<BraintreeTransaction>;
};

export type CreateBraintreeCustomerInput = {
  userId: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserAlbumFeed = {
  __typename?: 'UserAlbumFeed';
  userId?: Maybe<Scalars['Int']>;
  albumId?: Maybe<Scalars['Int']>;
  read?: Maybe<Scalars['Boolean']>;
};

export type FcmToken = {
  __typename?: 'FcmToken';
  id?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type Genre = {
  __typename?: 'Genre';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  artists?: Maybe<Array<Maybe<Artist>>>;
  releases?: Maybe<Array<Maybe<Release>>>;
};

export enum IapProductTransactionType {
  Apple = 'APPLE',
  Google = 'GOOGLE'
}

export type IapProductInput = {
  id: Scalars['String'];
  type: IapProductTransactionType;
  priceMicros?: Maybe<Scalars['Int']>;
  receipt: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  releaseId?: Maybe<Scalars['Int']>;
  release?: Maybe<Release>;
};

export enum VerificationStatus {
  InReview = 'IN_REVIEW',
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export type LinkedAccount = {
  __typename?: 'LinkedAccount';
  userId?: Maybe<Scalars['Int']>;
  artistId?: Maybe<Scalars['Int']>;
  status?: Maybe<VerificationStatus>;
  artist?: Maybe<Artist>;
  user?: Maybe<User>;
};

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['Int']>;
  taskName?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['Int']>;
  release?: Maybe<Release>;
};

export type CreateReleaseNotificationInput = {
  date?: Maybe<Scalars['DateTime']>;
};

export type NotificationInput = {
  id?: Maybe<Scalars['Int']>;
  taskName?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['Int']>;
};

export enum RoleName {
  Admin = 'ADMIN'
}

export type Role = {
  __typename?: 'Role';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<RoleName>;
};

export type ProductTransaction = {
  __typename?: 'ProductTransaction';
  productId?: Maybe<Scalars['Int']>;
  transactionId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['BigInt']>;
  product?: Maybe<Product>;
  transaction?: Maybe<Transaction>;
};

export type ProductTransactionInput = {
  productId?: Maybe<Scalars['Int']>;
  transactionId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['BigInt']>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['BigInt']>;
  transactions?: Maybe<Array<Maybe<ProductTransaction>>>;
};

export enum ReleaseStatus {
  InReview = 'IN_REVIEW',
  Similar = 'SIMILAR',
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Official = 'OFFICIAL'
}

export enum ReleaseType {
  Album = 'album',
  Single = 'single',
  Ep = 'ep',
  Compilation = 'compilation'
}

export enum EncoreStatus {
  Basic = 'BASIC',
  Plus = 'PLUS'
}

export enum CampaignStatus {
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  LowBudget = 'LOW_BUDGET',
  FailedOverBudget = 'FAILED_OVER_BUDGET',
  Pending = 'PENDING',
  PendingReschedule = 'PENDING_RESCHEDULE',
  Error = 'ERROR'
}

export enum ReleaseVisibility {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  Deleted = 'DELETED'
}

export type Release = {
  __typename?: 'Release';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  spotifyId?: Maybe<Scalars['String']>;
  recordLabel?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  dynamicLinkEnding?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['BigInt']>;
  estimatedCost?: Maybe<Scalars['BigInt']>;
  actualCost?: Maybe<Scalars['BigInt']>;
  customEnding?: Maybe<Scalars['String']>;
  visibility?: Maybe<ReleaseVisibility>;
  encore?: Maybe<EncoreStatus>;
  status?: Maybe<ReleaseStatus>;
  type?: Maybe<ReleaseType>;
  creatorId?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Boolean']>;
  campaignStatus?: Maybe<CampaignStatus>;
  creator?: Maybe<User>;
  /** Determines if a user supports a release using ctx.user */
  supporting?: Maybe<Scalars['Boolean']>;
  /** Determines if a user has added release to his collection using ctx.user */
  inCollection?: Maybe<Scalars['Boolean']>;
  /** Determines if a user has joined the waitlist for this release using ctx.user */
  waitListMethods?: Maybe<Array<Maybe<WaitList>>>;
  supporters?: Maybe<Array<Maybe<UserSupport>>>;
  totalSupporters?: Maybe<Scalars['Int']>;
  artists?: Maybe<Array<Maybe<ArtistRelease>>>;
  links?: Maybe<Array<Maybe<Link>>>;
  genres?: Maybe<Array<Maybe<Genre>>>;
  notification?: Maybe<Notification>;
  waitList?: Maybe<Array<Maybe<WaitList>>>;
  addOns?: Maybe<Array<Maybe<ReleaseAddOn>>>;
};

export type CreateReleaseInput = {
  title: Scalars['String'];
  date: Scalars['Date'];
  artistIds: Array<Scalars['Int']>;
  imageURL?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  links: Array<Scalars['String']>;
  type: ReleaseType;
  recordLabel?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  productSku?: Maybe<Scalars['String']>;
  visibility: ReleaseVisibility;
  notification?: Maybe<CreateReleaseNotificationInput>;
  addOns?: Maybe<Array<AddOnType>>;
};

export type UpdateReleaseInput = {
  id: Scalars['Int'];
  title: Scalars['String'];
  date: Scalars['Date'];
  artistIds: Array<Scalars['Int']>;
  imageURL?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  links: Array<Scalars['String']>;
  type: ReleaseType;
  recordLabel?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  visibility: ReleaseVisibility;
  notification?: Maybe<CreateReleaseNotificationInput>;
  addOns?: Maybe<Array<AddOnType>>;
};

export enum AddOnType {
  TextMarketing = 'TEXT_MARKETING',
  EmailMarketing = 'EMAIL_MARKETING',
  PushNotificationMarketing = 'PUSH_NOTIFICATION_MARKETING'
}

export type AddOn = {
  __typename?: 'AddOn';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<AddOnType>;
};

export type ReleaseAddOn = {
  __typename?: 'ReleaseAddOn';
  addOnId?: Maybe<Scalars['Int']>;
  releaseId?: Maybe<Scalars['Int']>;
  release?: Maybe<Release>;
  addOn?: Maybe<AddOn>;
};

export type AlgoliaSearchResponse = {
  __typename?: 'AlgoliaSearchResponse';
  ObjectID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
};

export type SearchResponse = AlbumSearch | ArtistSearch;

export type AlbumSearch = {
  __typename?: 'AlbumSearch';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  artists?: Maybe<Array<Maybe<ArtistSearch>>>;
};

export type ArtistSearch = {
  __typename?: 'ArtistSearch';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
};

export enum HistoryType {
  Artist = 'ARTIST',
  Album = 'ALBUM'
}

export type ArtistSearchHistory = {
  __typename?: 'ArtistSearchHistory';
  id?: Maybe<Scalars['Int']>;
  artistId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  artist?: Maybe<Artist>;
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type TextPricing = {
  __typename?: 'TextPricing';
  country?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['BigInt']>;
};

export type Song = {
  __typename?: 'Song';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  appleId?: Maybe<Scalars['String']>;
  spotifyId?: Maybe<Scalars['String']>;
  isrc?: Maybe<Scalars['String']>;
  previewURL?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Scalars['Int']>;
  release?: Maybe<Release>;
};

export type SpotifyExternalUrl = {
  __typename?: 'SpotifyExternalURL';
  spotify?: Maybe<Scalars['String']>;
};

export type SpotifyFollowers = {
  __typename?: 'SpotifyFollowers';
  href?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SpotifyImage = {
  __typename?: 'SpotifyImage';
  height?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type SpotifyArtistSimplifiedInterface = {
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyArtistSimplified = SpotifyArtistSimplifiedInterface & {
  __typename?: 'SpotifyArtistSimplified';
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyArtistFull = SpotifyArtistSimplifiedInterface & {
  __typename?: 'SpotifyArtistFull';
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  followers?: Maybe<SpotifyFollowers>;
  genres?: Maybe<Array<Maybe<Scalars['String']>>>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  popularity?: Maybe<Scalars['Int']>;
};

export enum SpotifyAlbumType {
  Album = 'album',
  Single = 'single',
  Compilation = 'compilation'
}

export enum SpotifyAlbumGroup {
  Album = 'album',
  Single = 'single',
  Compilation = 'compilation',
  AppearsOn = 'appears_on'
}

export enum SpotifyCopyrightType {
  C = 'C',
  P = 'P'
}

export type SpotifyCopyright = {
  __typename?: 'SpotifyCopyright';
  text?: Maybe<Scalars['String']>;
  type?: Maybe<SpotifyCopyrightType>;
};

export type SpotifyExternalId = {
  __typename?: 'SpotifyExternalID';
  isrc?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
};

export enum SpotifyAlbumReleaseDatePrecision {
  Year = 'year',
  Month = 'month',
  Day = 'day'
}

export type SpotifyAlbumSimplifiedInterface = {
  type?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  uri?: Maybe<Scalars['String']>;
  album_group?: Maybe<SpotifyAlbumGroup>;
  album_type?: Maybe<SpotifyAlbumType>;
  artists?: Maybe<Array<Maybe<SpotifyArtistSimplified>>>;
  available_markets?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  name?: Maybe<Scalars['String']>;
  release_date?: Maybe<Scalars['String']>;
  release_date_precision?: Maybe<SpotifyAlbumReleaseDatePrecision>;
  restrictions?: Maybe<SpotifyRestriction>;
};

export type SpotifyAlbumSimplified = SpotifyAlbumSimplifiedInterface & {
  __typename?: 'SpotifyAlbumSimplified';
  type?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  uri?: Maybe<Scalars['String']>;
  album_group?: Maybe<SpotifyAlbumGroup>;
  album_type?: Maybe<SpotifyAlbumType>;
  artists?: Maybe<Array<Maybe<SpotifyArtistSimplified>>>;
  available_markets?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  name?: Maybe<Scalars['String']>;
  release_date?: Maybe<Scalars['String']>;
  release_date_precision?: Maybe<SpotifyAlbumReleaseDatePrecision>;
  restrictions?: Maybe<SpotifyRestriction>;
};

export type SpotifyAlbumFull = SpotifyAlbumSimplifiedInterface & {
  __typename?: 'SpotifyAlbumFull';
  type?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  uri?: Maybe<Scalars['String']>;
  album_group?: Maybe<SpotifyAlbumGroup>;
  album_type?: Maybe<SpotifyAlbumType>;
  artists?: Maybe<Array<Maybe<SpotifyArtistSimplified>>>;
  available_markets?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  name?: Maybe<Scalars['String']>;
  release_date?: Maybe<Scalars['String']>;
  release_date_precision?: Maybe<SpotifyAlbumReleaseDatePrecision>;
  restrictions?: Maybe<SpotifyRestriction>;
  copyrights?: Maybe<Array<Maybe<SpotifyCopyright>>>;
  external_ids?: Maybe<SpotifyExternalId>;
  genres?: Maybe<Array<Maybe<Scalars['String']>>>;
  label?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  tracks?: Maybe<SpotifyTracksPagingObject>;
};

export type SpotifyTrackLink = {
  __typename?: 'SpotifyTrackLink';
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyRestriction = {
  __typename?: 'SpotifyRestriction';
  reason?: Maybe<Scalars['String']>;
};

export type SpotifyTrackSimplifiedInterface = {
  artists?: Maybe<Array<Maybe<SpotifyArtistSimplified>>>;
  available_markets?: Maybe<Array<Maybe<Scalars['String']>>>;
  disc_number?: Maybe<Scalars['Int']>;
  duration_ms?: Maybe<Scalars['Int']>;
  explicit?: Maybe<Scalars['Boolean']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_playable?: Maybe<Scalars['Boolean']>;
  linked_from?: Maybe<SpotifyTrackLink>;
  restrictions?: Maybe<SpotifyRestriction>;
  name?: Maybe<Scalars['String']>;
  preview_url?: Maybe<Scalars['String']>;
  track_number?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyTrackSimplified = SpotifyTrackSimplifiedInterface & {
  __typename?: 'SpotifyTrackSimplified';
  artists?: Maybe<Array<Maybe<SpotifyArtistSimplified>>>;
  available_markets?: Maybe<Array<Maybe<Scalars['String']>>>;
  disc_number?: Maybe<Scalars['Int']>;
  duration_ms?: Maybe<Scalars['Int']>;
  explicit?: Maybe<Scalars['Boolean']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_playable?: Maybe<Scalars['Boolean']>;
  linked_from?: Maybe<SpotifyTrackLink>;
  restrictions?: Maybe<SpotifyRestriction>;
  name?: Maybe<Scalars['String']>;
  preview_url?: Maybe<Scalars['String']>;
  track_number?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyTrackFull = SpotifyTrackSimplifiedInterface & {
  __typename?: 'SpotifyTrackFull';
  artists?: Maybe<Array<Maybe<SpotifyArtistSimplified>>>;
  available_markets?: Maybe<Array<Maybe<Scalars['String']>>>;
  disc_number?: Maybe<Scalars['Int']>;
  duration_ms?: Maybe<Scalars['Int']>;
  explicit?: Maybe<Scalars['Boolean']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_playable?: Maybe<Scalars['Boolean']>;
  linked_from?: Maybe<SpotifyTrackLink>;
  restrictions?: Maybe<SpotifyRestriction>;
  name?: Maybe<Scalars['String']>;
  preview_url?: Maybe<Scalars['String']>;
  track_number?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  album?: Maybe<SpotifyAlbumSimplified>;
  external_ids?: Maybe<SpotifyExternalId>;
  is_local?: Maybe<Scalars['Boolean']>;
};

export type SpotifyUser = {
  __typename?: 'SpotifyUser';
  display_name?: Maybe<Scalars['String']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  followers?: Maybe<SpotifyFollowers>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyPlaylistTracks = {
  __typename?: 'SpotifyPlaylistTracks';
  href?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SpotifyPlaylistSimplified = {
  __typename?: 'SpotifyPlaylistSimplified';
  collaborative?: Maybe<Scalars['Boolean']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<SpotifyUser>;
  public?: Maybe<Scalars['Boolean']>;
  snapshot_id?: Maybe<Scalars['String']>;
  tracks?: Maybe<SpotifyPlaylistTracks>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyTracksPagingObject = {
  __typename?: 'SpotifyTracksPagingObject';
  href?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<SpotifyTrackFull>>>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  previous?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SpotifyArtistsPagingObject = {
  __typename?: 'SpotifyArtistsPagingObject';
  href?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<SpotifyArtistFull>>>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  previous?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SpotifyAlbumsPagingObject = {
  __typename?: 'SpotifyAlbumsPagingObject';
  href?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<SpotifyAlbumSimplified>>>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  previous?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SpotifyPlaylistsPagingObject = {
  __typename?: 'SpotifyPlaylistsPagingObject';
  href?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<SpotifyPlaylistSimplified>>>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  previous?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SpotifySearchResponse = {
  __typename?: 'SpotifySearchResponse';
  artists?: Maybe<SpotifyArtistsPagingObject>;
  albums?: Maybe<SpotifyAlbumsPagingObject>;
  playlists?: Maybe<SpotifyPlaylistsPagingObject>;
  tracks?: Maybe<SpotifyTracksPagingObject>;
};

export enum SpotifySearchType {
  Album = 'album',
  Artist = 'artist',
  Playlist = 'playlist',
  Track = 'track'
}

export enum PaymentMethod {
  Braintree = 'BRAINTREE',
  InAppPurchase = 'IN_APP_PURCHASE',
  Wallet = 'WALLET'
}

export type Transaction = {
  __typename?: 'Transaction';
  id?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ProductTransaction>>>;
  total?: Maybe<Scalars['BigInt']>;
  date?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type CreateTransactionProductInput = {
  sku: Scalars['String'];
  quantity: Scalars['Int'];
};

export type UserSupport = {
  __typename?: 'UserSupport';
  releaseId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  release?: Maybe<Release>;
  user?: Maybe<User>;
};

export type UserArtist = {
  __typename?: 'UserArtist';
  artistId: Scalars['Int'];
  userId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  artist?: Maybe<Artist>;
  user?: Maybe<User>;
};

export enum AccountType {
  Fan = 'FAN',
  Artist = 'ARTIST'
}

export type UserCollection = {
  __typename?: 'UserCollection';
  releaseId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  release?: Maybe<Release>;
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  dailyDigest?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  wallet?: Maybe<Scalars['BigInt']>;
  type?: Maybe<AccountType>;
  reputation?: Maybe<Scalars['Int']>;
  releaseThreshold?: Maybe<Scalars['Int']>;
  bgColor?: Maybe<Scalars['String']>;
  supporting?: Maybe<Array<Maybe<Release>>>;
  supportingCount?: Maybe<Scalars['Int']>;
  encoreReleases?: Maybe<Array<Maybe<Release>>>;
  following?: Maybe<Array<Maybe<Artist>>>;
  followingCount?: Maybe<Scalars['Int']>;
  collection?: Maybe<Array<Maybe<Release>>>;
  collectionCount?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  linkedAccounts?: Maybe<Array<Maybe<LinkedAccount>>>;
  fcmTokens?: Maybe<Array<Maybe<FcmToken>>>;
  waitList?: Maybe<Array<Maybe<WaitList>>>;
  braintreeCustomer?: Maybe<BraintreeCustomer>;
  searchHistory?: Maybe<Array<Maybe<Artist>>>;
};


export type UserSupportingArgs = {
  first?: Maybe<Scalars['Int']>;
};


export type UserEncoreReleasesArgs = {
  first?: Maybe<Scalars['Int']>;
};


export type UserFollowingArgs = {
  first?: Maybe<Scalars['Int']>;
};


export type UserCollectionArgs = {
  first?: Maybe<Scalars['Int']>;
};

export type UpdateUserSettingsInput = {
  id: Scalars['Int'];
  email: Scalars['String'];
  phone: Scalars['String'];
  username: Scalars['String'];
  imageURL: Scalars['String'];
  bgColor: Scalars['String'];
  releaseThreshold: Scalars['Int'];
  bio: Scalars['String'];
  dailyDigest: Scalars['Boolean'];
};

export type UpdateAnonUserSettingsInput = {
  id: Scalars['Int'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type OAuthCredential = {
  __typename?: 'OAuthCredential';
  refresh_token?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['Long']>;
  access_token?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  id_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type ContactMailInput = {
  email: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
};

export enum NotificationMethod {
  TextMessage = 'TEXT_MESSAGE',
  PushNotification = 'PUSH_NOTIFICATION',
  Email = 'EMAIL'
}

export type WaitList = {
  __typename?: 'WaitList';
  userId?: Maybe<Scalars['Int']>;
  releaseId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  method?: Maybe<NotificationMethod>;
  user?: Maybe<User>;
  release?: Maybe<Release>;
};

export type ReleaseConnection = {
  __typename?: 'ReleaseConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<Maybe<ReleaseEdge>>>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type ReleaseEdge = {
  __typename?: 'ReleaseEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node?: Maybe<Release>;
};

/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
  endCursor?: Maybe<Scalars['String']>;
};

export type ArtistConnection = {
  __typename?: 'ArtistConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<Maybe<ArtistEdge>>>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type ArtistEdge = {
  __typename?: 'ArtistEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node?: Maybe<Artist>;
};

export type Query = {
  __typename?: 'Query';
  artist?: Maybe<Artist>;
  artistRumors?: Maybe<ReleaseConnection>;
  artistReleases?: Maybe<ReleaseConnection>;
  linkedAccount?: Maybe<LinkedAccount>;
  product?: Maybe<Product>;
  encoreProducts?: Maybe<Array<Maybe<Product>>>;
  release?: Maybe<Release>;
  similarSubmissions?: Maybe<Array<Maybe<Release>>>;
  releaseExists?: Maybe<Scalars['Boolean']>;
  featuredReleases?: Maybe<Array<Maybe<Release>>>;
  releaseArtists?: Maybe<Array<Maybe<ArtistRelease>>>;
  campaignNameExists?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Array<Maybe<ArtistSearch>>>;
  textPricing: Array<TextPricing>;
  spotifySearch?: Maybe<SpotifySearchResponse>;
  spotifyArtistExists?: Maybe<Scalars['Boolean']>;
  artistSuggestions?: Maybe<Array<Maybe<Artist>>>;
  unAuthArtistSuggestions?: Maybe<Array<Maybe<Artist>>>;
  user?: Maybe<User>;
  loggedInUser?: Maybe<User>;
  userRumorFeed?: Maybe<ReleaseConnection>;
  userReleaseFeed?: Maybe<ReleaseConnection>;
  userEncore?: Maybe<ReleaseConnection>;
  userRumors?: Maybe<ReleaseConnection>;
  userFollowing?: Maybe<ArtistConnection>;
  userCollection?: Maybe<ReleaseConnection>;
  usernameExists?: Maybe<Scalars['Boolean']>;
  userOnWaitList?: Maybe<Array<Maybe<WaitList>>>;
  userReleaseWaitListNotifications?: Maybe<Array<Maybe<WaitList>>>;
  test?: Maybe<Scalars['Boolean']>;
  estimatedCost?: Maybe<Scalars['Boolean']>;
};


export type QueryArtistArgs = {
  id: Scalars['Int'];
};


export type QueryArtistRumorsArgs = {
  artistId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryArtistReleasesArgs = {
  artistId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryLinkedAccountArgs = {
  userId: Scalars['Int'];
  artistId: Scalars['Int'];
};


export type QueryProductArgs = {
  sku: Scalars['String'];
};


export type QueryEncoreProductsArgs = {
  productIds: Array<Scalars['String']>;
};


export type QueryReleaseArgs = {
  id: Scalars['Int'];
};


export type QuerySimilarSubmissionsArgs = {
  artistIds: Array<Maybe<Scalars['Int']>>;
  date: Scalars['Date'];
  title: Scalars['String'];
};


export type QueryReleaseExistsArgs = {
  artistIds: Array<Maybe<Scalars['Int']>>;
  date: Scalars['Date'];
  title: Scalars['String'];
};


export type QueryFeaturedReleasesArgs = {
  take: Scalars['Int'];
};


export type QueryReleaseArtistsArgs = {
  releaseId: Scalars['Int'];
};


export type QueryCampaignNameExistsArgs = {
  campaignName: Scalars['String'];
};


export type QuerySearchArgs = {
  q: Scalars['String'];
};


export type QuerySpotifySearchArgs = {
  q: Scalars['String'];
  type: Array<Maybe<SpotifySearchType>>;
};


export type QuerySpotifyArtistExistsArgs = {
  spotifyId: Scalars['String'];
};


export type QueryArtistSuggestionsArgs = {
  userId: Scalars['Int'];
};


export type QueryUnAuthArtistSuggestionsArgs = {
  take?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryLoggedInUserArgs = {
  uid: Scalars['String'];
};


export type QueryUserRumorFeedArgs = {
  userId: Scalars['Int'];
  releaseThreshold?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryUserReleaseFeedArgs = {
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryUserEncoreArgs = {
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryUserRumorsArgs = {
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryUserFollowingArgs = {
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryUserCollectionArgs = {
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryUsernameExistsArgs = {
  username: Scalars['String'];
};


export type QueryUserOnWaitListArgs = {
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
};


export type QueryUserReleaseWaitListNotificationsArgs = {
  releaseId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createArtist?: Maybe<Artist>;
  createBraintreeCustomer?: Maybe<User>;
  createBraintreeClientToken?: Maybe<Scalars['String']>;
  createBraintreePaymentMethod?: Maybe<User>;
  updateBraintreeDefaultPaymentMethod?: Maybe<User>;
  deleteBraintreePaymentMethod?: Maybe<User>;
  createBraintreeTransaction?: Maybe<User>;
  upsertFcmToken?: Maybe<FcmToken>;
  createInAppPurchaseTransaction?: Maybe<Scalars['Boolean']>;
  submitArtistVerification?: Maybe<User>;
  scheduleReleaseNotificationsCron?: Maybe<Scalars['Boolean']>;
  upsertNotification?: Maybe<Notification>;
  updateNotification?: Maybe<Notification>;
  createNotification?: Maybe<Notification>;
  releaseNotificationCloudTask?: Maybe<Scalars['Boolean']>;
  rescheduleNotification?: Maybe<Release>;
  createRelease?: Maybe<Release>;
  updateRelease?: Maybe<Release>;
  userReleaseFeedCloudTask?: Maybe<Scalars['Boolean']>;
  deleteRelease?: Maybe<Release>;
  confirmReleasesCron?: Maybe<Scalars['Boolean']>;
  confirmReleaseCloudTask?: Maybe<Scalars['Boolean']>;
  connectAlbumToRelease?: Maybe<Release>;
  updateReleaseScoreCron?: Maybe<Scalars['Boolean']>;
  updateReleaseScoreCloudTask?: Maybe<Scalars['Boolean']>;
  dailyDigestCron?: Maybe<Scalars['Boolean']>;
  dailyDigestCloudTask?: Maybe<Scalars['Boolean']>;
  addReleaseBudget?: Maybe<Release>;
  /** Updates the estimated cost for a single release */
  updateEstimatedReleaseCost?: Maybe<Release>;
  /** Updates the estimated cost for all releases */
  updateEstimatedReleaseCostsCron?: Maybe<Scalars['Boolean']>;
  bulkIndexArtist?: Maybe<Scalars['Boolean']>;
  createSearchHistory?: Maybe<User>;
  deleteSearchHistory?: Maybe<User>;
  updateTextPricing?: Maybe<Scalars['Boolean']>;
  createWalletTransaction?: Maybe<User>;
  createTransaction?: Maybe<Transaction>;
  createUser?: Maybe<User>;
  deleteUser?: Maybe<User>;
  deleteFirebaseUser?: Maybe<Scalars['Boolean']>;
  upsertUserAuth?: Maybe<User>;
  updateUserSettings?: Maybe<User>;
  updateAnonUserSettings?: Maybe<User>;
  userToRelease?: Maybe<Release>;
  userToCollection?: Maybe<Release>;
  userToWaitList?: Maybe<Release>;
  userToLinkedArtist?: Maybe<User>;
  /** [DEPRECATED] Calculates on the User object now. */
  updateUserReputation?: Maybe<Scalars['Boolean']>;
  updateUserReputationCloudTask?: Maybe<Scalars['Boolean']>;
  userToArtist?: Maybe<Artist>;
  userToArtistCloudTask?: Maybe<Scalars['Boolean']>;
  updatePhoneNumber?: Maybe<User>;
  updateEmail?: Maybe<User>;
  addWalletBudget?: Maybe<User>;
  convertDateToUtc?: Maybe<Scalars['Boolean']>;
  updateUserClaims?: Maybe<Scalars['Boolean']>;
  createS3PresignedUrl?: Maybe<Scalars['String']>;
  createStorySticker?: Maybe<Scalars['String']>;
  updateDynamicLinks?: Maybe<Scalars['Boolean']>;
  signS3Image?: Maybe<Scalars['String']>;
  sendContactMail?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateArtistArgs = {
  artist: ArtistInput;
};


export type MutationCreateBraintreeCustomerArgs = {
  user: CreateBraintreeCustomerInput;
};


export type MutationCreateBraintreeClientTokenArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type MutationCreateBraintreePaymentMethodArgs = {
  paymentMethodNonce: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationUpdateBraintreeDefaultPaymentMethodArgs = {
  token: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationDeleteBraintreePaymentMethodArgs = {
  token: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCreateBraintreeTransactionArgs = {
  input: BraintreeTransactionInput;
};


export type MutationUpsertFcmTokenArgs = {
  userId: Scalars['Int'];
  deviceId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateInAppPurchaseTransactionArgs = {
  product: IapProductInput;
};


export type MutationSubmitArtistVerificationArgs = {
  userId: Scalars['Int'];
  userEmail: Scalars['String'];
  artistId: Scalars['Int'];
  artistName: Scalars['String'];
};


export type MutationUpsertNotificationArgs = {
  notification: NotificationInput;
};


export type MutationUpdateNotificationArgs = {
  notification: NotificationInput;
};


export type MutationCreateNotificationArgs = {
  notification: NotificationInput;
};


export type MutationReleaseNotificationCloudTaskArgs = {
  notificationId: Scalars['Int'];
};


export type MutationRescheduleNotificationArgs = {
  releaseId: Scalars['Int'];
  date: Scalars['DateTime'];
};


export type MutationCreateReleaseArgs = {
  release: CreateReleaseInput;
};


export type MutationUpdateReleaseArgs = {
  release: UpdateReleaseInput;
};


export type MutationUserReleaseFeedCloudTaskArgs = {
  releaseId: Scalars['Int'];
  artistIds: Array<Scalars['Int']>;
};


export type MutationDeleteReleaseArgs = {
  releaseId: Scalars['Int'];
};


export type MutationConfirmReleaseCloudTaskArgs = {
  releaseId: Scalars['Int'];
};


export type MutationConnectAlbumToReleaseArgs = {
  spotifyId: Scalars['String'];
  releaseId: Scalars['Int'];
};


export type MutationUpdateReleaseScoreCloudTaskArgs = {
  releaseId: Scalars['Int'];
};


export type MutationDailyDigestCloudTaskArgs = {
  userId: Scalars['Int'];
  tokens: Array<Maybe<Scalars['String']>>;
};


export type MutationAddReleaseBudgetArgs = {
  releaseId: Scalars['Int'];
  sku: Scalars['String'];
};


export type MutationUpdateEstimatedReleaseCostArgs = {
  releaseId: Scalars['Int'];
};


export type MutationCreateSearchHistoryArgs = {
  artistId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationDeleteSearchHistoryArgs = {
  userId: Scalars['Int'];
};


export type MutationCreateWalletTransactionArgs = {
  productSku: Scalars['String'];
};


export type MutationCreateTransactionArgs = {
  sku: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCreateUserArgs = {
  uid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type MutationDeleteUserArgs = {
  uid: Scalars['String'];
};


export type MutationDeleteFirebaseUserArgs = {
  uid: Scalars['String'];
};


export type MutationUpsertUserAuthArgs = {
  uid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserSettingsArgs = {
  user: UpdateUserSettingsInput;
};


export type MutationUpdateAnonUserSettingsArgs = {
  user: UpdateAnonUserSettingsInput;
};


export type MutationUserToReleaseArgs = {
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
  supporting: Scalars['Boolean'];
};


export type MutationUserToCollectionArgs = {
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
  add: Scalars['Boolean'];
};


export type MutationUserToWaitListArgs = {
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
  method: NotificationMethod;
  add: Scalars['Boolean'];
};


export type MutationUserToLinkedArtistArgs = {
  userId: Scalars['Int'];
  artistId: Scalars['Int'];
  add: Scalars['Boolean'];
};


export type MutationUpdateUserReputationCloudTaskArgs = {
  userId: Scalars['Int'];
};


export type MutationUserToArtistArgs = {
  userId: Scalars['Int'];
  artistId: Scalars['Int'];
  follow: Scalars['Boolean'];
};


export type MutationUserToArtistCloudTaskArgs = {
  userId: Scalars['Int'];
  artistId: Scalars['Int'];
  follow: Scalars['Boolean'];
};


export type MutationUpdatePhoneNumberArgs = {
  phone: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationUpdateEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationAddWalletBudgetArgs = {
  productSku: Scalars['String'];
};


export type MutationCreateS3PresignedUrlArgs = {
  key: Scalars['String'];
};


export type MutationCreateStoryStickerArgs = {
  releaseId: Scalars['Int'];
};


export type MutationSignS3ImageArgs = {
  key: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};


export type MutationSendContactMailArgs = {
  data: ContactMailInput;
};

export type AddReleaseBudgetMutationVariables = Exact<{
  releaseId: Scalars['Int'];
  productSku: Scalars['String'];
}>;


export type AddReleaseBudgetMutation = (
  { __typename?: 'Mutation' }
  & { addReleaseBudget?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'budget' | 'campaignStatus'>
  )> }
);

export type AddWalletBudgetMutationVariables = Exact<{
  productSku: Scalars['String'];
}>;


export type AddWalletBudgetMutation = (
  { __typename?: 'Mutation' }
  & { addWalletBudget?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'wallet'>
  )> }
);

export type BudgetProductsQueryVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type BudgetProductsQuery = (
  { __typename?: 'Query' }
  & { encoreProducts?: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'price' | 'sku' | 'name'>
  )>>> }
);

export type BraintreeCustomerQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type BraintreeCustomerQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { braintreeCustomer?: Maybe<(
      { __typename?: 'BraintreeCustomer' }
      & BraintreeCustomerFragmentFragment
    )> }
  )> }
);

export type CheckoutProductQueryVariables = Exact<{
  sku: Scalars['String'];
}>;


export type CheckoutProductQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'price' | 'sku'>
  )> }
);

export type CreateBraintreeClientTokenMutationVariables = Exact<{
  userId?: Maybe<Scalars['Int']>;
}>;


export type CreateBraintreeClientTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBraintreeClientToken'>
);

export type CreateBraintreeCustomerMutationVariables = Exact<{
  user: CreateBraintreeCustomerInput;
}>;


export type CreateBraintreeCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createBraintreeCustomer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { braintreeCustomer?: Maybe<(
      { __typename?: 'BraintreeCustomer' }
      & BraintreeCustomerFragmentFragment
    )> }
  )> }
);

export type CreateBraintreePaymentMethodMutationVariables = Exact<{
  userId: Scalars['Int'];
  paymentMethodNonce: Scalars['String'];
}>;


export type CreateBraintreePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { createBraintreePaymentMethod?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { braintreeCustomer?: Maybe<(
      { __typename?: 'BraintreeCustomer' }
      & BraintreeCustomerFragmentFragment
    )> }
  )> }
);

export type CreateBraintreeTransactionMutationVariables = Exact<{
  input: BraintreeTransactionInput;
}>;


export type CreateBraintreeTransactionMutation = (
  { __typename?: 'Mutation' }
  & { createBraintreeTransaction?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'wallet'>
    & { braintreeCustomer?: Maybe<(
      { __typename?: 'BraintreeCustomer' }
      & BraintreeCustomerFragmentFragment
    )> }
  )> }
);

export type CreateWalletTransactionMutationVariables = Exact<{
  productSku: Scalars['String'];
}>;


export type CreateWalletTransactionMutation = (
  { __typename?: 'Mutation' }
  & { createWalletTransaction?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'wallet'>
  )> }
);

export type DeleteBraintreePaymentMethodMutationVariables = Exact<{
  token: Scalars['String'];
  userId: Scalars['Int'];
}>;


export type DeleteBraintreePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { deleteBraintreePaymentMethod?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { braintreeCustomer?: Maybe<(
      { __typename?: 'BraintreeCustomer' }
      & BraintreeCustomerFragmentFragment
    )> }
  )> }
);

export type UpdateBraintreeDefaultPaymentMethodMutationVariables = Exact<{
  token: Scalars['String'];
  userId: Scalars['Int'];
}>;


export type UpdateBraintreeDefaultPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updateBraintreeDefaultPaymentMethod?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { braintreeCustomer?: Maybe<(
      { __typename?: 'BraintreeCustomer' }
      & BraintreeCustomerFragmentFragment
    )> }
  )> }
);

export type UpdateNotificationEmailMutationVariables = Exact<{
  email: Scalars['String'];
  userId: Scalars['Int'];
}>;


export type UpdateNotificationEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  )> }
);

export type UpdateNotificationPhoneNumberMutationVariables = Exact<{
  phone: Scalars['String'];
  userId: Scalars['Int'];
}>;


export type UpdateNotificationPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { updatePhoneNumber?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'phone'>
  )> }
);

export type UserToWaitListMutationVariables = Exact<{
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
  method: NotificationMethod;
  add: Scalars['Boolean'];
}>;


export type UserToWaitListMutation = (
  { __typename?: 'Mutation' }
  & { userToWaitList?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id'>
    & { waitListMethods?: Maybe<Array<Maybe<(
      { __typename?: 'WaitList' }
      & Pick<WaitList, 'userId' | 'releaseId' | 'method'>
    )>>> }
  )> }
);

export type WaitListNotificationQueryVariables = Exact<{
  releaseId: Scalars['Int'];
}>;


export type WaitListNotificationQuery = (
  { __typename?: 'Query' }
  & { release?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id'>
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'date'>
    )>, waitListMethods?: Maybe<Array<Maybe<(
      { __typename?: 'WaitList' }
      & Pick<WaitList, 'userId' | 'releaseId' | 'method'>
    )>>>, addOns?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseAddOn' }
      & Pick<ReleaseAddOn, 'addOnId' | 'releaseId'>
      & { addOn?: Maybe<(
        { __typename?: 'AddOn' }
        & Pick<AddOn, 'id' | 'type'>
      )> }
    )>>> }
  )> }
);

export type UpsertFcmTokenMutationVariables = Exact<{
  userId: Scalars['Int'];
  deviceId: Scalars['String'];
  token: Scalars['String'];
}>;


export type UpsertFcmTokenMutation = (
  { __typename?: 'Mutation' }
  & { upsertFcmToken?: Maybe<(
    { __typename?: 'FcmToken' }
    & Pick<FcmToken, 'id' | 'deviceId' | 'token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { fcmTokens?: Maybe<Array<Maybe<(
        { __typename?: 'FcmToken' }
        & Pick<FcmToken, 'id' | 'token' | 'deviceId'>
      )>>> }
    )> }
  )> }
);

export type ProductContainerQueryVariables = Exact<{
  sku: Scalars['String'];
}>;


export type ProductContainerQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'price' | 'sku'>
  )> }
);

export type RescheduleNotificationMutationVariables = Exact<{
  releaseId: Scalars['Int'];
  date: Scalars['DateTime'];
}>;


export type RescheduleNotificationMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleNotification?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'campaignStatus'>
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'date'>
    )> }
  )> }
);

export type TextPricingTableQueryVariables = Exact<{ [key: string]: never; }>;


export type TextPricingTableQuery = (
  { __typename?: 'Query' }
  & { textPricing: Array<(
    { __typename?: 'TextPricing' }
    & Pick<TextPricing, 'country' | 'price'>
  )> }
);

export type CreateInAppPurchaseTransactionMutationVariables = Exact<{
  product: IapProductInput;
}>;


export type CreateInAppPurchaseTransactionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createInAppPurchaseTransaction'>
);

export type InAppPurchaseProductsQueryVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type InAppPurchaseProductsQuery = (
  { __typename?: 'Query' }
  & { encoreProducts?: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'sku'>
  )>>> }
);

export type CreateUserMutationVariables = Exact<{
  uid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'uid' | 'email' | 'username'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>>> }
  )> }
);

export type DeleteFirebaseUserMutationVariables = Exact<{
  uid: Scalars['String'];
}>;


export type DeleteFirebaseUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFirebaseUser'>
);

export type DeleteUserMutationVariables = Exact<{
  uid: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type LoggedInUserQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type LoggedInUserQuery = (
  { __typename?: 'Query' }
  & { loggedInUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'uid' | 'username' | 'imageURL' | 'bio' | 'reputation' | 'releaseThreshold' | 'email' | 'phone' | 'type' | 'wallet'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>>>, linkedAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedAccount' }
      & Pick<LinkedAccount, 'status' | 'artistId' | 'userId'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>>, fcmTokens?: Maybe<Array<Maybe<(
      { __typename?: 'FcmToken' }
      & Pick<FcmToken, 'id' | 'deviceId' | 'token'>
    )>>> }
  )> }
);

export type UpsertUserAuthMutationVariables = Exact<{
  uid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}>;


export type UpsertUserAuthMutation = (
  { __typename?: 'Mutation' }
  & { upsertUserAuth?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'email' | 'uid' | 'phone'>
  )> }
);

export type CreateS3PresignedUrlMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type CreateS3PresignedUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createS3PresignedUrl'>
);

export type SignS3ImageMutationVariables = Exact<{
  key: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
}>;


export type SignS3ImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signS3Image'>
);

export type ReleaseAnalyticsQueryVariables = Exact<{
  releaseId: Scalars['Int'];
}>;


export type ReleaseAnalyticsQuery = (
  { __typename?: 'Query' }
  & { release?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'encore'>
  )> }
);

export type UserToArtistMutationVariables = Exact<{
  userId: Scalars['Int'];
  artistId: Scalars['Int'];
  follow: Scalars['Boolean'];
}>;


export type UserToArtistMutation = (
  { __typename?: 'Mutation' }
  & { userToArtist?: Maybe<(
    { __typename?: 'Artist' }
    & Pick<Artist, 'id' | 'following' | 'followerCount'>
  )> }
);

export type ArtistQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ArtistQuery = (
  { __typename?: 'Query' }
  & { artist?: Maybe<(
    { __typename?: 'Artist' }
    & Pick<Artist, 'id' | 'name' | 'imageURL' | 'following' | 'followerCount' | 'dynamicLinkEnding' | 'bgColor' | 'rumorCount' | 'releaseCount'>
    & { rumors?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistRelease' }
      & Pick<ArtistRelease, 'releaseId' | 'artistId'>
      & { release?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'status'>
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'imageURL'>
        )>, artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>> }
      )> }
    )>>>, releases?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistRelease' }
      & Pick<ArtistRelease, 'releaseId' | 'artistId'>
      & { release?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'imageURL'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name'>
          )> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type ArtistReleasesQueryVariables = Exact<{
  artistId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type ArtistReleasesQuery = (
  { __typename?: 'Query' }
  & { artistReleases?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'imageURL'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name'>
          )> }
        )>>> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type ArtistRumorsQueryVariables = Exact<{
  artistId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type ArtistRumorsQuery = (
  { __typename?: 'Query' }
  & { artistRumors?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'date' | 'status'>
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'imageURL'>
        )>, artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'releaseId' | 'artistId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type UsernameExistsQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UsernameExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'usernameExists'>
);

export type CreateArtistMutationVariables = Exact<{
  artist: ArtistInput;
}>;


export type CreateArtistMutation = (
  { __typename?: 'Mutation' }
  & { createArtist?: Maybe<(
    { __typename?: 'Artist' }
    & Pick<Artist, 'id' | 'name' | 'imageURL'>
  )> }
);

export type SearchSpotifyArtistQueryVariables = Exact<{
  q: Scalars['String'];
  type: Array<SpotifySearchType> | SpotifySearchType;
}>;


export type SearchSpotifyArtistQuery = (
  { __typename?: 'Query' }
  & { spotifySearch?: Maybe<(
    { __typename?: 'SpotifySearchResponse' }
    & { artists?: Maybe<(
      { __typename?: 'SpotifyArtistsPagingObject' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'SpotifyArtistFull' }
        & Pick<SpotifyArtistFull, 'id' | 'genres' | 'name' | 'popularity'>
        & { followers?: Maybe<(
          { __typename?: 'SpotifyFollowers' }
          & Pick<SpotifyFollowers, 'total'>
        )>, images?: Maybe<Array<Maybe<(
          { __typename?: 'SpotifyImage' }
          & Pick<SpotifyImage, 'url'>
        )>>>, external_urls?: Maybe<(
          { __typename?: 'SpotifyExternalURL' }
          & Pick<SpotifyExternalUrl, 'spotify'>
        )> }
      )>>> }
    )> }
  )> }
);

export type SpotifyArtistExistsQueryVariables = Exact<{
  spotifyId: Scalars['String'];
}>;


export type SpotifyArtistExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'spotifyArtistExists'>
);

export type ArtistSearchQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type ArtistSearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<Array<Maybe<(
    { __typename?: 'ArtistSearch' }
    & Pick<ArtistSearch, 'id' | 'name' | 'imageURL'>
  )>>> }
);

export type CampaignNameExistsQueryVariables = Exact<{
  campaignName: Scalars['String'];
}>;


export type CampaignNameExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'campaignNameExists'>
);

export type CreateReleaseMutationVariables = Exact<{
  release: CreateReleaseInput;
}>;


export type CreateReleaseMutation = (
  { __typename?: 'Mutation' }
  & { createRelease?: Maybe<(
    { __typename?: 'Release' }
    & ReleasePageFragmentFragment
  )> }
);

export type DeleteReleaseMutationVariables = Exact<{
  releaseId: Scalars['Int'];
}>;


export type DeleteReleaseMutation = (
  { __typename?: 'Mutation' }
  & { deleteRelease?: Maybe<(
    { __typename?: 'Release' }
    & ReleasePageFragmentFragment
  )> }
);

export type EncoreProductsQueryVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type EncoreProductsQuery = (
  { __typename?: 'Query' }
  & { encoreProducts?: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'price' | 'sku' | 'name'>
  )>>> }
);

export type LoadCreatePageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type LoadCreatePageQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'type'>
    & { linkedAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedAccount' }
      & Pick<LinkedAccount, 'userId' | 'artistId' | 'status'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>> }
  )> }
);

export type LoadCreatePageInitialArtistQueryVariables = Exact<{
  artistId: Scalars['Int'];
}>;


export type LoadCreatePageInitialArtistQuery = (
  { __typename?: 'Query' }
  & { artist?: Maybe<(
    { __typename?: 'Artist' }
    & Pick<Artist, 'id' | 'imageURL' | 'name'>
  )> }
);

export type LoadEditReleaseQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type LoadEditReleaseQuery = (
  { __typename?: 'Query' }
  & { release?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'title' | 'date' | 'status' | 'imageURL' | 'bgColor' | 'type' | 'creatorId' | 'recordLabel' | 'message' | 'encore' | 'visibility' | 'budget' | 'campaignStatus' | 'customEnding'>
    & { artists?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistRelease' }
      & Pick<ArtistRelease, 'artistId' | 'releaseId'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'Link' }
      & Pick<Link, 'id' | 'url' | 'order'>
    )>>>, notification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'taskName' | 'date'>
    )>, addOns?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseAddOn' }
      & Pick<ReleaseAddOn, 'addOnId' | 'releaseId'>
      & { addOn?: Maybe<(
        { __typename?: 'AddOn' }
        & Pick<AddOn, 'id' | 'type'>
      )> }
    )>>> }
  )> }
);

export type ReleaseExistsQueryVariables = Exact<{
  artistIds: Array<Scalars['Int']> | Scalars['Int'];
  date: Scalars['Date'];
  title: Scalars['String'];
}>;


export type ReleaseExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'releaseExists'>
);

export type SimilarSubmissionsQueryVariables = Exact<{
  artistIds: Array<Scalars['Int']> | Scalars['Int'];
  date: Scalars['Date'];
  title: Scalars['String'];
}>;


export type SimilarSubmissionsQuery = (
  { __typename?: 'Query' }
  & { similarSubmissions?: Maybe<Array<Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'title'>
    & { artists?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistRelease' }
      & Pick<ArtistRelease, 'artistId' | 'releaseId'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'name'>
      )> }
    )>>>, supporters?: Maybe<Array<Maybe<(
      { __typename?: 'UserSupport' }
      & Pick<UserSupport, 'userId' | 'releaseId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'imageURL'>
      )> }
    )>>> }
  )>>> }
);

export type UpdateReleaseMutationVariables = Exact<{
  release: UpdateReleaseInput;
}>;


export type UpdateReleaseMutation = (
  { __typename?: 'Mutation' }
  & { updateRelease?: Maybe<(
    { __typename?: 'Release' }
    & ReleasePageFragmentFragment
  )> }
);

export type UserReleaseFeedQueryVariables = Exact<{
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type UserReleaseFeedQuery = (
  { __typename?: 'Query' }
  & { userReleaseFeed?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'date' | 'score' | 'imageURL' | 'status' | 'message'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>>, creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'imageURL'>
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type UserRumorFeedQueryVariables = Exact<{
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
  releaseThreshold?: Maybe<Scalars['Int']>;
}>;


export type UserRumorFeedQuery = (
  { __typename?: 'Query' }
  & { userRumorFeed?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'date' | 'score' | 'status' | 'imageURL'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'releaseId' | 'artistId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type UserStoriesQueryVariables = Exact<{
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type UserStoriesQuery = (
  { __typename?: 'Query' }
  & { userReleaseFeed?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'imageURL'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type ArtistSuggestionsQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type ArtistSuggestionsQuery = (
  { __typename?: 'Query' }
  & { artistSuggestions?: Maybe<Array<Maybe<(
    { __typename?: 'Artist' }
    & Pick<Artist, 'id' | 'name' | 'imageURL' | 'bgColor' | 'followerCount'>
    & { genres?: Maybe<Array<Maybe<(
      { __typename?: 'Genre' }
      & Pick<Genre, 'name'>
    )>>> }
  )>>> }
);

export type FeaturedReleasesQueryVariables = Exact<{
  take: Scalars['Int'];
}>;


export type FeaturedReleasesQuery = (
  { __typename?: 'Query' }
  & { featuredReleases?: Maybe<Array<Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'title' | 'imageURL' | 'date'>
    & { artists?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistRelease' }
      & Pick<ArtistRelease, 'artistId' | 'releaseId'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>> }
  )>>> }
);

export type UnAuthArtistSuggestionsQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
}>;


export type UnAuthArtistSuggestionsQuery = (
  { __typename?: 'Query' }
  & { unAuthArtistSuggestions?: Maybe<Array<Maybe<(
    { __typename?: 'Artist' }
    & Pick<Artist, 'id' | 'name' | 'imageURL' | 'bgColor' | 'followerCount'>
    & { genres?: Maybe<Array<Maybe<(
      { __typename?: 'Genre' }
      & Pick<Genre, 'name'>
    )>>> }
  )>>> }
);

export type ConnectAlbumReleaseQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ConnectAlbumReleaseQuery = (
  { __typename?: 'Query' }
  & { release?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'date' | 'title'>
    & { artists?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistRelease' }
      & Pick<ArtistRelease, 'artistId' | 'releaseId'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type ConnectAlbumToReleaseMutationVariables = Exact<{
  releaseId: Scalars['Int'];
  spotifyId: Scalars['String'];
}>;


export type ConnectAlbumToReleaseMutation = (
  { __typename?: 'Mutation' }
  & { connectAlbumToRelease?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'status'>
  )> }
);

export type SearchSpotifyAlbumQueryVariables = Exact<{
  q: Scalars['String'];
  type: Array<SpotifySearchType> | SpotifySearchType;
}>;


export type SearchSpotifyAlbumQuery = (
  { __typename?: 'Query' }
  & { spotifySearch?: Maybe<(
    { __typename?: 'SpotifySearchResponse' }
    & { albums?: Maybe<(
      { __typename?: 'SpotifyAlbumsPagingObject' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'SpotifyAlbumSimplified' }
        & Pick<SpotifyAlbumSimplified, 'id' | 'name'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'SpotifyArtistSimplified' }
          & Pick<SpotifyArtistSimplified, 'name'>
        )>>>, images?: Maybe<Array<Maybe<(
          { __typename?: 'SpotifyImage' }
          & Pick<SpotifyImage, 'url'>
        )>>>, external_urls?: Maybe<(
          { __typename?: 'SpotifyExternalURL' }
          & Pick<SpotifyExternalUrl, 'spotify'>
        )> }
      )>>> }
    )> }
  )> }
);

export type UserToCollectionMutationVariables = Exact<{
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
  add: Scalars['Boolean'];
}>;


export type UserToCollectionMutation = (
  { __typename?: 'Mutation' }
  & { userToCollection?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'inCollection'>
  )> }
);

export type UserToReleaseMutationVariables = Exact<{
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
  supporting: Scalars['Boolean'];
}>;


export type UserToReleaseMutation = (
  { __typename?: 'Mutation' }
  & { userToRelease?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'supporting'>
    & { supporters?: Maybe<Array<Maybe<(
      { __typename?: 'UserSupport' }
      & Pick<UserSupport, 'userId' | 'releaseId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'imageURL'>
      )> }
    )>>> }
  )> }
);

export type CreateStoryStickerMutationVariables = Exact<{
  releaseId: Scalars['Int'];
}>;


export type CreateStoryStickerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStorySticker'>
);

export type ReleasePageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleasePageQuery = (
  { __typename?: 'Query' }
  & { release?: Maybe<(
    { __typename?: 'Release' }
    & ReleasePageFragmentFragment
  )> }
);

export type UpdateEstimatedReleaseCostAlertMutationVariables = Exact<{
  releaseId: Scalars['Int'];
}>;


export type UpdateEstimatedReleaseCostAlertMutation = (
  { __typename?: 'Mutation' }
  & { updateEstimatedReleaseCost?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'campaignStatus' | 'estimatedCost'>
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'date'>
    )> }
  )> }
);

export type UserOnWaitListQueryVariables = Exact<{
  userId: Scalars['Int'];
  releaseId: Scalars['Int'];
}>;


export type UserOnWaitListQuery = (
  { __typename?: 'Query' }
  & { userOnWaitList?: Maybe<Array<Maybe<(
    { __typename?: 'WaitList' }
    & Pick<WaitList, 'userId' | 'releaseId' | 'method'>
    & { release?: Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id'>
    )> }
  )>>> }
);

export type CreateSearchHistoryMutationVariables = Exact<{
  artistId: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type CreateSearchHistoryMutation = (
  { __typename?: 'Mutation' }
  & { createSearchHistory?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { searchHistory?: Maybe<Array<Maybe<(
      { __typename?: 'Artist' }
      & Pick<Artist, 'id' | 'imageURL' | 'name'>
    )>>> }
  )> }
);

export type DeleteSearchHistoryMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DeleteSearchHistoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteSearchHistory?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { searchHistory?: Maybe<Array<Maybe<(
      { __typename?: 'Artist' }
      & Pick<Artist, 'id' | 'imageURL' | 'name'>
    )>>> }
  )> }
);

export type SearchQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<Array<Maybe<(
    { __typename?: 'ArtistSearch' }
    & Pick<ArtistSearch, 'id' | 'name' | 'imageURL'>
  )>>> }
);

export type SearchHistoryQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type SearchHistoryQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { searchHistory?: Maybe<Array<Maybe<(
      { __typename?: 'Artist' }
      & Pick<Artist, 'id' | 'imageURL' | 'name'>
    )>>> }
  )> }
);

export type UserCollectionQueryVariables = Exact<{
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type UserCollectionQuery = (
  { __typename?: 'Query' }
  & { userCollection?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'imageURL'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type UserEncoreQueryVariables = Exact<{
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type UserEncoreQuery = (
  { __typename?: 'Query' }
  & { userEncore?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'imageURL' | 'date' | 'status' | 'estimatedCost' | 'budget' | 'creatorId' | 'campaignStatus'>
        & { artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type UserFollowingQueryVariables = Exact<{
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type UserFollowingQuery = (
  { __typename?: 'Query' }
  & { userFollowing?: Maybe<(
    { __typename?: 'ArtistConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistEdge' }
      & Pick<ArtistEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type UserRumorsQueryVariables = Exact<{
  userId: Scalars['Int'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type UserRumorsQuery = (
  { __typename?: 'Query' }
  & { userRumors?: Maybe<(
    { __typename?: 'ReleaseConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReleaseEdge' }
      & Pick<ReleaseEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Release' }
        & Pick<Release, 'id' | 'title' | 'date' | 'status'>
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'imageURL'>
        )>, artists?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistRelease' }
          & Pick<ArtistRelease, 'artistId' | 'releaseId'>
          & { artist?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'name' | 'imageURL'>
          )> }
        )>>> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type AnonUserSettingsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnonUserSettingsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'phone'>
  )> }
);

export type UserToLinkedArtistMutationVariables = Exact<{
  userId: Scalars['Int'];
  artistId: Scalars['Int'];
  add: Scalars['Boolean'];
}>;


export type UserToLinkedArtistMutation = (
  { __typename?: 'Mutation' }
  & { userToLinkedArtist?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { linkedAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedAccount' }
      & Pick<LinkedAccount, 'userId' | 'artistId'>
    )>>> }
  )> }
);

export type SubmitArtistVerificationMutationVariables = Exact<{
  userId: Scalars['Int'];
  userEmail: Scalars['String'];
  artistId: Scalars['Int'];
  artistName: Scalars['String'];
}>;


export type SubmitArtistVerificationMutation = (
  { __typename?: 'Mutation' }
  & { submitArtistVerification?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { linkedAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedAccount' }
      & Pick<LinkedAccount, 'userId' | 'artistId' | 'status'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>> }
  )> }
);

export type UpdateAnonUserSettingsMutationVariables = Exact<{
  user: UpdateAnonUserSettingsInput;
}>;


export type UpdateAnonUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateAnonUserSettings?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'phone' | 'email'>
  )> }
);

export type UpdateUserSettingsMutationVariables = Exact<{
  user: UpdateUserSettingsInput;
}>;


export type UpdateUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'uid' | 'username' | 'imageURL' | 'bgColor' | 'bio' | 'releaseThreshold' | 'dailyDigest' | 'phone' | 'email'>
  )> }
);

export type UserSettingsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserSettingsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'uid' | 'email' | 'username' | 'imageURL' | 'bio' | 'releaseThreshold' | 'dailyDigest' | 'type' | 'phone'>
    & { linkedAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedAccount' }
      & Pick<LinkedAccount, 'userId' | 'artistId' | 'status'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>> }
  )> }
);

export type UserProfileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserProfileQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'imageURL' | 'bgColor' | 'bio' | 'reputation' | 'type' | 'wallet'>
    & { encoreReleases?: Maybe<Array<Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id' | 'title' | 'date' | 'imageURL' | 'budget' | 'estimatedCost' | 'status' | 'campaignStatus'>
      & { notification?: Maybe<(
        { __typename?: 'Notification' }
        & Pick<Notification, 'id' | 'date'>
      )>, artists?: Maybe<Array<Maybe<(
        { __typename?: 'ArtistRelease' }
        & Pick<ArtistRelease, 'artistId' | 'releaseId'>
        & { artist?: Maybe<(
          { __typename?: 'Artist' }
          & Pick<Artist, 'id' | 'name' | 'imageURL'>
        )> }
      )>>> }
    )>>>, supporting?: Maybe<Array<Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id' | 'title' | 'status'>
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'imageURL'>
      )>, artists?: Maybe<Array<Maybe<(
        { __typename?: 'ArtistRelease' }
        & Pick<ArtistRelease, 'artistId' | 'releaseId'>
        & { artist?: Maybe<(
          { __typename?: 'Artist' }
          & Pick<Artist, 'id' | 'name' | 'imageURL'>
        )> }
      )>>> }
    )>>>, following?: Maybe<Array<Maybe<(
      { __typename?: 'Artist' }
      & Pick<Artist, 'id' | 'imageURL' | 'name'>
    )>>>, collection?: Maybe<Array<Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id' | 'title' | 'imageURL'>
      & { artists?: Maybe<Array<Maybe<(
        { __typename?: 'ArtistRelease' }
        & Pick<ArtistRelease, 'artistId' | 'releaseId'>
        & { artist?: Maybe<(
          { __typename?: 'Artist' }
          & Pick<Artist, 'id' | 'name' | 'imageURL'>
        )> }
      )>>> }
    )>>>, linkedAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedAccount' }
      & Pick<LinkedAccount, 'userId' | 'artistId' | 'status'>
      & { artist?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'name' | 'imageURL'>
      )> }
    )>>> }
  )> }
);

export type BraintreeCustomerFragmentFragment = (
  { __typename?: 'BraintreeCustomer' }
  & Pick<BraintreeCustomer, 'id' | 'firstName' | 'lastName' | 'email'>
  & { paymentMethods?: Maybe<Array<Maybe<(
    { __typename?: 'BraintreePayPalAccount' }
    & Pick<BraintreePayPalAccount, 'token' | 'default' | 'email' | 'createdAt' | 'updatedAt'>
  ) | (
    { __typename?: 'BraintreeCreditCard' }
    & Pick<BraintreeCreditCard, 'cardholderName' | 'cardType' | 'default' | 'expirationDate' | 'expired' | 'last4' | 'token' | 'createdAt' | 'updatedAt'>
  )>>>, creditCards?: Maybe<Array<Maybe<(
    { __typename?: 'BraintreeCreditCard' }
    & Pick<BraintreeCreditCard, 'cardholderName' | 'cardType' | 'default' | 'expirationDate' | 'expired' | 'last4' | 'token' | 'createdAt' | 'updatedAt'>
  )>>>, paypalAccounts?: Maybe<Array<Maybe<(
    { __typename?: 'BraintreePayPalAccount' }
    & Pick<BraintreePayPalAccount, 'token' | 'default' | 'email' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type ReleasePageFragmentFragment = (
  { __typename?: 'Release' }
  & Pick<Release, 'id' | 'title' | 'date' | 'status' | 'score' | 'creatorId' | 'dynamicLinkEnding' | 'type' | 'imageURL' | 'bgColor' | 'recordLabel' | 'message' | 'encore' | 'customEnding' | 'visibility' | 'budget' | 'estimatedCost' | 'campaignStatus' | 'supporting' | 'inCollection'>
  & { artists?: Maybe<Array<Maybe<(
    { __typename?: 'ArtistRelease' }
    & Pick<ArtistRelease, 'artistId' | 'releaseId'>
    & { artist?: Maybe<(
      { __typename?: 'Artist' }
      & Pick<Artist, 'id' | 'name' | 'imageURL' | 'bgColor' | 'followerCount'>
    )> }
  )>>>, waitListMethods?: Maybe<Array<Maybe<(
    { __typename?: 'WaitList' }
    & Pick<WaitList, 'releaseId' | 'userId' | 'method'>
  )>>>, supporters?: Maybe<Array<Maybe<(
    { __typename?: 'UserSupport' }
    & Pick<UserSupport, 'userId' | 'releaseId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'imageURL'>
    )> }
  )>>>, notification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'date'>
  )>, links?: Maybe<Array<Maybe<(
    { __typename?: 'Link' }
    & Pick<Link, 'id' | 'url' | 'releaseId'>
  )>>>, addOns?: Maybe<Array<Maybe<(
    { __typename?: 'ReleaseAddOn' }
    & Pick<ReleaseAddOn, 'releaseId' | 'addOnId'>
    & { addOn?: Maybe<(
      { __typename?: 'AddOn' }
      & Pick<AddOn, 'id' | 'type'>
    )> }
  )>>> }
);

export const BraintreeCustomerFragmentFragmentDoc = gql`
    fragment BraintreeCustomerFragment on BraintreeCustomer {
  id
  firstName
  lastName
  email
  paymentMethods {
    ... on BraintreeCreditCard {
      cardholderName
      cardType
      default
      expirationDate
      expired
      last4
      token
      createdAt
      updatedAt
    }
    ... on BraintreePayPalAccount {
      token
      default
      email
      createdAt
      updatedAt
    }
  }
  creditCards {
    cardholderName
    cardType
    default
    expirationDate
    expired
    last4
    token
    createdAt
    updatedAt
  }
  paypalAccounts {
    token
    default
    email
    createdAt
    updatedAt
  }
}
    `;
export const ReleasePageFragmentFragmentDoc = gql`
    fragment ReleasePageFragment on Release {
  id
  title
  date
  status
  score
  creatorId
  dynamicLinkEnding
  type
  imageURL
  bgColor
  recordLabel
  message
  encore
  customEnding
  visibility
  budget
  estimatedCost
  campaignStatus
  artists {
    artistId
    releaseId
    artist {
      id
      name
      imageURL
      bgColor
      followerCount
    }
  }
  supporting
  inCollection
  waitListMethods {
    releaseId
    userId
    method
  }
  supporters {
    userId
    releaseId
    user {
      id
      username
      imageURL
    }
  }
  notification {
    id
    date
  }
  links {
    id
    url
    releaseId
  }
  addOns {
    releaseId
    addOnId
    addOn {
      id
      type
    }
  }
}
    `;
export const AddReleaseBudgetDocument = gql`
    mutation AddReleaseBudget($releaseId: Int!, $productSku: String!) {
  addReleaseBudget(releaseId: $releaseId, sku: $productSku) {
    id
    budget
    campaignStatus
  }
}
    `;
export type AddReleaseBudgetMutationFn = ApolloReactCommon.MutationFunction<AddReleaseBudgetMutation, AddReleaseBudgetMutationVariables>;

/**
 * __useAddReleaseBudgetMutation__
 *
 * To run a mutation, you first call `useAddReleaseBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReleaseBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReleaseBudgetMutation, { data, loading, error }] = useAddReleaseBudgetMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      productSku: // value for 'productSku'
 *   },
 * });
 */
export function useAddReleaseBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddReleaseBudgetMutation, AddReleaseBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddReleaseBudgetMutation, AddReleaseBudgetMutationVariables>(AddReleaseBudgetDocument, options);
      }
export type AddReleaseBudgetMutationHookResult = ReturnType<typeof useAddReleaseBudgetMutation>;
export type AddReleaseBudgetMutationResult = ApolloReactCommon.MutationResult<AddReleaseBudgetMutation>;
export type AddReleaseBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<AddReleaseBudgetMutation, AddReleaseBudgetMutationVariables>;
export const AddWalletBudgetDocument = gql`
    mutation AddWalletBudget($productSku: String!) {
  addWalletBudget(productSku: $productSku) {
    id
    wallet
  }
}
    `;
export type AddWalletBudgetMutationFn = ApolloReactCommon.MutationFunction<AddWalletBudgetMutation, AddWalletBudgetMutationVariables>;

/**
 * __useAddWalletBudgetMutation__
 *
 * To run a mutation, you first call `useAddWalletBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWalletBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWalletBudgetMutation, { data, loading, error }] = useAddWalletBudgetMutation({
 *   variables: {
 *      productSku: // value for 'productSku'
 *   },
 * });
 */
export function useAddWalletBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWalletBudgetMutation, AddWalletBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddWalletBudgetMutation, AddWalletBudgetMutationVariables>(AddWalletBudgetDocument, options);
      }
export type AddWalletBudgetMutationHookResult = ReturnType<typeof useAddWalletBudgetMutation>;
export type AddWalletBudgetMutationResult = ApolloReactCommon.MutationResult<AddWalletBudgetMutation>;
export type AddWalletBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<AddWalletBudgetMutation, AddWalletBudgetMutationVariables>;
export const BudgetProductsDocument = gql`
    query BudgetProducts($productIds: [String!]!) {
  encoreProducts(productIds: $productIds) {
    id
    price
    sku
    name
  }
}
    `;

/**
 * __useBudgetProductsQuery__
 *
 * To run a query within a React component, call `useBudgetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetProductsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useBudgetProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BudgetProductsQuery, BudgetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BudgetProductsQuery, BudgetProductsQueryVariables>(BudgetProductsDocument, options);
      }
export function useBudgetProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BudgetProductsQuery, BudgetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BudgetProductsQuery, BudgetProductsQueryVariables>(BudgetProductsDocument, options);
        }
export type BudgetProductsQueryHookResult = ReturnType<typeof useBudgetProductsQuery>;
export type BudgetProductsLazyQueryHookResult = ReturnType<typeof useBudgetProductsLazyQuery>;
export type BudgetProductsQueryResult = ApolloReactCommon.QueryResult<BudgetProductsQuery, BudgetProductsQueryVariables>;
export const BraintreeCustomerDocument = gql`
    query BraintreeCustomer($userId: Int!) {
  user(id: $userId) {
    id
    braintreeCustomer {
      ...BraintreeCustomerFragment
    }
  }
}
    ${BraintreeCustomerFragmentFragmentDoc}`;

/**
 * __useBraintreeCustomerQuery__
 *
 * To run a query within a React component, call `useBraintreeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBraintreeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBraintreeCustomerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBraintreeCustomerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BraintreeCustomerQuery, BraintreeCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BraintreeCustomerQuery, BraintreeCustomerQueryVariables>(BraintreeCustomerDocument, options);
      }
export function useBraintreeCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BraintreeCustomerQuery, BraintreeCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BraintreeCustomerQuery, BraintreeCustomerQueryVariables>(BraintreeCustomerDocument, options);
        }
export type BraintreeCustomerQueryHookResult = ReturnType<typeof useBraintreeCustomerQuery>;
export type BraintreeCustomerLazyQueryHookResult = ReturnType<typeof useBraintreeCustomerLazyQuery>;
export type BraintreeCustomerQueryResult = ApolloReactCommon.QueryResult<BraintreeCustomerQuery, BraintreeCustomerQueryVariables>;
export const CheckoutProductDocument = gql`
    query CheckoutProduct($sku: String!) {
  product(sku: $sku) {
    id
    name
    price
    sku
  }
}
    `;

/**
 * __useCheckoutProductQuery__
 *
 * To run a query within a React component, call `useCheckoutProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutProductQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useCheckoutProductQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutProductQuery, CheckoutProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CheckoutProductQuery, CheckoutProductQueryVariables>(CheckoutProductDocument, options);
      }
export function useCheckoutProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutProductQuery, CheckoutProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CheckoutProductQuery, CheckoutProductQueryVariables>(CheckoutProductDocument, options);
        }
export type CheckoutProductQueryHookResult = ReturnType<typeof useCheckoutProductQuery>;
export type CheckoutProductLazyQueryHookResult = ReturnType<typeof useCheckoutProductLazyQuery>;
export type CheckoutProductQueryResult = ApolloReactCommon.QueryResult<CheckoutProductQuery, CheckoutProductQueryVariables>;
export const CreateBraintreeClientTokenDocument = gql`
    mutation CreateBraintreeClientToken($userId: Int) {
  createBraintreeClientToken(userId: $userId)
}
    `;
export type CreateBraintreeClientTokenMutationFn = ApolloReactCommon.MutationFunction<CreateBraintreeClientTokenMutation, CreateBraintreeClientTokenMutationVariables>;

/**
 * __useCreateBraintreeClientTokenMutation__
 *
 * To run a mutation, you first call `useCreateBraintreeClientTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBraintreeClientTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBraintreeClientTokenMutation, { data, loading, error }] = useCreateBraintreeClientTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateBraintreeClientTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBraintreeClientTokenMutation, CreateBraintreeClientTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBraintreeClientTokenMutation, CreateBraintreeClientTokenMutationVariables>(CreateBraintreeClientTokenDocument, options);
      }
export type CreateBraintreeClientTokenMutationHookResult = ReturnType<typeof useCreateBraintreeClientTokenMutation>;
export type CreateBraintreeClientTokenMutationResult = ApolloReactCommon.MutationResult<CreateBraintreeClientTokenMutation>;
export type CreateBraintreeClientTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBraintreeClientTokenMutation, CreateBraintreeClientTokenMutationVariables>;
export const CreateBraintreeCustomerDocument = gql`
    mutation CreateBraintreeCustomer($user: CreateBraintreeCustomerInput!) {
  createBraintreeCustomer(user: $user) {
    id
    braintreeCustomer {
      ...BraintreeCustomerFragment
    }
  }
}
    ${BraintreeCustomerFragmentFragmentDoc}`;
export type CreateBraintreeCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateBraintreeCustomerMutation, CreateBraintreeCustomerMutationVariables>;

/**
 * __useCreateBraintreeCustomerMutation__
 *
 * To run a mutation, you first call `useCreateBraintreeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBraintreeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBraintreeCustomerMutation, { data, loading, error }] = useCreateBraintreeCustomerMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateBraintreeCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBraintreeCustomerMutation, CreateBraintreeCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBraintreeCustomerMutation, CreateBraintreeCustomerMutationVariables>(CreateBraintreeCustomerDocument, options);
      }
export type CreateBraintreeCustomerMutationHookResult = ReturnType<typeof useCreateBraintreeCustomerMutation>;
export type CreateBraintreeCustomerMutationResult = ApolloReactCommon.MutationResult<CreateBraintreeCustomerMutation>;
export type CreateBraintreeCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBraintreeCustomerMutation, CreateBraintreeCustomerMutationVariables>;
export const CreateBraintreePaymentMethodDocument = gql`
    mutation CreateBraintreePaymentMethod($userId: Int!, $paymentMethodNonce: String!) {
  createBraintreePaymentMethod(
    userId: $userId
    paymentMethodNonce: $paymentMethodNonce
  ) {
    id
    braintreeCustomer {
      ...BraintreeCustomerFragment
    }
  }
}
    ${BraintreeCustomerFragmentFragmentDoc}`;
export type CreateBraintreePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<CreateBraintreePaymentMethodMutation, CreateBraintreePaymentMethodMutationVariables>;

/**
 * __useCreateBraintreePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreateBraintreePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBraintreePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBraintreePaymentMethodMutation, { data, loading, error }] = useCreateBraintreePaymentMethodMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodNonce: // value for 'paymentMethodNonce'
 *   },
 * });
 */
export function useCreateBraintreePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBraintreePaymentMethodMutation, CreateBraintreePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBraintreePaymentMethodMutation, CreateBraintreePaymentMethodMutationVariables>(CreateBraintreePaymentMethodDocument, options);
      }
export type CreateBraintreePaymentMethodMutationHookResult = ReturnType<typeof useCreateBraintreePaymentMethodMutation>;
export type CreateBraintreePaymentMethodMutationResult = ApolloReactCommon.MutationResult<CreateBraintreePaymentMethodMutation>;
export type CreateBraintreePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBraintreePaymentMethodMutation, CreateBraintreePaymentMethodMutationVariables>;
export const CreateBraintreeTransactionDocument = gql`
    mutation CreateBraintreeTransaction($input: BraintreeTransactionInput!) {
  createBraintreeTransaction(input: $input) {
    id
    wallet
    braintreeCustomer {
      ...BraintreeCustomerFragment
    }
  }
}
    ${BraintreeCustomerFragmentFragmentDoc}`;
export type CreateBraintreeTransactionMutationFn = ApolloReactCommon.MutationFunction<CreateBraintreeTransactionMutation, CreateBraintreeTransactionMutationVariables>;

/**
 * __useCreateBraintreeTransactionMutation__
 *
 * To run a mutation, you first call `useCreateBraintreeTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBraintreeTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBraintreeTransactionMutation, { data, loading, error }] = useCreateBraintreeTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBraintreeTransactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBraintreeTransactionMutation, CreateBraintreeTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBraintreeTransactionMutation, CreateBraintreeTransactionMutationVariables>(CreateBraintreeTransactionDocument, options);
      }
export type CreateBraintreeTransactionMutationHookResult = ReturnType<typeof useCreateBraintreeTransactionMutation>;
export type CreateBraintreeTransactionMutationResult = ApolloReactCommon.MutationResult<CreateBraintreeTransactionMutation>;
export type CreateBraintreeTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBraintreeTransactionMutation, CreateBraintreeTransactionMutationVariables>;
export const CreateWalletTransactionDocument = gql`
    mutation CreateWalletTransaction($productSku: String!) {
  createWalletTransaction(productSku: $productSku) {
    id
    wallet
  }
}
    `;
export type CreateWalletTransactionMutationFn = ApolloReactCommon.MutationFunction<CreateWalletTransactionMutation, CreateWalletTransactionMutationVariables>;

/**
 * __useCreateWalletTransactionMutation__
 *
 * To run a mutation, you first call `useCreateWalletTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWalletTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWalletTransactionMutation, { data, loading, error }] = useCreateWalletTransactionMutation({
 *   variables: {
 *      productSku: // value for 'productSku'
 *   },
 * });
 */
export function useCreateWalletTransactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWalletTransactionMutation, CreateWalletTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateWalletTransactionMutation, CreateWalletTransactionMutationVariables>(CreateWalletTransactionDocument, options);
      }
export type CreateWalletTransactionMutationHookResult = ReturnType<typeof useCreateWalletTransactionMutation>;
export type CreateWalletTransactionMutationResult = ApolloReactCommon.MutationResult<CreateWalletTransactionMutation>;
export type CreateWalletTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWalletTransactionMutation, CreateWalletTransactionMutationVariables>;
export const DeleteBraintreePaymentMethodDocument = gql`
    mutation DeleteBraintreePaymentMethod($token: String!, $userId: Int!) {
  deleteBraintreePaymentMethod(token: $token, userId: $userId) {
    id
    braintreeCustomer {
      ...BraintreeCustomerFragment
    }
  }
}
    ${BraintreeCustomerFragmentFragmentDoc}`;
export type DeleteBraintreePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<DeleteBraintreePaymentMethodMutation, DeleteBraintreePaymentMethodMutationVariables>;

/**
 * __useDeleteBraintreePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeleteBraintreePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBraintreePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBraintreePaymentMethodMutation, { data, loading, error }] = useDeleteBraintreePaymentMethodMutation({
 *   variables: {
 *      token: // value for 'token'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteBraintreePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBraintreePaymentMethodMutation, DeleteBraintreePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteBraintreePaymentMethodMutation, DeleteBraintreePaymentMethodMutationVariables>(DeleteBraintreePaymentMethodDocument, options);
      }
export type DeleteBraintreePaymentMethodMutationHookResult = ReturnType<typeof useDeleteBraintreePaymentMethodMutation>;
export type DeleteBraintreePaymentMethodMutationResult = ApolloReactCommon.MutationResult<DeleteBraintreePaymentMethodMutation>;
export type DeleteBraintreePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBraintreePaymentMethodMutation, DeleteBraintreePaymentMethodMutationVariables>;
export const UpdateBraintreeDefaultPaymentMethodDocument = gql`
    mutation UpdateBraintreeDefaultPaymentMethod($token: String!, $userId: Int!) {
  updateBraintreeDefaultPaymentMethod(token: $token, userId: $userId) {
    id
    braintreeCustomer {
      ...BraintreeCustomerFragment
    }
  }
}
    ${BraintreeCustomerFragmentFragmentDoc}`;
export type UpdateBraintreeDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdateBraintreeDefaultPaymentMethodMutation, UpdateBraintreeDefaultPaymentMethodMutationVariables>;

/**
 * __useUpdateBraintreeDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateBraintreeDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBraintreeDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBraintreeDefaultPaymentMethodMutation, { data, loading, error }] = useUpdateBraintreeDefaultPaymentMethodMutation({
 *   variables: {
 *      token: // value for 'token'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateBraintreeDefaultPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBraintreeDefaultPaymentMethodMutation, UpdateBraintreeDefaultPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateBraintreeDefaultPaymentMethodMutation, UpdateBraintreeDefaultPaymentMethodMutationVariables>(UpdateBraintreeDefaultPaymentMethodDocument, options);
      }
export type UpdateBraintreeDefaultPaymentMethodMutationHookResult = ReturnType<typeof useUpdateBraintreeDefaultPaymentMethodMutation>;
export type UpdateBraintreeDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdateBraintreeDefaultPaymentMethodMutation>;
export type UpdateBraintreeDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBraintreeDefaultPaymentMethodMutation, UpdateBraintreeDefaultPaymentMethodMutationVariables>;
export const UpdateNotificationEmailDocument = gql`
    mutation UpdateNotificationEmail($email: String!, $userId: Int!) {
  updateEmail(email: $email, userId: $userId) {
    id
    email
  }
}
    `;
export type UpdateNotificationEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationEmailMutation, UpdateNotificationEmailMutationVariables>;

/**
 * __useUpdateNotificationEmailMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationEmailMutation, { data, loading, error }] = useUpdateNotificationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateNotificationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationEmailMutation, UpdateNotificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateNotificationEmailMutation, UpdateNotificationEmailMutationVariables>(UpdateNotificationEmailDocument, options);
      }
export type UpdateNotificationEmailMutationHookResult = ReturnType<typeof useUpdateNotificationEmailMutation>;
export type UpdateNotificationEmailMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationEmailMutation>;
export type UpdateNotificationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationEmailMutation, UpdateNotificationEmailMutationVariables>;
export const UpdateNotificationPhoneNumberDocument = gql`
    mutation UpdateNotificationPhoneNumber($phone: String!, $userId: Int!) {
  updatePhoneNumber(phone: $phone, userId: $userId) {
    id
    phone
  }
}
    `;
export type UpdateNotificationPhoneNumberMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationPhoneNumberMutation, UpdateNotificationPhoneNumberMutationVariables>;

/**
 * __useUpdateNotificationPhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationPhoneNumberMutation, { data, loading, error }] = useUpdateNotificationPhoneNumberMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateNotificationPhoneNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationPhoneNumberMutation, UpdateNotificationPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateNotificationPhoneNumberMutation, UpdateNotificationPhoneNumberMutationVariables>(UpdateNotificationPhoneNumberDocument, options);
      }
export type UpdateNotificationPhoneNumberMutationHookResult = ReturnType<typeof useUpdateNotificationPhoneNumberMutation>;
export type UpdateNotificationPhoneNumberMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationPhoneNumberMutation>;
export type UpdateNotificationPhoneNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationPhoneNumberMutation, UpdateNotificationPhoneNumberMutationVariables>;
export const UserToWaitListDocument = gql`
    mutation UserToWaitList($userId: Int!, $releaseId: Int!, $method: NotificationMethod!, $add: Boolean!) {
  userToWaitList(
    userId: $userId
    releaseId: $releaseId
    method: $method
    add: $add
  ) {
    id
    waitListMethods {
      userId
      releaseId
      method
    }
  }
}
    `;
export type UserToWaitListMutationFn = ApolloReactCommon.MutationFunction<UserToWaitListMutation, UserToWaitListMutationVariables>;

/**
 * __useUserToWaitListMutation__
 *
 * To run a mutation, you first call `useUserToWaitListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToWaitListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToWaitListMutation, { data, loading, error }] = useUserToWaitListMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      releaseId: // value for 'releaseId'
 *      method: // value for 'method'
 *      add: // value for 'add'
 *   },
 * });
 */
export function useUserToWaitListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserToWaitListMutation, UserToWaitListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserToWaitListMutation, UserToWaitListMutationVariables>(UserToWaitListDocument, options);
      }
export type UserToWaitListMutationHookResult = ReturnType<typeof useUserToWaitListMutation>;
export type UserToWaitListMutationResult = ApolloReactCommon.MutationResult<UserToWaitListMutation>;
export type UserToWaitListMutationOptions = ApolloReactCommon.BaseMutationOptions<UserToWaitListMutation, UserToWaitListMutationVariables>;
export const WaitListNotificationDocument = gql`
    query WaitListNotification($releaseId: Int!) {
  release(id: $releaseId) {
    id
    notification {
      id
      date
    }
    waitListMethods {
      userId
      releaseId
      method
    }
    addOns {
      addOnId
      releaseId
      addOn {
        id
        type
      }
    }
  }
}
    `;

/**
 * __useWaitListNotificationQuery__
 *
 * To run a query within a React component, call `useWaitListNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitListNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitListNotificationQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useWaitListNotificationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WaitListNotificationQuery, WaitListNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WaitListNotificationQuery, WaitListNotificationQueryVariables>(WaitListNotificationDocument, options);
      }
export function useWaitListNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WaitListNotificationQuery, WaitListNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WaitListNotificationQuery, WaitListNotificationQueryVariables>(WaitListNotificationDocument, options);
        }
export type WaitListNotificationQueryHookResult = ReturnType<typeof useWaitListNotificationQuery>;
export type WaitListNotificationLazyQueryHookResult = ReturnType<typeof useWaitListNotificationLazyQuery>;
export type WaitListNotificationQueryResult = ApolloReactCommon.QueryResult<WaitListNotificationQuery, WaitListNotificationQueryVariables>;
export const UpsertFcmTokenDocument = gql`
    mutation UpsertFcmToken($userId: Int!, $deviceId: String!, $token: String!) {
  upsertFcmToken(userId: $userId, deviceId: $deviceId, token: $token) {
    id
    deviceId
    token
    user {
      id
      fcmTokens {
        id
        token
        deviceId
      }
    }
  }
}
    `;
export type UpsertFcmTokenMutationFn = ApolloReactCommon.MutationFunction<UpsertFcmTokenMutation, UpsertFcmTokenMutationVariables>;

/**
 * __useUpsertFcmTokenMutation__
 *
 * To run a mutation, you first call `useUpsertFcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFcmTokenMutation, { data, loading, error }] = useUpsertFcmTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      deviceId: // value for 'deviceId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpsertFcmTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertFcmTokenMutation, UpsertFcmTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpsertFcmTokenMutation, UpsertFcmTokenMutationVariables>(UpsertFcmTokenDocument, options);
      }
export type UpsertFcmTokenMutationHookResult = ReturnType<typeof useUpsertFcmTokenMutation>;
export type UpsertFcmTokenMutationResult = ApolloReactCommon.MutationResult<UpsertFcmTokenMutation>;
export type UpsertFcmTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertFcmTokenMutation, UpsertFcmTokenMutationVariables>;
export const ProductContainerDocument = gql`
    query ProductContainer($sku: String!) {
  product(sku: $sku) {
    id
    name
    price
    sku
  }
}
    `;

/**
 * __useProductContainerQuery__
 *
 * To run a query within a React component, call `useProductContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductContainerQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useProductContainerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProductContainerQuery, ProductContainerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductContainerQuery, ProductContainerQueryVariables>(ProductContainerDocument, options);
      }
export function useProductContainerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductContainerQuery, ProductContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductContainerQuery, ProductContainerQueryVariables>(ProductContainerDocument, options);
        }
export type ProductContainerQueryHookResult = ReturnType<typeof useProductContainerQuery>;
export type ProductContainerLazyQueryHookResult = ReturnType<typeof useProductContainerLazyQuery>;
export type ProductContainerQueryResult = ApolloReactCommon.QueryResult<ProductContainerQuery, ProductContainerQueryVariables>;
export const RescheduleNotificationDocument = gql`
    mutation RescheduleNotification($releaseId: Int!, $date: DateTime!) {
  rescheduleNotification(releaseId: $releaseId, date: $date) {
    id
    campaignStatus
    notification {
      date
    }
  }
}
    `;
export type RescheduleNotificationMutationFn = ApolloReactCommon.MutationFunction<RescheduleNotificationMutation, RescheduleNotificationMutationVariables>;

/**
 * __useRescheduleNotificationMutation__
 *
 * To run a mutation, you first call `useRescheduleNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleNotificationMutation, { data, loading, error }] = useRescheduleNotificationMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRescheduleNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RescheduleNotificationMutation, RescheduleNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RescheduleNotificationMutation, RescheduleNotificationMutationVariables>(RescheduleNotificationDocument, options);
      }
export type RescheduleNotificationMutationHookResult = ReturnType<typeof useRescheduleNotificationMutation>;
export type RescheduleNotificationMutationResult = ApolloReactCommon.MutationResult<RescheduleNotificationMutation>;
export type RescheduleNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<RescheduleNotificationMutation, RescheduleNotificationMutationVariables>;
export const TextPricingTableDocument = gql`
    query TextPricingTable {
  textPricing {
    country
    price
  }
}
    `;

/**
 * __useTextPricingTableQuery__
 *
 * To run a query within a React component, call `useTextPricingTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextPricingTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextPricingTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextPricingTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TextPricingTableQuery, TextPricingTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TextPricingTableQuery, TextPricingTableQueryVariables>(TextPricingTableDocument, options);
      }
export function useTextPricingTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TextPricingTableQuery, TextPricingTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TextPricingTableQuery, TextPricingTableQueryVariables>(TextPricingTableDocument, options);
        }
export type TextPricingTableQueryHookResult = ReturnType<typeof useTextPricingTableQuery>;
export type TextPricingTableLazyQueryHookResult = ReturnType<typeof useTextPricingTableLazyQuery>;
export type TextPricingTableQueryResult = ApolloReactCommon.QueryResult<TextPricingTableQuery, TextPricingTableQueryVariables>;
export const CreateInAppPurchaseTransactionDocument = gql`
    mutation CreateInAppPurchaseTransaction($product: IAPProductInput!) {
  createInAppPurchaseTransaction(product: $product)
}
    `;
export type CreateInAppPurchaseTransactionMutationFn = ApolloReactCommon.MutationFunction<CreateInAppPurchaseTransactionMutation, CreateInAppPurchaseTransactionMutationVariables>;

/**
 * __useCreateInAppPurchaseTransactionMutation__
 *
 * To run a mutation, you first call `useCreateInAppPurchaseTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInAppPurchaseTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInAppPurchaseTransactionMutation, { data, loading, error }] = useCreateInAppPurchaseTransactionMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useCreateInAppPurchaseTransactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInAppPurchaseTransactionMutation, CreateInAppPurchaseTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateInAppPurchaseTransactionMutation, CreateInAppPurchaseTransactionMutationVariables>(CreateInAppPurchaseTransactionDocument, options);
      }
export type CreateInAppPurchaseTransactionMutationHookResult = ReturnType<typeof useCreateInAppPurchaseTransactionMutation>;
export type CreateInAppPurchaseTransactionMutationResult = ApolloReactCommon.MutationResult<CreateInAppPurchaseTransactionMutation>;
export type CreateInAppPurchaseTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInAppPurchaseTransactionMutation, CreateInAppPurchaseTransactionMutationVariables>;
export const InAppPurchaseProductsDocument = gql`
    query InAppPurchaseProducts($productIds: [String!]!) {
  encoreProducts(productIds: $productIds) {
    id
    sku
  }
}
    `;

/**
 * __useInAppPurchaseProductsQuery__
 *
 * To run a query within a React component, call `useInAppPurchaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInAppPurchaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInAppPurchaseProductsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useInAppPurchaseProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<InAppPurchaseProductsQuery, InAppPurchaseProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<InAppPurchaseProductsQuery, InAppPurchaseProductsQueryVariables>(InAppPurchaseProductsDocument, options);
      }
export function useInAppPurchaseProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InAppPurchaseProductsQuery, InAppPurchaseProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<InAppPurchaseProductsQuery, InAppPurchaseProductsQueryVariables>(InAppPurchaseProductsDocument, options);
        }
export type InAppPurchaseProductsQueryHookResult = ReturnType<typeof useInAppPurchaseProductsQuery>;
export type InAppPurchaseProductsLazyQueryHookResult = ReturnType<typeof useInAppPurchaseProductsLazyQuery>;
export type InAppPurchaseProductsQueryResult = ApolloReactCommon.QueryResult<InAppPurchaseProductsQuery, InAppPurchaseProductsQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($uid: String!, $email: String, $username: String) {
  createUser(uid: $uid, email: $email, username: $username) {
    id
    uid
    email
    username
    roles {
      id
      name
    }
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteFirebaseUserDocument = gql`
    mutation DeleteFirebaseUser($uid: String!) {
  deleteFirebaseUser(uid: $uid)
}
    `;
export type DeleteFirebaseUserMutationFn = ApolloReactCommon.MutationFunction<DeleteFirebaseUserMutation, DeleteFirebaseUserMutationVariables>;

/**
 * __useDeleteFirebaseUserMutation__
 *
 * To run a mutation, you first call `useDeleteFirebaseUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFirebaseUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFirebaseUserMutation, { data, loading, error }] = useDeleteFirebaseUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteFirebaseUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFirebaseUserMutation, DeleteFirebaseUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteFirebaseUserMutation, DeleteFirebaseUserMutationVariables>(DeleteFirebaseUserDocument, options);
      }
export type DeleteFirebaseUserMutationHookResult = ReturnType<typeof useDeleteFirebaseUserMutation>;
export type DeleteFirebaseUserMutationResult = ApolloReactCommon.MutationResult<DeleteFirebaseUserMutation>;
export type DeleteFirebaseUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFirebaseUserMutation, DeleteFirebaseUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($uid: String!) {
  deleteUser(uid: $uid) {
    id
  }
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const LoggedInUserDocument = gql`
    query LoggedInUser($uid: String!) {
  loggedInUser(uid: $uid) {
    id
    uid
    username
    imageURL
    bio
    reputation
    releaseThreshold
    email
    phone
    type
    wallet
    roles {
      id
      name
    }
    linkedAccounts {
      status
      artistId
      userId
      artist {
        id
        name
        imageURL
      }
    }
    fcmTokens {
      id
      deviceId
      token
    }
  }
}
    `;

/**
 * __useLoggedInUserQuery__
 *
 * To run a query within a React component, call `useLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useLoggedInUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LoggedInUserQuery, LoggedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LoggedInUserQuery, LoggedInUserQueryVariables>(LoggedInUserDocument, options);
      }
export function useLoggedInUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserQuery, LoggedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LoggedInUserQuery, LoggedInUserQueryVariables>(LoggedInUserDocument, options);
        }
export type LoggedInUserQueryHookResult = ReturnType<typeof useLoggedInUserQuery>;
export type LoggedInUserLazyQueryHookResult = ReturnType<typeof useLoggedInUserLazyQuery>;
export type LoggedInUserQueryResult = ApolloReactCommon.QueryResult<LoggedInUserQuery, LoggedInUserQueryVariables>;
export const UpsertUserAuthDocument = gql`
    mutation UpsertUserAuth($uid: String!, $email: String, $username: String) {
  upsertUserAuth(uid: $uid, email: $email, username: $username) {
    id
    username
    email
    uid
    phone
  }
}
    `;
export type UpsertUserAuthMutationFn = ApolloReactCommon.MutationFunction<UpsertUserAuthMutation, UpsertUserAuthMutationVariables>;

/**
 * __useUpsertUserAuthMutation__
 *
 * To run a mutation, you first call `useUpsertUserAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserAuthMutation, { data, loading, error }] = useUpsertUserAuthMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUpsertUserAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUserAuthMutation, UpsertUserAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpsertUserAuthMutation, UpsertUserAuthMutationVariables>(UpsertUserAuthDocument, options);
      }
export type UpsertUserAuthMutationHookResult = ReturnType<typeof useUpsertUserAuthMutation>;
export type UpsertUserAuthMutationResult = ApolloReactCommon.MutationResult<UpsertUserAuthMutation>;
export type UpsertUserAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUserAuthMutation, UpsertUserAuthMutationVariables>;
export const CreateS3PresignedUrlDocument = gql`
    mutation CreateS3PresignedUrl($key: String!) {
  createS3PresignedUrl(key: $key)
}
    `;
export type CreateS3PresignedUrlMutationFn = ApolloReactCommon.MutationFunction<CreateS3PresignedUrlMutation, CreateS3PresignedUrlMutationVariables>;

/**
 * __useCreateS3PresignedUrlMutation__
 *
 * To run a mutation, you first call `useCreateS3PresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateS3PresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createS3PresignedUrlMutation, { data, loading, error }] = useCreateS3PresignedUrlMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useCreateS3PresignedUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateS3PresignedUrlMutation, CreateS3PresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateS3PresignedUrlMutation, CreateS3PresignedUrlMutationVariables>(CreateS3PresignedUrlDocument, options);
      }
export type CreateS3PresignedUrlMutationHookResult = ReturnType<typeof useCreateS3PresignedUrlMutation>;
export type CreateS3PresignedUrlMutationResult = ApolloReactCommon.MutationResult<CreateS3PresignedUrlMutation>;
export type CreateS3PresignedUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateS3PresignedUrlMutation, CreateS3PresignedUrlMutationVariables>;
export const SignS3ImageDocument = gql`
    mutation SignS3Image($key: String!, $width: Int, $height: Int) {
  signS3Image(key: $key, width: $width, height: $height)
}
    `;
export type SignS3ImageMutationFn = ApolloReactCommon.MutationFunction<SignS3ImageMutation, SignS3ImageMutationVariables>;

/**
 * __useSignS3ImageMutation__
 *
 * To run a mutation, you first call `useSignS3ImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignS3ImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signS3ImageMutation, { data, loading, error }] = useSignS3ImageMutation({
 *   variables: {
 *      key: // value for 'key'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useSignS3ImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignS3ImageMutation, SignS3ImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SignS3ImageMutation, SignS3ImageMutationVariables>(SignS3ImageDocument, options);
      }
export type SignS3ImageMutationHookResult = ReturnType<typeof useSignS3ImageMutation>;
export type SignS3ImageMutationResult = ApolloReactCommon.MutationResult<SignS3ImageMutation>;
export type SignS3ImageMutationOptions = ApolloReactCommon.BaseMutationOptions<SignS3ImageMutation, SignS3ImageMutationVariables>;
export const ReleaseAnalyticsDocument = gql`
    query ReleaseAnalytics($releaseId: Int!) {
  release(id: $releaseId) {
    id
    encore
  }
}
    `;

/**
 * __useReleaseAnalyticsQuery__
 *
 * To run a query within a React component, call `useReleaseAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseAnalyticsQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useReleaseAnalyticsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReleaseAnalyticsQuery, ReleaseAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReleaseAnalyticsQuery, ReleaseAnalyticsQueryVariables>(ReleaseAnalyticsDocument, options);
      }
export function useReleaseAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReleaseAnalyticsQuery, ReleaseAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReleaseAnalyticsQuery, ReleaseAnalyticsQueryVariables>(ReleaseAnalyticsDocument, options);
        }
export type ReleaseAnalyticsQueryHookResult = ReturnType<typeof useReleaseAnalyticsQuery>;
export type ReleaseAnalyticsLazyQueryHookResult = ReturnType<typeof useReleaseAnalyticsLazyQuery>;
export type ReleaseAnalyticsQueryResult = ApolloReactCommon.QueryResult<ReleaseAnalyticsQuery, ReleaseAnalyticsQueryVariables>;
export const UserToArtistDocument = gql`
    mutation UserToArtist($userId: Int!, $artistId: Int!, $follow: Boolean!) {
  userToArtist(userId: $userId, artistId: $artistId, follow: $follow) {
    id
    following
    followerCount
  }
}
    `;
export type UserToArtistMutationFn = ApolloReactCommon.MutationFunction<UserToArtistMutation, UserToArtistMutationVariables>;

/**
 * __useUserToArtistMutation__
 *
 * To run a mutation, you first call `useUserToArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToArtistMutation, { data, loading, error }] = useUserToArtistMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      artistId: // value for 'artistId'
 *      follow: // value for 'follow'
 *   },
 * });
 */
export function useUserToArtistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserToArtistMutation, UserToArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserToArtistMutation, UserToArtistMutationVariables>(UserToArtistDocument, options);
      }
export type UserToArtistMutationHookResult = ReturnType<typeof useUserToArtistMutation>;
export type UserToArtistMutationResult = ApolloReactCommon.MutationResult<UserToArtistMutation>;
export type UserToArtistMutationOptions = ApolloReactCommon.BaseMutationOptions<UserToArtistMutation, UserToArtistMutationVariables>;
export const ArtistDocument = gql`
    query Artist($id: Int!) {
  artist(id: $id) {
    id
    name
    imageURL
    following
    followerCount
    dynamicLinkEnding
    bgColor
    rumors(first: 3) {
      releaseId
      artistId
      release {
        id
        title
        status
        creator {
          id
          username
          imageURL
        }
        artists {
          artistId
          releaseId
          artist {
            id
            name
            imageURL
          }
        }
      }
    }
    rumorCount
    releases(first: 10) {
      releaseId
      artistId
      release {
        id
        title
        imageURL
        artists {
          artistId
          releaseId
          artist {
            id
            name
          }
        }
      }
    }
    releaseCount
  }
}
    `;

/**
 * __useArtistQuery__
 *
 * To run a query within a React component, call `useArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArtistQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ArtistQuery, ArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ArtistQuery, ArtistQueryVariables>(ArtistDocument, options);
      }
export function useArtistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtistQuery, ArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ArtistQuery, ArtistQueryVariables>(ArtistDocument, options);
        }
export type ArtistQueryHookResult = ReturnType<typeof useArtistQuery>;
export type ArtistLazyQueryHookResult = ReturnType<typeof useArtistLazyQuery>;
export type ArtistQueryResult = ApolloReactCommon.QueryResult<ArtistQuery, ArtistQueryVariables>;
export const ArtistReleasesDocument = gql`
    query ArtistReleases($artistId: Int!, $first: Int!, $after: String) {
  artistReleases(artistId: $artistId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        title
        imageURL
        artists {
          artistId
          releaseId
          artist {
            id
            name
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useArtistReleasesQuery__
 *
 * To run a query within a React component, call `useArtistReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistReleasesQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useArtistReleasesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ArtistReleasesQuery, ArtistReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ArtistReleasesQuery, ArtistReleasesQueryVariables>(ArtistReleasesDocument, options);
      }
export function useArtistReleasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtistReleasesQuery, ArtistReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ArtistReleasesQuery, ArtistReleasesQueryVariables>(ArtistReleasesDocument, options);
        }
export type ArtistReleasesQueryHookResult = ReturnType<typeof useArtistReleasesQuery>;
export type ArtistReleasesLazyQueryHookResult = ReturnType<typeof useArtistReleasesLazyQuery>;
export type ArtistReleasesQueryResult = ApolloReactCommon.QueryResult<ArtistReleasesQuery, ArtistReleasesQueryVariables>;
export const ArtistRumorsDocument = gql`
    query ArtistRumors($artistId: Int!, $first: Int!, $after: String) {
  artistRumors(artistId: $artistId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        title
        date
        status
        creator {
          id
          username
          imageURL
        }
        artists {
          releaseId
          artistId
          artist {
            id
            name
            imageURL
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useArtistRumorsQuery__
 *
 * To run a query within a React component, call `useArtistRumorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistRumorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistRumorsQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useArtistRumorsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ArtistRumorsQuery, ArtistRumorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ArtistRumorsQuery, ArtistRumorsQueryVariables>(ArtistRumorsDocument, options);
      }
export function useArtistRumorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtistRumorsQuery, ArtistRumorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ArtistRumorsQuery, ArtistRumorsQueryVariables>(ArtistRumorsDocument, options);
        }
export type ArtistRumorsQueryHookResult = ReturnType<typeof useArtistRumorsQuery>;
export type ArtistRumorsLazyQueryHookResult = ReturnType<typeof useArtistRumorsLazyQuery>;
export type ArtistRumorsQueryResult = ApolloReactCommon.QueryResult<ArtistRumorsQuery, ArtistRumorsQueryVariables>;
export const UsernameExistsDocument = gql`
    query UsernameExists($username: String!) {
  usernameExists(username: $username)
}
    `;

/**
 * __useUsernameExistsQuery__
 *
 * To run a query within a React component, call `useUsernameExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameExistsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameExistsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UsernameExistsQuery, UsernameExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UsernameExistsQuery, UsernameExistsQueryVariables>(UsernameExistsDocument, options);
      }
export function useUsernameExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsernameExistsQuery, UsernameExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UsernameExistsQuery, UsernameExistsQueryVariables>(UsernameExistsDocument, options);
        }
export type UsernameExistsQueryHookResult = ReturnType<typeof useUsernameExistsQuery>;
export type UsernameExistsLazyQueryHookResult = ReturnType<typeof useUsernameExistsLazyQuery>;
export type UsernameExistsQueryResult = ApolloReactCommon.QueryResult<UsernameExistsQuery, UsernameExistsQueryVariables>;
export const CreateArtistDocument = gql`
    mutation CreateArtist($artist: ArtistInput!) {
  createArtist(artist: $artist) {
    id
    name
    imageURL
  }
}
    `;
export type CreateArtistMutationFn = ApolloReactCommon.MutationFunction<CreateArtistMutation, CreateArtistMutationVariables>;

/**
 * __useCreateArtistMutation__
 *
 * To run a mutation, you first call `useCreateArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtistMutation, { data, loading, error }] = useCreateArtistMutation({
 *   variables: {
 *      artist: // value for 'artist'
 *   },
 * });
 */
export function useCreateArtistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtistMutation, CreateArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateArtistMutation, CreateArtistMutationVariables>(CreateArtistDocument, options);
      }
export type CreateArtistMutationHookResult = ReturnType<typeof useCreateArtistMutation>;
export type CreateArtistMutationResult = ApolloReactCommon.MutationResult<CreateArtistMutation>;
export type CreateArtistMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtistMutation, CreateArtistMutationVariables>;
export const SearchSpotifyArtistDocument = gql`
    query SearchSpotifyArtist($q: String!, $type: [SpotifySearchType!]!) {
  spotifySearch(q: $q, type: $type) {
    artists {
      items {
        id
        followers {
          total
        }
        genres
        images {
          url
        }
        name
        popularity
        external_urls {
          spotify
        }
      }
    }
  }
}
    `;

/**
 * __useSearchSpotifyArtistQuery__
 *
 * To run a query within a React component, call `useSearchSpotifyArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSpotifyArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSpotifyArtistQuery({
 *   variables: {
 *      q: // value for 'q'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSearchSpotifyArtistQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchSpotifyArtistQuery, SearchSpotifyArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchSpotifyArtistQuery, SearchSpotifyArtistQueryVariables>(SearchSpotifyArtistDocument, options);
      }
export function useSearchSpotifyArtistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSpotifyArtistQuery, SearchSpotifyArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchSpotifyArtistQuery, SearchSpotifyArtistQueryVariables>(SearchSpotifyArtistDocument, options);
        }
export type SearchSpotifyArtistQueryHookResult = ReturnType<typeof useSearchSpotifyArtistQuery>;
export type SearchSpotifyArtistLazyQueryHookResult = ReturnType<typeof useSearchSpotifyArtistLazyQuery>;
export type SearchSpotifyArtistQueryResult = ApolloReactCommon.QueryResult<SearchSpotifyArtistQuery, SearchSpotifyArtistQueryVariables>;
export const SpotifyArtistExistsDocument = gql`
    query SpotifyArtistExists($spotifyId: String!) {
  spotifyArtistExists(spotifyId: $spotifyId)
}
    `;

/**
 * __useSpotifyArtistExistsQuery__
 *
 * To run a query within a React component, call `useSpotifyArtistExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpotifyArtistExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpotifyArtistExistsQuery({
 *   variables: {
 *      spotifyId: // value for 'spotifyId'
 *   },
 * });
 */
export function useSpotifyArtistExistsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SpotifyArtistExistsQuery, SpotifyArtistExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SpotifyArtistExistsQuery, SpotifyArtistExistsQueryVariables>(SpotifyArtistExistsDocument, options);
      }
export function useSpotifyArtistExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpotifyArtistExistsQuery, SpotifyArtistExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SpotifyArtistExistsQuery, SpotifyArtistExistsQueryVariables>(SpotifyArtistExistsDocument, options);
        }
export type SpotifyArtistExistsQueryHookResult = ReturnType<typeof useSpotifyArtistExistsQuery>;
export type SpotifyArtistExistsLazyQueryHookResult = ReturnType<typeof useSpotifyArtistExistsLazyQuery>;
export type SpotifyArtistExistsQueryResult = ApolloReactCommon.QueryResult<SpotifyArtistExistsQuery, SpotifyArtistExistsQueryVariables>;
export const ArtistSearchDocument = gql`
    query ArtistSearch($q: String!) {
  search(q: $q) {
    id
    name
    imageURL
  }
}
    `;

/**
 * __useArtistSearchQuery__
 *
 * To run a query within a React component, call `useArtistSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistSearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useArtistSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ArtistSearchQuery, ArtistSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ArtistSearchQuery, ArtistSearchQueryVariables>(ArtistSearchDocument, options);
      }
export function useArtistSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtistSearchQuery, ArtistSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ArtistSearchQuery, ArtistSearchQueryVariables>(ArtistSearchDocument, options);
        }
export type ArtistSearchQueryHookResult = ReturnType<typeof useArtistSearchQuery>;
export type ArtistSearchLazyQueryHookResult = ReturnType<typeof useArtistSearchLazyQuery>;
export type ArtistSearchQueryResult = ApolloReactCommon.QueryResult<ArtistSearchQuery, ArtistSearchQueryVariables>;
export const CampaignNameExistsDocument = gql`
    query CampaignNameExists($campaignName: String!) {
  campaignNameExists(campaignName: $campaignName)
}
    `;

/**
 * __useCampaignNameExistsQuery__
 *
 * To run a query within a React component, call `useCampaignNameExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignNameExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignNameExistsQuery({
 *   variables: {
 *      campaignName: // value for 'campaignName'
 *   },
 * });
 */
export function useCampaignNameExistsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CampaignNameExistsQuery, CampaignNameExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CampaignNameExistsQuery, CampaignNameExistsQueryVariables>(CampaignNameExistsDocument, options);
      }
export function useCampaignNameExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignNameExistsQuery, CampaignNameExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CampaignNameExistsQuery, CampaignNameExistsQueryVariables>(CampaignNameExistsDocument, options);
        }
export type CampaignNameExistsQueryHookResult = ReturnType<typeof useCampaignNameExistsQuery>;
export type CampaignNameExistsLazyQueryHookResult = ReturnType<typeof useCampaignNameExistsLazyQuery>;
export type CampaignNameExistsQueryResult = ApolloReactCommon.QueryResult<CampaignNameExistsQuery, CampaignNameExistsQueryVariables>;
export const CreateReleaseDocument = gql`
    mutation CreateRelease($release: CreateReleaseInput!) {
  createRelease(release: $release) {
    ...ReleasePageFragment
  }
}
    ${ReleasePageFragmentFragmentDoc}`;
export type CreateReleaseMutationFn = ApolloReactCommon.MutationFunction<CreateReleaseMutation, CreateReleaseMutationVariables>;

/**
 * __useCreateReleaseMutation__
 *
 * To run a mutation, you first call `useCreateReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReleaseMutation, { data, loading, error }] = useCreateReleaseMutation({
 *   variables: {
 *      release: // value for 'release'
 *   },
 * });
 */
export function useCreateReleaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReleaseMutation, CreateReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateReleaseMutation, CreateReleaseMutationVariables>(CreateReleaseDocument, options);
      }
export type CreateReleaseMutationHookResult = ReturnType<typeof useCreateReleaseMutation>;
export type CreateReleaseMutationResult = ApolloReactCommon.MutationResult<CreateReleaseMutation>;
export type CreateReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReleaseMutation, CreateReleaseMutationVariables>;
export const DeleteReleaseDocument = gql`
    mutation DeleteRelease($releaseId: Int!) {
  deleteRelease(releaseId: $releaseId) {
    ...ReleasePageFragment
  }
}
    ${ReleasePageFragmentFragmentDoc}`;
export type DeleteReleaseMutationFn = ApolloReactCommon.MutationFunction<DeleteReleaseMutation, DeleteReleaseMutationVariables>;

/**
 * __useDeleteReleaseMutation__
 *
 * To run a mutation, you first call `useDeleteReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReleaseMutation, { data, loading, error }] = useDeleteReleaseMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useDeleteReleaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReleaseMutation, DeleteReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteReleaseMutation, DeleteReleaseMutationVariables>(DeleteReleaseDocument, options);
      }
export type DeleteReleaseMutationHookResult = ReturnType<typeof useDeleteReleaseMutation>;
export type DeleteReleaseMutationResult = ApolloReactCommon.MutationResult<DeleteReleaseMutation>;
export type DeleteReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReleaseMutation, DeleteReleaseMutationVariables>;
export const EncoreProductsDocument = gql`
    query EncoreProducts($productIds: [String!]!) {
  encoreProducts(productIds: $productIds) {
    id
    price
    sku
    name
  }
}
    `;

/**
 * __useEncoreProductsQuery__
 *
 * To run a query within a React component, call `useEncoreProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEncoreProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEncoreProductsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useEncoreProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EncoreProductsQuery, EncoreProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<EncoreProductsQuery, EncoreProductsQueryVariables>(EncoreProductsDocument, options);
      }
export function useEncoreProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EncoreProductsQuery, EncoreProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<EncoreProductsQuery, EncoreProductsQueryVariables>(EncoreProductsDocument, options);
        }
export type EncoreProductsQueryHookResult = ReturnType<typeof useEncoreProductsQuery>;
export type EncoreProductsLazyQueryHookResult = ReturnType<typeof useEncoreProductsLazyQuery>;
export type EncoreProductsQueryResult = ApolloReactCommon.QueryResult<EncoreProductsQuery, EncoreProductsQueryVariables>;
export const LoadCreatePageDocument = gql`
    query LoadCreatePage($id: Int!) {
  user(id: $id) {
    id
    type
    linkedAccounts {
      userId
      artistId
      status
      artist {
        id
        name
        imageURL
      }
    }
  }
}
    `;

/**
 * __useLoadCreatePageQuery__
 *
 * To run a query within a React component, call `useLoadCreatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCreatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCreatePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadCreatePageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LoadCreatePageQuery, LoadCreatePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LoadCreatePageQuery, LoadCreatePageQueryVariables>(LoadCreatePageDocument, options);
      }
export function useLoadCreatePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadCreatePageQuery, LoadCreatePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LoadCreatePageQuery, LoadCreatePageQueryVariables>(LoadCreatePageDocument, options);
        }
export type LoadCreatePageQueryHookResult = ReturnType<typeof useLoadCreatePageQuery>;
export type LoadCreatePageLazyQueryHookResult = ReturnType<typeof useLoadCreatePageLazyQuery>;
export type LoadCreatePageQueryResult = ApolloReactCommon.QueryResult<LoadCreatePageQuery, LoadCreatePageQueryVariables>;
export const LoadCreatePageInitialArtistDocument = gql`
    query LoadCreatePageInitialArtist($artistId: Int!) {
  artist(id: $artistId) {
    id
    imageURL
    name
  }
}
    `;

/**
 * __useLoadCreatePageInitialArtistQuery__
 *
 * To run a query within a React component, call `useLoadCreatePageInitialArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCreatePageInitialArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCreatePageInitialArtistQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useLoadCreatePageInitialArtistQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LoadCreatePageInitialArtistQuery, LoadCreatePageInitialArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LoadCreatePageInitialArtistQuery, LoadCreatePageInitialArtistQueryVariables>(LoadCreatePageInitialArtistDocument, options);
      }
export function useLoadCreatePageInitialArtistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadCreatePageInitialArtistQuery, LoadCreatePageInitialArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LoadCreatePageInitialArtistQuery, LoadCreatePageInitialArtistQueryVariables>(LoadCreatePageInitialArtistDocument, options);
        }
export type LoadCreatePageInitialArtistQueryHookResult = ReturnType<typeof useLoadCreatePageInitialArtistQuery>;
export type LoadCreatePageInitialArtistLazyQueryHookResult = ReturnType<typeof useLoadCreatePageInitialArtistLazyQuery>;
export type LoadCreatePageInitialArtistQueryResult = ApolloReactCommon.QueryResult<LoadCreatePageInitialArtistQuery, LoadCreatePageInitialArtistQueryVariables>;
export const LoadEditReleaseDocument = gql`
    query LoadEditRelease($id: Int!) {
  release(id: $id) {
    id
    title
    date
    artists {
      artistId
      releaseId
      artist {
        id
        name
        imageURL
      }
    }
    status
    imageURL
    bgColor
    links {
      id
      url
      order
    }
    type
    creatorId
    recordLabel
    message
    encore
    visibility
    budget
    campaignStatus
    notification {
      id
      taskName
      date
    }
    addOns {
      addOnId
      releaseId
      addOn {
        id
        type
      }
    }
    customEnding
  }
}
    `;

/**
 * __useLoadEditReleaseQuery__
 *
 * To run a query within a React component, call `useLoadEditReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEditReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEditReleaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadEditReleaseQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LoadEditReleaseQuery, LoadEditReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LoadEditReleaseQuery, LoadEditReleaseQueryVariables>(LoadEditReleaseDocument, options);
      }
export function useLoadEditReleaseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadEditReleaseQuery, LoadEditReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LoadEditReleaseQuery, LoadEditReleaseQueryVariables>(LoadEditReleaseDocument, options);
        }
export type LoadEditReleaseQueryHookResult = ReturnType<typeof useLoadEditReleaseQuery>;
export type LoadEditReleaseLazyQueryHookResult = ReturnType<typeof useLoadEditReleaseLazyQuery>;
export type LoadEditReleaseQueryResult = ApolloReactCommon.QueryResult<LoadEditReleaseQuery, LoadEditReleaseQueryVariables>;
export const ReleaseExistsDocument = gql`
    query ReleaseExists($artistIds: [Int!]!, $date: Date!, $title: String!) {
  releaseExists(artistIds: $artistIds, date: $date, title: $title)
}
    `;

/**
 * __useReleaseExistsQuery__
 *
 * To run a query within a React component, call `useReleaseExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseExistsQuery({
 *   variables: {
 *      artistIds: // value for 'artistIds'
 *      date: // value for 'date'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useReleaseExistsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReleaseExistsQuery, ReleaseExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReleaseExistsQuery, ReleaseExistsQueryVariables>(ReleaseExistsDocument, options);
      }
export function useReleaseExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReleaseExistsQuery, ReleaseExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReleaseExistsQuery, ReleaseExistsQueryVariables>(ReleaseExistsDocument, options);
        }
export type ReleaseExistsQueryHookResult = ReturnType<typeof useReleaseExistsQuery>;
export type ReleaseExistsLazyQueryHookResult = ReturnType<typeof useReleaseExistsLazyQuery>;
export type ReleaseExistsQueryResult = ApolloReactCommon.QueryResult<ReleaseExistsQuery, ReleaseExistsQueryVariables>;
export const SimilarSubmissionsDocument = gql`
    query SimilarSubmissions($artistIds: [Int!]!, $date: Date!, $title: String!) {
  similarSubmissions(artistIds: $artistIds, date: $date, title: $title) {
    id
    title
    artists {
      artistId
      releaseId
      artist {
        name
      }
    }
    supporters {
      userId
      releaseId
      user {
        id
        username
        imageURL
      }
    }
  }
}
    `;

/**
 * __useSimilarSubmissionsQuery__
 *
 * To run a query within a React component, call `useSimilarSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarSubmissionsQuery({
 *   variables: {
 *      artistIds: // value for 'artistIds'
 *      date: // value for 'date'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useSimilarSubmissionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SimilarSubmissionsQuery, SimilarSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SimilarSubmissionsQuery, SimilarSubmissionsQueryVariables>(SimilarSubmissionsDocument, options);
      }
export function useSimilarSubmissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimilarSubmissionsQuery, SimilarSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SimilarSubmissionsQuery, SimilarSubmissionsQueryVariables>(SimilarSubmissionsDocument, options);
        }
export type SimilarSubmissionsQueryHookResult = ReturnType<typeof useSimilarSubmissionsQuery>;
export type SimilarSubmissionsLazyQueryHookResult = ReturnType<typeof useSimilarSubmissionsLazyQuery>;
export type SimilarSubmissionsQueryResult = ApolloReactCommon.QueryResult<SimilarSubmissionsQuery, SimilarSubmissionsQueryVariables>;
export const UpdateReleaseDocument = gql`
    mutation UpdateRelease($release: UpdateReleaseInput!) {
  updateRelease(release: $release) {
    ...ReleasePageFragment
  }
}
    ${ReleasePageFragmentFragmentDoc}`;
export type UpdateReleaseMutationFn = ApolloReactCommon.MutationFunction<UpdateReleaseMutation, UpdateReleaseMutationVariables>;

/**
 * __useUpdateReleaseMutation__
 *
 * To run a mutation, you first call `useUpdateReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReleaseMutation, { data, loading, error }] = useUpdateReleaseMutation({
 *   variables: {
 *      release: // value for 'release'
 *   },
 * });
 */
export function useUpdateReleaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReleaseMutation, UpdateReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateReleaseMutation, UpdateReleaseMutationVariables>(UpdateReleaseDocument, options);
      }
export type UpdateReleaseMutationHookResult = ReturnType<typeof useUpdateReleaseMutation>;
export type UpdateReleaseMutationResult = ApolloReactCommon.MutationResult<UpdateReleaseMutation>;
export type UpdateReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReleaseMutation, UpdateReleaseMutationVariables>;
export const UserReleaseFeedDocument = gql`
    query UserReleaseFeed($userId: Int!, $first: Int!, $after: String) {
  userReleaseFeed(userId: $userId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        title
        date
        score
        imageURL
        status
        message
        artists {
          artistId
          releaseId
          artist {
            id
            name
            imageURL
          }
        }
        creator {
          id
          username
          imageURL
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserReleaseFeedQuery__
 *
 * To run a query within a React component, call `useUserReleaseFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReleaseFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReleaseFeedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserReleaseFeedQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserReleaseFeedQuery, UserReleaseFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserReleaseFeedQuery, UserReleaseFeedQueryVariables>(UserReleaseFeedDocument, options);
      }
export function useUserReleaseFeedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserReleaseFeedQuery, UserReleaseFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserReleaseFeedQuery, UserReleaseFeedQueryVariables>(UserReleaseFeedDocument, options);
        }
export type UserReleaseFeedQueryHookResult = ReturnType<typeof useUserReleaseFeedQuery>;
export type UserReleaseFeedLazyQueryHookResult = ReturnType<typeof useUserReleaseFeedLazyQuery>;
export type UserReleaseFeedQueryResult = ApolloReactCommon.QueryResult<UserReleaseFeedQuery, UserReleaseFeedQueryVariables>;
export const UserRumorFeedDocument = gql`
    query UserRumorFeed($userId: Int!, $first: Int!, $after: String, $releaseThreshold: Int) {
  userRumorFeed(
    userId: $userId
    first: $first
    after: $after
    releaseThreshold: $releaseThreshold
  ) {
    edges {
      cursor
      node {
        id
        title
        date
        score
        status
        imageURL
        artists {
          releaseId
          artistId
          artist {
            id
            name
            imageURL
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserRumorFeedQuery__
 *
 * To run a query within a React component, call `useUserRumorFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRumorFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRumorFeedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      releaseThreshold: // value for 'releaseThreshold'
 *   },
 * });
 */
export function useUserRumorFeedQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserRumorFeedQuery, UserRumorFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserRumorFeedQuery, UserRumorFeedQueryVariables>(UserRumorFeedDocument, options);
      }
export function useUserRumorFeedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRumorFeedQuery, UserRumorFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserRumorFeedQuery, UserRumorFeedQueryVariables>(UserRumorFeedDocument, options);
        }
export type UserRumorFeedQueryHookResult = ReturnType<typeof useUserRumorFeedQuery>;
export type UserRumorFeedLazyQueryHookResult = ReturnType<typeof useUserRumorFeedLazyQuery>;
export type UserRumorFeedQueryResult = ApolloReactCommon.QueryResult<UserRumorFeedQuery, UserRumorFeedQueryVariables>;
export const UserStoriesDocument = gql`
    query UserStories($userId: Int!, $first: Int!, $after: String) {
  userReleaseFeed(userId: $userId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        imageURL
        artists {
          artistId
          releaseId
          artist {
            id
            name
            imageURL
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserStoriesQuery__
 *
 * To run a query within a React component, call `useUserStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStoriesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserStoriesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserStoriesQuery, UserStoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserStoriesQuery, UserStoriesQueryVariables>(UserStoriesDocument, options);
      }
export function useUserStoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStoriesQuery, UserStoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserStoriesQuery, UserStoriesQueryVariables>(UserStoriesDocument, options);
        }
export type UserStoriesQueryHookResult = ReturnType<typeof useUserStoriesQuery>;
export type UserStoriesLazyQueryHookResult = ReturnType<typeof useUserStoriesLazyQuery>;
export type UserStoriesQueryResult = ApolloReactCommon.QueryResult<UserStoriesQuery, UserStoriesQueryVariables>;
export const ArtistSuggestionsDocument = gql`
    query ArtistSuggestions($userId: Int!) {
  artistSuggestions(userId: $userId) {
    id
    name
    imageURL
    bgColor
    followerCount
    genres {
      name
    }
  }
}
    `;

/**
 * __useArtistSuggestionsQuery__
 *
 * To run a query within a React component, call `useArtistSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistSuggestionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useArtistSuggestionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ArtistSuggestionsQuery, ArtistSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ArtistSuggestionsQuery, ArtistSuggestionsQueryVariables>(ArtistSuggestionsDocument, options);
      }
export function useArtistSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtistSuggestionsQuery, ArtistSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ArtistSuggestionsQuery, ArtistSuggestionsQueryVariables>(ArtistSuggestionsDocument, options);
        }
export type ArtistSuggestionsQueryHookResult = ReturnType<typeof useArtistSuggestionsQuery>;
export type ArtistSuggestionsLazyQueryHookResult = ReturnType<typeof useArtistSuggestionsLazyQuery>;
export type ArtistSuggestionsQueryResult = ApolloReactCommon.QueryResult<ArtistSuggestionsQuery, ArtistSuggestionsQueryVariables>;
export const FeaturedReleasesDocument = gql`
    query FeaturedReleases($take: Int!) {
  featuredReleases(take: $take) {
    id
    title
    imageURL
    artists {
      artistId
      releaseId
      artist {
        id
        name
        imageURL
      }
    }
    date
  }
}
    `;

/**
 * __useFeaturedReleasesQuery__
 *
 * To run a query within a React component, call `useFeaturedReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedReleasesQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFeaturedReleasesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FeaturedReleasesQuery, FeaturedReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FeaturedReleasesQuery, FeaturedReleasesQueryVariables>(FeaturedReleasesDocument, options);
      }
export function useFeaturedReleasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturedReleasesQuery, FeaturedReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FeaturedReleasesQuery, FeaturedReleasesQueryVariables>(FeaturedReleasesDocument, options);
        }
export type FeaturedReleasesQueryHookResult = ReturnType<typeof useFeaturedReleasesQuery>;
export type FeaturedReleasesLazyQueryHookResult = ReturnType<typeof useFeaturedReleasesLazyQuery>;
export type FeaturedReleasesQueryResult = ApolloReactCommon.QueryResult<FeaturedReleasesQuery, FeaturedReleasesQueryVariables>;
export const UnAuthArtistSuggestionsDocument = gql`
    query UnAuthArtistSuggestions($take: Int) {
  unAuthArtistSuggestions(take: $take) {
    id
    name
    imageURL
    bgColor
    followerCount
    genres {
      name
    }
  }
}
    `;

/**
 * __useUnAuthArtistSuggestionsQuery__
 *
 * To run a query within a React component, call `useUnAuthArtistSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnAuthArtistSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnAuthArtistSuggestionsQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUnAuthArtistSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnAuthArtistSuggestionsQuery, UnAuthArtistSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UnAuthArtistSuggestionsQuery, UnAuthArtistSuggestionsQueryVariables>(UnAuthArtistSuggestionsDocument, options);
      }
export function useUnAuthArtistSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnAuthArtistSuggestionsQuery, UnAuthArtistSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UnAuthArtistSuggestionsQuery, UnAuthArtistSuggestionsQueryVariables>(UnAuthArtistSuggestionsDocument, options);
        }
export type UnAuthArtistSuggestionsQueryHookResult = ReturnType<typeof useUnAuthArtistSuggestionsQuery>;
export type UnAuthArtistSuggestionsLazyQueryHookResult = ReturnType<typeof useUnAuthArtistSuggestionsLazyQuery>;
export type UnAuthArtistSuggestionsQueryResult = ApolloReactCommon.QueryResult<UnAuthArtistSuggestionsQuery, UnAuthArtistSuggestionsQueryVariables>;
export const ConnectAlbumReleaseDocument = gql`
    query ConnectAlbumRelease($id: Int!) {
  release(id: $id) {
    id
    date
    title
    artists {
      artistId
      releaseId
      artist {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useConnectAlbumReleaseQuery__
 *
 * To run a query within a React component, call `useConnectAlbumReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectAlbumReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectAlbumReleaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectAlbumReleaseQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ConnectAlbumReleaseQuery, ConnectAlbumReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ConnectAlbumReleaseQuery, ConnectAlbumReleaseQueryVariables>(ConnectAlbumReleaseDocument, options);
      }
export function useConnectAlbumReleaseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConnectAlbumReleaseQuery, ConnectAlbumReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ConnectAlbumReleaseQuery, ConnectAlbumReleaseQueryVariables>(ConnectAlbumReleaseDocument, options);
        }
export type ConnectAlbumReleaseQueryHookResult = ReturnType<typeof useConnectAlbumReleaseQuery>;
export type ConnectAlbumReleaseLazyQueryHookResult = ReturnType<typeof useConnectAlbumReleaseLazyQuery>;
export type ConnectAlbumReleaseQueryResult = ApolloReactCommon.QueryResult<ConnectAlbumReleaseQuery, ConnectAlbumReleaseQueryVariables>;
export const ConnectAlbumToReleaseDocument = gql`
    mutation ConnectAlbumToRelease($releaseId: Int!, $spotifyId: String!) {
  connectAlbumToRelease(releaseId: $releaseId, spotifyId: $spotifyId) {
    id
    status
  }
}
    `;
export type ConnectAlbumToReleaseMutationFn = ApolloReactCommon.MutationFunction<ConnectAlbumToReleaseMutation, ConnectAlbumToReleaseMutationVariables>;

/**
 * __useConnectAlbumToReleaseMutation__
 *
 * To run a mutation, you first call `useConnectAlbumToReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAlbumToReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAlbumToReleaseMutation, { data, loading, error }] = useConnectAlbumToReleaseMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      spotifyId: // value for 'spotifyId'
 *   },
 * });
 */
export function useConnectAlbumToReleaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectAlbumToReleaseMutation, ConnectAlbumToReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ConnectAlbumToReleaseMutation, ConnectAlbumToReleaseMutationVariables>(ConnectAlbumToReleaseDocument, options);
      }
export type ConnectAlbumToReleaseMutationHookResult = ReturnType<typeof useConnectAlbumToReleaseMutation>;
export type ConnectAlbumToReleaseMutationResult = ApolloReactCommon.MutationResult<ConnectAlbumToReleaseMutation>;
export type ConnectAlbumToReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectAlbumToReleaseMutation, ConnectAlbumToReleaseMutationVariables>;
export const SearchSpotifyAlbumDocument = gql`
    query SearchSpotifyAlbum($q: String!, $type: [SpotifySearchType!]!) {
  spotifySearch(q: $q, type: $type) {
    albums {
      items {
        id
        name
        artists {
          name
        }
        images {
          url
        }
        external_urls {
          spotify
        }
      }
    }
  }
}
    `;

/**
 * __useSearchSpotifyAlbumQuery__
 *
 * To run a query within a React component, call `useSearchSpotifyAlbumQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSpotifyAlbumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSpotifyAlbumQuery({
 *   variables: {
 *      q: // value for 'q'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSearchSpotifyAlbumQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchSpotifyAlbumQuery, SearchSpotifyAlbumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchSpotifyAlbumQuery, SearchSpotifyAlbumQueryVariables>(SearchSpotifyAlbumDocument, options);
      }
export function useSearchSpotifyAlbumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSpotifyAlbumQuery, SearchSpotifyAlbumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchSpotifyAlbumQuery, SearchSpotifyAlbumQueryVariables>(SearchSpotifyAlbumDocument, options);
        }
export type SearchSpotifyAlbumQueryHookResult = ReturnType<typeof useSearchSpotifyAlbumQuery>;
export type SearchSpotifyAlbumLazyQueryHookResult = ReturnType<typeof useSearchSpotifyAlbumLazyQuery>;
export type SearchSpotifyAlbumQueryResult = ApolloReactCommon.QueryResult<SearchSpotifyAlbumQuery, SearchSpotifyAlbumQueryVariables>;
export const UserToCollectionDocument = gql`
    mutation UserToCollection($userId: Int!, $releaseId: Int!, $add: Boolean!) {
  userToCollection(userId: $userId, releaseId: $releaseId, add: $add) {
    id
    inCollection
  }
}
    `;
export type UserToCollectionMutationFn = ApolloReactCommon.MutationFunction<UserToCollectionMutation, UserToCollectionMutationVariables>;

/**
 * __useUserToCollectionMutation__
 *
 * To run a mutation, you first call `useUserToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToCollectionMutation, { data, loading, error }] = useUserToCollectionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      releaseId: // value for 'releaseId'
 *      add: // value for 'add'
 *   },
 * });
 */
export function useUserToCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserToCollectionMutation, UserToCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserToCollectionMutation, UserToCollectionMutationVariables>(UserToCollectionDocument, options);
      }
export type UserToCollectionMutationHookResult = ReturnType<typeof useUserToCollectionMutation>;
export type UserToCollectionMutationResult = ApolloReactCommon.MutationResult<UserToCollectionMutation>;
export type UserToCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UserToCollectionMutation, UserToCollectionMutationVariables>;
export const UserToReleaseDocument = gql`
    mutation UserToRelease($userId: Int!, $releaseId: Int!, $supporting: Boolean!) {
  userToRelease(userId: $userId, releaseId: $releaseId, supporting: $supporting) {
    id
    supporting
    supporters {
      userId
      releaseId
      user {
        id
        username
        imageURL
      }
    }
  }
}
    `;
export type UserToReleaseMutationFn = ApolloReactCommon.MutationFunction<UserToReleaseMutation, UserToReleaseMutationVariables>;

/**
 * __useUserToReleaseMutation__
 *
 * To run a mutation, you first call `useUserToReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToReleaseMutation, { data, loading, error }] = useUserToReleaseMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      releaseId: // value for 'releaseId'
 *      supporting: // value for 'supporting'
 *   },
 * });
 */
export function useUserToReleaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserToReleaseMutation, UserToReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserToReleaseMutation, UserToReleaseMutationVariables>(UserToReleaseDocument, options);
      }
export type UserToReleaseMutationHookResult = ReturnType<typeof useUserToReleaseMutation>;
export type UserToReleaseMutationResult = ApolloReactCommon.MutationResult<UserToReleaseMutation>;
export type UserToReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<UserToReleaseMutation, UserToReleaseMutationVariables>;
export const CreateStoryStickerDocument = gql`
    mutation CreateStorySticker($releaseId: Int!) {
  createStorySticker(releaseId: $releaseId)
}
    `;
export type CreateStoryStickerMutationFn = ApolloReactCommon.MutationFunction<CreateStoryStickerMutation, CreateStoryStickerMutationVariables>;

/**
 * __useCreateStoryStickerMutation__
 *
 * To run a mutation, you first call `useCreateStoryStickerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryStickerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryStickerMutation, { data, loading, error }] = useCreateStoryStickerMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useCreateStoryStickerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStoryStickerMutation, CreateStoryStickerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateStoryStickerMutation, CreateStoryStickerMutationVariables>(CreateStoryStickerDocument, options);
      }
export type CreateStoryStickerMutationHookResult = ReturnType<typeof useCreateStoryStickerMutation>;
export type CreateStoryStickerMutationResult = ApolloReactCommon.MutationResult<CreateStoryStickerMutation>;
export type CreateStoryStickerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStoryStickerMutation, CreateStoryStickerMutationVariables>;
export const ReleasePageDocument = gql`
    query ReleasePage($id: Int!) {
  release(id: $id) {
    ...ReleasePageFragment
  }
}
    ${ReleasePageFragmentFragmentDoc}`;

/**
 * __useReleasePageQuery__
 *
 * To run a query within a React component, call `useReleasePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleasePageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReleasePageQuery, ReleasePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReleasePageQuery, ReleasePageQueryVariables>(ReleasePageDocument, options);
      }
export function useReleasePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReleasePageQuery, ReleasePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReleasePageQuery, ReleasePageQueryVariables>(ReleasePageDocument, options);
        }
export type ReleasePageQueryHookResult = ReturnType<typeof useReleasePageQuery>;
export type ReleasePageLazyQueryHookResult = ReturnType<typeof useReleasePageLazyQuery>;
export type ReleasePageQueryResult = ApolloReactCommon.QueryResult<ReleasePageQuery, ReleasePageQueryVariables>;
export const UpdateEstimatedReleaseCostAlertDocument = gql`
    mutation UpdateEstimatedReleaseCostAlert($releaseId: Int!) {
  updateEstimatedReleaseCost(releaseId: $releaseId) {
    id
    campaignStatus
    estimatedCost
    notification {
      date
    }
  }
}
    `;
export type UpdateEstimatedReleaseCostAlertMutationFn = ApolloReactCommon.MutationFunction<UpdateEstimatedReleaseCostAlertMutation, UpdateEstimatedReleaseCostAlertMutationVariables>;

/**
 * __useUpdateEstimatedReleaseCostAlertMutation__
 *
 * To run a mutation, you first call `useUpdateEstimatedReleaseCostAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimatedReleaseCostAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimatedReleaseCostAlertMutation, { data, loading, error }] = useUpdateEstimatedReleaseCostAlertMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useUpdateEstimatedReleaseCostAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEstimatedReleaseCostAlertMutation, UpdateEstimatedReleaseCostAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateEstimatedReleaseCostAlertMutation, UpdateEstimatedReleaseCostAlertMutationVariables>(UpdateEstimatedReleaseCostAlertDocument, options);
      }
export type UpdateEstimatedReleaseCostAlertMutationHookResult = ReturnType<typeof useUpdateEstimatedReleaseCostAlertMutation>;
export type UpdateEstimatedReleaseCostAlertMutationResult = ApolloReactCommon.MutationResult<UpdateEstimatedReleaseCostAlertMutation>;
export type UpdateEstimatedReleaseCostAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEstimatedReleaseCostAlertMutation, UpdateEstimatedReleaseCostAlertMutationVariables>;
export const UserOnWaitListDocument = gql`
    query UserOnWaitList($userId: Int!, $releaseId: Int!) {
  userOnWaitList(userId: $userId, releaseId: $releaseId) {
    userId
    releaseId
    method
    release {
      id
    }
  }
}
    `;

/**
 * __useUserOnWaitListQuery__
 *
 * To run a query within a React component, call `useUserOnWaitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOnWaitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOnWaitListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useUserOnWaitListQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserOnWaitListQuery, UserOnWaitListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserOnWaitListQuery, UserOnWaitListQueryVariables>(UserOnWaitListDocument, options);
      }
export function useUserOnWaitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserOnWaitListQuery, UserOnWaitListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserOnWaitListQuery, UserOnWaitListQueryVariables>(UserOnWaitListDocument, options);
        }
export type UserOnWaitListQueryHookResult = ReturnType<typeof useUserOnWaitListQuery>;
export type UserOnWaitListLazyQueryHookResult = ReturnType<typeof useUserOnWaitListLazyQuery>;
export type UserOnWaitListQueryResult = ApolloReactCommon.QueryResult<UserOnWaitListQuery, UserOnWaitListQueryVariables>;
export const CreateSearchHistoryDocument = gql`
    mutation CreateSearchHistory($artistId: Int!, $userId: Int!) {
  createSearchHistory(artistId: $artistId, userId: $userId) {
    id
    searchHistory {
      id
      imageURL
      name
    }
  }
}
    `;
export type CreateSearchHistoryMutationFn = ApolloReactCommon.MutationFunction<CreateSearchHistoryMutation, CreateSearchHistoryMutationVariables>;

/**
 * __useCreateSearchHistoryMutation__
 *
 * To run a mutation, you first call `useCreateSearchHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchHistoryMutation, { data, loading, error }] = useCreateSearchHistoryMutation({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateSearchHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSearchHistoryMutation, CreateSearchHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSearchHistoryMutation, CreateSearchHistoryMutationVariables>(CreateSearchHistoryDocument, options);
      }
export type CreateSearchHistoryMutationHookResult = ReturnType<typeof useCreateSearchHistoryMutation>;
export type CreateSearchHistoryMutationResult = ApolloReactCommon.MutationResult<CreateSearchHistoryMutation>;
export type CreateSearchHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSearchHistoryMutation, CreateSearchHistoryMutationVariables>;
export const DeleteSearchHistoryDocument = gql`
    mutation DeleteSearchHistory($userId: Int!) {
  deleteSearchHistory(userId: $userId) {
    id
    searchHistory {
      id
      imageURL
      name
    }
  }
}
    `;
export type DeleteSearchHistoryMutationFn = ApolloReactCommon.MutationFunction<DeleteSearchHistoryMutation, DeleteSearchHistoryMutationVariables>;

/**
 * __useDeleteSearchHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteSearchHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchHistoryMutation, { data, loading, error }] = useDeleteSearchHistoryMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteSearchHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSearchHistoryMutation, DeleteSearchHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSearchHistoryMutation, DeleteSearchHistoryMutationVariables>(DeleteSearchHistoryDocument, options);
      }
export type DeleteSearchHistoryMutationHookResult = ReturnType<typeof useDeleteSearchHistoryMutation>;
export type DeleteSearchHistoryMutationResult = ApolloReactCommon.MutationResult<DeleteSearchHistoryMutation>;
export type DeleteSearchHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSearchHistoryMutation, DeleteSearchHistoryMutationVariables>;
export const SearchDocument = gql`
    query Search($q: String!) {
  search(q: $q) {
    id
    name
    imageURL
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const SearchHistoryDocument = gql`
    query SearchHistory($userId: Int!) {
  user(id: $userId) {
    id
    searchHistory {
      id
      imageURL
      name
    }
  }
}
    `;

/**
 * __useSearchHistoryQuery__
 *
 * To run a query within a React component, call `useSearchHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSearchHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchHistoryQuery, SearchHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchHistoryQuery, SearchHistoryQueryVariables>(SearchHistoryDocument, options);
      }
export function useSearchHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchHistoryQuery, SearchHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchHistoryQuery, SearchHistoryQueryVariables>(SearchHistoryDocument, options);
        }
export type SearchHistoryQueryHookResult = ReturnType<typeof useSearchHistoryQuery>;
export type SearchHistoryLazyQueryHookResult = ReturnType<typeof useSearchHistoryLazyQuery>;
export type SearchHistoryQueryResult = ApolloReactCommon.QueryResult<SearchHistoryQuery, SearchHistoryQueryVariables>;
export const UserCollectionDocument = gql`
    query UserCollection($userId: Int!, $first: Int!, $after: String) {
  userCollection(userId: $userId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        title
        imageURL
        artists {
          artistId
          releaseId
          artist {
            id
            name
            imageURL
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserCollectionQuery__
 *
 * To run a query within a React component, call `useUserCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCollectionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserCollectionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserCollectionQuery, UserCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserCollectionQuery, UserCollectionQueryVariables>(UserCollectionDocument, options);
      }
export function useUserCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCollectionQuery, UserCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserCollectionQuery, UserCollectionQueryVariables>(UserCollectionDocument, options);
        }
export type UserCollectionQueryHookResult = ReturnType<typeof useUserCollectionQuery>;
export type UserCollectionLazyQueryHookResult = ReturnType<typeof useUserCollectionLazyQuery>;
export type UserCollectionQueryResult = ApolloReactCommon.QueryResult<UserCollectionQuery, UserCollectionQueryVariables>;
export const UserEncoreDocument = gql`
    query UserEncore($userId: Int!, $first: Int!, $after: String) {
  userEncore(userId: $userId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        title
        imageURL
        date
        status
        estimatedCost
        budget
        creatorId
        campaignStatus
        artists {
          artistId
          releaseId
          artist {
            id
            name
            imageURL
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserEncoreQuery__
 *
 * To run a query within a React component, call `useUserEncoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEncoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEncoreQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserEncoreQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserEncoreQuery, UserEncoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserEncoreQuery, UserEncoreQueryVariables>(UserEncoreDocument, options);
      }
export function useUserEncoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserEncoreQuery, UserEncoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserEncoreQuery, UserEncoreQueryVariables>(UserEncoreDocument, options);
        }
export type UserEncoreQueryHookResult = ReturnType<typeof useUserEncoreQuery>;
export type UserEncoreLazyQueryHookResult = ReturnType<typeof useUserEncoreLazyQuery>;
export type UserEncoreQueryResult = ApolloReactCommon.QueryResult<UserEncoreQuery, UserEncoreQueryVariables>;
export const UserFollowingDocument = gql`
    query UserFollowing($userId: Int!, $first: Int!, $after: String) {
  userFollowing(userId: $userId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        name
        imageURL
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserFollowingQuery__
 *
 * To run a query within a React component, call `useUserFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFollowingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserFollowingQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserFollowingQuery, UserFollowingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserFollowingQuery, UserFollowingQueryVariables>(UserFollowingDocument, options);
      }
export function useUserFollowingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserFollowingQuery, UserFollowingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserFollowingQuery, UserFollowingQueryVariables>(UserFollowingDocument, options);
        }
export type UserFollowingQueryHookResult = ReturnType<typeof useUserFollowingQuery>;
export type UserFollowingLazyQueryHookResult = ReturnType<typeof useUserFollowingLazyQuery>;
export type UserFollowingQueryResult = ApolloReactCommon.QueryResult<UserFollowingQuery, UserFollowingQueryVariables>;
export const UserRumorsDocument = gql`
    query UserRumors($userId: Int!, $first: Int!, $after: String) {
  userRumors(userId: $userId, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        title
        date
        status
        creator {
          id
          username
          imageURL
        }
        artists {
          artistId
          releaseId
          artist {
            id
            name
            imageURL
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserRumorsQuery__
 *
 * To run a query within a React component, call `useUserRumorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRumorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRumorsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserRumorsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserRumorsQuery, UserRumorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserRumorsQuery, UserRumorsQueryVariables>(UserRumorsDocument, options);
      }
export function useUserRumorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRumorsQuery, UserRumorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserRumorsQuery, UserRumorsQueryVariables>(UserRumorsDocument, options);
        }
export type UserRumorsQueryHookResult = ReturnType<typeof useUserRumorsQuery>;
export type UserRumorsLazyQueryHookResult = ReturnType<typeof useUserRumorsLazyQuery>;
export type UserRumorsQueryResult = ApolloReactCommon.QueryResult<UserRumorsQuery, UserRumorsQueryVariables>;
export const AnonUserSettingsDocument = gql`
    query AnonUserSettings($id: Int!) {
  user(id: $id) {
    id
    email
    phone
  }
}
    `;

/**
 * __useAnonUserSettingsQuery__
 *
 * To run a query within a React component, call `useAnonUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonUserSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnonUserSettingsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AnonUserSettingsQuery, AnonUserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AnonUserSettingsQuery, AnonUserSettingsQueryVariables>(AnonUserSettingsDocument, options);
      }
export function useAnonUserSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnonUserSettingsQuery, AnonUserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AnonUserSettingsQuery, AnonUserSettingsQueryVariables>(AnonUserSettingsDocument, options);
        }
export type AnonUserSettingsQueryHookResult = ReturnType<typeof useAnonUserSettingsQuery>;
export type AnonUserSettingsLazyQueryHookResult = ReturnType<typeof useAnonUserSettingsLazyQuery>;
export type AnonUserSettingsQueryResult = ApolloReactCommon.QueryResult<AnonUserSettingsQuery, AnonUserSettingsQueryVariables>;
export const UserToLinkedArtistDocument = gql`
    mutation UserToLinkedArtist($userId: Int!, $artistId: Int!, $add: Boolean!) {
  userToLinkedArtist(userId: $userId, artistId: $artistId, add: $add) {
    id
    linkedAccounts {
      userId
      artistId
    }
  }
}
    `;
export type UserToLinkedArtistMutationFn = ApolloReactCommon.MutationFunction<UserToLinkedArtistMutation, UserToLinkedArtistMutationVariables>;

/**
 * __useUserToLinkedArtistMutation__
 *
 * To run a mutation, you first call `useUserToLinkedArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToLinkedArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToLinkedArtistMutation, { data, loading, error }] = useUserToLinkedArtistMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      artistId: // value for 'artistId'
 *      add: // value for 'add'
 *   },
 * });
 */
export function useUserToLinkedArtistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserToLinkedArtistMutation, UserToLinkedArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserToLinkedArtistMutation, UserToLinkedArtistMutationVariables>(UserToLinkedArtistDocument, options);
      }
export type UserToLinkedArtistMutationHookResult = ReturnType<typeof useUserToLinkedArtistMutation>;
export type UserToLinkedArtistMutationResult = ApolloReactCommon.MutationResult<UserToLinkedArtistMutation>;
export type UserToLinkedArtistMutationOptions = ApolloReactCommon.BaseMutationOptions<UserToLinkedArtistMutation, UserToLinkedArtistMutationVariables>;
export const SubmitArtistVerificationDocument = gql`
    mutation SubmitArtistVerification($userId: Int!, $userEmail: String!, $artistId: Int!, $artistName: String!) {
  submitArtistVerification(
    userId: $userId
    userEmail: $userEmail
    artistId: $artistId
    artistName: $artistName
  ) {
    id
    linkedAccounts {
      userId
      artistId
      status
      artist {
        id
        name
        imageURL
      }
    }
  }
}
    `;
export type SubmitArtistVerificationMutationFn = ApolloReactCommon.MutationFunction<SubmitArtistVerificationMutation, SubmitArtistVerificationMutationVariables>;

/**
 * __useSubmitArtistVerificationMutation__
 *
 * To run a mutation, you first call `useSubmitArtistVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitArtistVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitArtistVerificationMutation, { data, loading, error }] = useSubmitArtistVerificationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userEmail: // value for 'userEmail'
 *      artistId: // value for 'artistId'
 *      artistName: // value for 'artistName'
 *   },
 * });
 */
export function useSubmitArtistVerificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitArtistVerificationMutation, SubmitArtistVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubmitArtistVerificationMutation, SubmitArtistVerificationMutationVariables>(SubmitArtistVerificationDocument, options);
      }
export type SubmitArtistVerificationMutationHookResult = ReturnType<typeof useSubmitArtistVerificationMutation>;
export type SubmitArtistVerificationMutationResult = ApolloReactCommon.MutationResult<SubmitArtistVerificationMutation>;
export type SubmitArtistVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitArtistVerificationMutation, SubmitArtistVerificationMutationVariables>;
export const UpdateAnonUserSettingsDocument = gql`
    mutation UpdateAnonUserSettings($user: UpdateAnonUserSettingsInput!) {
  updateAnonUserSettings(user: $user) {
    id
    phone
    email
  }
}
    `;
export type UpdateAnonUserSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateAnonUserSettingsMutation, UpdateAnonUserSettingsMutationVariables>;

/**
 * __useUpdateAnonUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAnonUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnonUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnonUserSettingsMutation, { data, loading, error }] = useUpdateAnonUserSettingsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateAnonUserSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAnonUserSettingsMutation, UpdateAnonUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAnonUserSettingsMutation, UpdateAnonUserSettingsMutationVariables>(UpdateAnonUserSettingsDocument, options);
      }
export type UpdateAnonUserSettingsMutationHookResult = ReturnType<typeof useUpdateAnonUserSettingsMutation>;
export type UpdateAnonUserSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateAnonUserSettingsMutation>;
export type UpdateAnonUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAnonUserSettingsMutation, UpdateAnonUserSettingsMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($user: UpdateUserSettingsInput!) {
  updateUserSettings(user: $user) {
    id
    uid
    username
    imageURL
    bgColor
    bio
    releaseThreshold
    dailyDigest
    phone
    email
  }
}
    `;
export type UpdateUserSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const UserSettingsDocument = gql`
    query UserSettings($id: Int!) {
  user(id: $id) {
    id
    uid
    email
    username
    imageURL
    bio
    releaseThreshold
    dailyDigest
    type
    phone
    linkedAccounts {
      userId
      artistId
      status
      artist {
        id
        name
        imageURL
      }
    }
  }
}
    `;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSettingsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
      }
export function useUserSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
        }
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsQueryResult = ApolloReactCommon.QueryResult<UserSettingsQuery, UserSettingsQueryVariables>;
export const UserProfileDocument = gql`
    query UserProfile($id: Int!) {
  user(id: $id) {
    id
    username
    imageURL
    bgColor
    bio
    reputation
    type
    wallet
    encoreReleases(first: 3) {
      id
      title
      date
      imageURL
      budget
      estimatedCost
      status
      campaignStatus
      notification {
        id
        date
      }
      artists {
        artistId
        releaseId
        artist {
          id
          name
          imageURL
        }
      }
    }
    supporting(first: 3) {
      id
      title
      status
      creator {
        id
        username
        imageURL
      }
      artists {
        artistId
        releaseId
        artist {
          id
          name
          imageURL
        }
      }
    }
    following(first: 10) {
      id
      imageURL
      name
    }
    collection(first: 10) {
      id
      title
      imageURL
      artists {
        artistId
        releaseId
        artist {
          id
          name
          imageURL
        }
      }
    }
    linkedAccounts {
      userId
      artistId
      status
      artist {
        id
        name
        imageURL
      }
    }
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = ApolloReactCommon.QueryResult<UserProfileQuery, UserProfileQueryVariables>;