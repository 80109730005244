import React, { useRef, useState, useEffect, useCallback } from 'react';
import { CheckoutProviderProps } from './context/CheckoutContext';
import { useForm } from 'react-hook-form';
import braintree from 'braintree-web';

const CheckoutAddCard: React.FC<CheckoutProviderProps> = ({ client, onSubmitPaymentMethod, onGoBack, onGoForward, onSetStatus }) => {
    const cardNumberRef = useRef<HTMLInputElement | null>(null);
    const cardholderNameRef = useRef<HTMLInputElement | null>(null);
    const cvvRef = useRef<HTMLInputElement | null>(null);
    const expirationRef = useRef<HTMLInputElement | null>(null);
    const postalCodeRef = useRef<HTMLInputElement | null>(null);

    const [hostedFields, setHostedFields] = useState<braintree.HostedFields>();
    const [isCardholderNameValid, setIsCardholderNameValid] = useState(true);
    const [isCVVValid, setIsCVVValid] = useState(true);
    const [isExpirationDateValid, setIsExpirationDateValid] = useState(true);
    const [isNumberValid, setIsNumberValid] = useState(true);
    const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);

    const { handleSubmit } = useForm();

    const setValidClass = useCallback((field: 'cardholderName' | 'number' | 'cvv' | 'expirationDate' | 'postalCode' | 'expirationMonth' | 'expirationYear', isValid: boolean) => {
        switch (field) {
            case 'cardholderName':
                setIsCardholderNameValid(isValid);
                break;
            case 'cvv':
                setIsCVVValid(isValid);
                break;
            case 'expirationDate':
                setIsExpirationDateValid(isValid);
                break;
            case 'number':
                setIsNumberValid(isValid);
                break;
            case 'postalCode':
                setIsPostalCodeValid(isValid);
                break;
            default:
                break;
        }
    }, []);

    const onLoadHostedFields = useCallback(async () => {
        if (client && cvvRef.current && cardNumberRef.current && expirationRef.current && cardholderNameRef.current && postalCodeRef.current) {
            try {
                const hostedFields = await braintree.hostedFields
                    .create({
                        client,
                        styles: {
                            input: {
                                padding: '18px 7px',
                                'font-family': 'Roboto, Helvetica, Arial, sans-serif',
                                'font-size': '16px',
                                'font-weight': '400',
                            },
                            '.invalid': {
                                color: '#DC2626',
                            },
                            '::-webkit-input-placeholder': {
                                color: 'rgba(0, 0, 0, 0.5)',
                            },
                            ':-moz-placeholder': {
                                color: 'rgba(0, 0, 0, 0.5)',
                            },
                            '::-moz-placeholder': {
                                color: 'rgba(0, 0, 0, 0.5)',
                            },
                            ':-ms-input-placeholder': {
                                color: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                        fields: {
                            // TODO: Update when added to braintree-web types
                            // @ts-ignore
                            cardholderName: {
                                container: cardholderNameRef.current,
                                placeholder: 'Name as it appears on your card',
                            },
                            number: {
                                container: cardNumberRef.current,
                                placeholder: '4111 1111 1111 1111',
                            },
                            cvv: {
                                container: cvvRef.current,
                                placeholder: '123',
                            },
                            expirationDate: {
                                container: expirationRef.current,
                                placeholder: 'MM/YY',
                            },
                            postalCode: {
                                container: postalCodeRef.current,
                                placeholder: '11111',
                            },
                        },
                    })
                setHostedFields(hostedFields);
            } catch (error) {
                console.log(error)
            }
        }
    }, [client]);

    const onSubmit = useCallback(async () => {
        try {
            if (hostedFields) {
                const tokenizedFields = await hostedFields.tokenize({
                    vault: true
                })
                await onSubmitPaymentMethod(tokenizedFields.nonce)
                onGoBack(true)
            }
        } catch (error) {
            console.log(error)
            onSetStatus({
                success: false,
                message: error.message
            })
            onGoForward("error")
        }
    }, [hostedFields, onSubmitPaymentMethod, onGoBack, onGoForward, onSetStatus])

    const checkoutAnimationEventCallback = useCallback((isMounted: boolean) => {
        if (isMounted) {
            setPageLoaded(true);
        }
    }, [])

    const validityChangeCallback = useCallback((event: braintree.HostedFieldsStateObject) => {
        const field = event.fields[event.emittedBy];

        if (field.isValid) {
            setValidClass(event.emittedBy, true);
        } else if (field.isPotentiallyValid) {
            setValidClass(event.emittedBy, true);
        } else {
            setValidClass(event.emittedBy, false);
        }
    }, [setValidClass])

    useEffect(() => {
        hostedFields?.on('validityChange', validityChangeCallback);

        return () => {
            hostedFields?.off('validityChange', validityChangeCallback);
        }
    }, [hostedFields, validityChangeCallback]);

    useEffect(() => {
        let isMounted = true;

        window.addEventListener('checkoutFormAnimationEnd', () => checkoutAnimationEventCallback(isMounted));

        return () => {
            isMounted = false;
            window.removeEventListener('checkoutFormAnimationEnd', () => checkoutAnimationEventCallback(isMounted));
        };
    }, [checkoutAnimationEventCallback]);

    useEffect(() => {
        if (pageLoaded && client && !hostedFields) {
            (async () => {
                await onLoadHostedFields();
            })()
        }

        return () => {
            (async () => {
                try {
                    await hostedFields?.teardown();
                } catch (error) {
                    console.log(error)
                }
            })()
        }
    }, [pageLoaded, client, onLoadHostedFields, hostedFields])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className="grid grid-cols-12 gap-3 mb-5">
                    <div className="col-span-12">
                        <label htmlFor="cardholder-name" className="inline-block mb-2 text-sm font-bold text-black uppercase">
                            Name On Card
                        </label>
                        <div id="cardholder-name" ref={cardholderNameRef} className={`h-14 hosted-field ${!isCardholderNameValid ? 'hosted-field-invalid' : ''}`}></div>
                    </div>
                    <div className="col-span-9">
                        <label htmlFor="card-number" className="inline-block mb-2 text-sm font-bold text-black uppercase">
                            Card Number
                        </label>
                        <div ref={cardNumberRef} id="card-number" className={`h-14 hosted-field ${!isNumberValid ? 'hosted-field-invalid' : ''}`}></div>
                    </div>
                    <div className="col-span-3">
                        <label htmlFor="cvv" className="inline-block mb-2 text-sm font-bold text-black uppercase">
                            CVV
                        </label>
                        <div ref={cvvRef} id="cvv" className={`h-14 hosted-field ${!isCVVValid ? 'hosted-field-invalid' : ''}`}></div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="expiration" className="inline-block mb-2 text-sm font-bold text-black uppercase">
                            Expiration
                        </label>
                        <div ref={expirationRef} id="expiration" className={`h-14 hosted-field ${!isExpirationDateValid ? 'hosted-field-invalid' : ''}`}></div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="postal-code" className="inline-block mb-2 text-sm font-bold text-black uppercase">
                            Postal Code
                        </label>
                        <div ref={postalCodeRef} id="postal-code" className={`h-14 hosted-field ${!isPostalCodeValid ? 'hosted-field-invalid' : ''}`}></div>
                    </div>
                </div>
                <button className="w-full black-button focus:outline-none" type="submit">
                    Save Card
                </button>
            </form>
        </>
    );
};

export default CheckoutAddCard;
