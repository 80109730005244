import React, { memo, useCallback, useMemo } from 'react';
import { Delete } from '@material-ui/icons';
import { CheckoutProviderProps } from './context/CheckoutContext';
import { notEmpty } from '../../utils/Helpers';
import CreditCardLogo from './CreditCardLogo';
import { BraintreeCreditCard, PaymentMethod } from '../../generated/graphql';

const CheckoutChangeCard: React.FC<CheckoutProviderProps> = memo(({ onGoForward, customerData, onGetBraintreePaymentMethod, onSetBraintreePaymentMethod, onDeleteBraintreePaymentMethod, paymentMethod }) => {

    const onDelete = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, token: string | null | undefined) => {
        e.stopPropagation();
        if (token) {
            await onDeleteBraintreePaymentMethod(token);
        }
    }, [onDeleteBraintreePaymentMethod])

    const braintreePaymentMethod = useMemo(() => {
        return onGetBraintreePaymentMethod();
    }, [onGetBraintreePaymentMethod])

    return (
        <>
            <div className="grid grid-cols-1 gap-3 mb-5">
                {customerData?.user?.braintreeCustomer?.paymentMethods
                    ?.filter(notEmpty)
                    .filter((method) => method.__typename === 'BraintreeCreditCard')
                    .map((method, index) => {
                        const card = method as BraintreeCreditCard;
                        return (
                            <div key={index} className={`flex justify-between w-full p-2 border border-black border-solid rounded cursor-pointer ${paymentMethod === PaymentMethod.Braintree && braintreePaymentMethod?.token === card.token ? '' : 'border-opacity-10'}`} onClick={() => onSetBraintreePaymentMethod(card)}>
                                <div className="flex">
                                    <CreditCardLogo size={'sm'} type={card.cardType} />
                                    <div className="flex flex-col w-full ml-3 text-sm">
                                        <div className="flex items-center font-bold text-black">
                                            <div className="ml-1">*{card.last4}</div>
                                        </div>
                                        <div className="text-black">{card.cardholderName}</div>
                                        <div className="text-black">{card.expirationDate}</div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center pl-4 pr-2 -my-2 text-black border-l border-black border-opacity-10" onClick={(e) => onDelete(e, card.token)}>
                                    <Delete color="inherit" />
                                </div>
                            </div>
                        );
                    })}
            </div>
            <button className="w-full black-button" onClick={() => onGoForward('add_card')}>
                Add Card
            </button>
        </>
    );
});

export default CheckoutChangeCard;
