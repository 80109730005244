import React, { useEffect, useState, useCallback, memo } from 'react';
import { Storage } from '@capacitor/storage';
import { Browser } from '@capacitor/browser';
import { useLocation } from 'react-router';
import { ArtistQuery, ArtistDocument, ReleasePageQuery, ReleasePageDocument } from '../../generated/graphql';
import moment from 'moment-timezone';
import { useApolloClient } from '@apollo/client';

const PREFERS_WEB_BROWSER = 'prefersWebBrowser';
const DEFAULT_LINK_URL = 'https://share.encoreapp.co/home';

const MobileConversionBanner: React.FC = memo(() => {
  const { pathname } = useLocation();

  const [showBanner, setShowBanner] = useState(false);

  const { query } = useApolloClient();

  useEffect(() => {
    const checkShowBanner = async () => {
      const result = await Storage.get({
        key: PREFERS_WEB_BROWSER,
      });

      if (result.value) {
        const browserObject = JSON.parse(result.value);

        if ('value' in browserObject && 'timestamp' in browserObject) {
          const expired = moment().isAfter(moment(browserObject.timestamp).add(1, 'day'), 'day');

          if (expired) {
            await Storage.remove({
              key: PREFERS_WEB_BROWSER,
            });
            setShowBanner(true);
            return;
          }
          setShowBanner(false);
        }
      } else {
        setShowBanner(true);
      }
    };

    checkShowBanner();
  }, []);

  const onHideBanner = useCallback(async () => {
    const now = moment().toISOString();

    await Storage.set({
      key: PREFERS_WEB_BROWSER,
      value: JSON.stringify({
        value: true,
        timestamp: now,
      }),
    });
    setShowBanner(false);
  }, []);

  const onViewInApp = useCallback(async () => {
    if (pathname.includes('artist')) {
      const artistId = pathname.split('/artist/')[1];

      const response = await query<ArtistQuery>({
        query: ArtistDocument,
        variables: {
          id: parseInt(artistId),
        },
      });

      if (response.data.artist?.dynamicLinkEnding) {
        await Browser.open({
          url: `${process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN}/${response.data.artist.dynamicLinkEnding}`,
        });
      } else {
        await Browser.open({
          url: DEFAULT_LINK_URL,
        });
      }

    } else if (pathname.includes('release')) {
      const releaseId = pathname.split('/release/')[1];

      const response = await query<ReleasePageQuery>({
        query: ReleasePageDocument,
        variables: {
          id: parseInt(releaseId),
        },
      });

      if (response.data.release?.dynamicLinkEnding) {
        await Browser.open({
          url: `${process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN}/${response.data.release.dynamicLinkEnding}`,
        });
      } else {
        await Browser.open({
          url: DEFAULT_LINK_URL,
        });
      }
    } else {
      await Browser.open({
        url: DEFAULT_LINK_URL,
      });
    }
  }, [pathname, query]);

  return (
    <>
      {showBanner ? (
        <div
          className="fixed bottom-0 z-10 w-full p-3 text-black bg-purple-600 dark:bg-purple-400"
          style={{
            paddingBottom: 'max(env(safe-area-inset-bottom), 20px)',
          }}
        >
          <div className="mb-2 text-lg font-bold">Encore is better on the App</div>
          <div className="mb-3 text-sm opacity-70">Never miss a Release. Open this in the Encore app to get the full experience.</div>
          <div className="grid grid-cols-1 gap-3 xs:grid-cols-2">
            <button className="primary-outlined-button" onClick={onHideBanner}>
              Not now
            </button>
            <button className={`primary-button`} onClick={onViewInApp}>
              Switch to the app
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
});

export default MobileConversionBanner;
