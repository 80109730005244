import { IonSpinner } from '@ionic/react'
import React from 'react'
import { CheckoutProviderProps } from './context/CheckoutContext';

const CheckoutLoading: React.FC<CheckoutProviderProps> = ({ status }) => {
  return (
    <div className="absolute inset-0 z-10 flex items-center justify-center w-full h-full bg-white">
      <div className="flex flex-col items-center justify-center">
        <IonSpinner color="black" />
        <div className="mt-3 text-xs text-black">{status?.message}</div>
      </div>
    </div>
  )
}


export default CheckoutLoading
