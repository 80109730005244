import React, { createContext, useContext, useCallback, useEffect } from "react"
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2'
import { useInAppPurchaseProductsLazyQuery, useCreateInAppPurchaseTransactionMutation, IapProductTransactionType, Product } from '../../generated/graphql';
import { isPlatform } from '@ionic/react';
import useDeferred from 'use-deferred';
import { useAuth } from '../../firebase/Auth';
import { mapIAPToProduct, PRODUCT_SKUS } from '../../utils/Helpers';

interface InAppPurchaseProviderContext {
    onPurchase: (productSku: string) => Promise<void> | undefined;
    onGetProducts: () => Product[] | undefined;
    onGetProduct: (productId: string) => IAPProduct | undefined;
}

export const InAppPurchaseContext = createContext<InAppPurchaseProviderContext>({
    onPurchase: () => undefined,
    onGetProducts: () => undefined,
    onGetProduct: () => undefined,
})

export const useInAppPurchase = () => useContext(InAppPurchaseContext);

export const InAppPuchaseProvider: React.FC = ({ children }) => {

    const { user } = useAuth();

    const { execute: executePurchase, resolve: resolvePurchase, reject: rejectPurchase } = useDeferred<boolean>();

    const [createTransaction] = useCreateInAppPurchaseTransactionMutation();
    const [registerProducts] = useInAppPurchaseProductsLazyQuery({
        onCompleted({ encoreProducts }) {
            encoreProducts?.forEach((product) => {
                if (product?.sku) {
                    InAppPurchase2.register({
                        id: product?.sku,
                        type: InAppPurchase2.CONSUMABLE
                    })
                    InAppPurchase2.when(product.sku).approved(approvedCallback)
                    InAppPurchase2.when(product.sku).verified(verifiedCallback)
                    InAppPurchase2.when(product.sku).error(errorCallback)
                    InAppPurchase2.when(product.sku).cancelled(cancelledCallback)
                    InAppPurchase2.when(product.sku).finished(finishedCallback)
                }
            })

            // InAppPurchase2.autoFinishTransactions = true;
            InAppPurchase2.refresh();
        }
    });

    const onPurchase = useCallback(async (productSku: string) => {
        try {
            if (user?.id) {
                InAppPurchase2.order(productSku, {
                    userId: user.id
                })
                const isValidTransaction = await executePurchase();
            }
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    }, [executePurchase, user])

    const onRestore = useCallback(() => {
        InAppPurchase2.refresh();
    }, [])

    const onGetProducts = useCallback(() => {
        return Object.values(InAppPurchase2.products.byId).filter((product) => product.type === InAppPurchase2.CONSUMABLE).map((iapProduct) => mapIAPToProduct(iapProduct))
    }, [])

    const onGetProduct = useCallback((productId: string) => {
        const product = InAppPurchase2.get(productId);

        if (product) {
            return product
        }

    }, [])

    const validator = useCallback(async (product: string | IAPProduct, callback: Function) => {

        let status = false;

        if (typeof product !== "string" && product.transaction) {

            const receipt = product.transaction.type === "ios-appstore" ? product.transaction.appStoreReceipt : product.transaction.receipt;

            try {
                const { data } = await createTransaction({
                    variables: {
                        product: {
                            id: product.id,
                            priceMicros: product.priceMicros,
                            receipt,
                            type: isPlatform("ios") ? IapProductTransactionType.Apple : IapProductTransactionType.Google
                        }
                    }
                })

                if (data?.createInAppPurchaseTransaction) {
                    status = data.createInAppPurchaseTransaction
                }

                callback(status, product.transaction)
            } catch (error) {
                console.log(error)
                callback(false, "An error occurred while creating a transaction.")
            }

        } else {
            callback(false, "Product was not returned as an object")
        }

    }, [createTransaction])

    const approvedCallback = useCallback((product: IAPProduct) => {
        console.log("🚀 ~ product approved", product)
        product.verify();
    }, [])

    const verifiedCallback = useCallback((product: IAPProduct) => {
        console.log("🚀 ~ product verified", product)
        product.finish();
    }, [])

    const errorCallback = useCallback((error: any) => {
        console.log("🚀 ~ product error", error)
        rejectPurchase("An error occurred while processing your transaction")
    }, [rejectPurchase])

    const cancelledCallback = useCallback((product: IAPProduct) => {
        console.log('PRODUCT CANCELLED')
        rejectPurchase("Purchase was cancelled before completing the transaction")
    }, [rejectPurchase])

    const finishedCallback = useCallback(async (product: IAPProduct) => {
        console.log('product finished')
        resolvePurchase(true);
    }, [resolvePurchase])

    useEffect(() => {

        if (isPlatform('capacitor')) {

            InAppPurchase2.validator = validator

            // InAppPurchase2.when("co.uncvrd.encore.dev.encoreplus").approved(approvedCallback)

            // InAppPurchase2.when("co.uncvrd.encore.dev.encoreplus").verified(verifiedCallback)

            // InAppPurchase2.when("co.uncvrd.encore.dev.encoreplus").error(errorCallback)

            // InAppPurchase2.when("co.uncvrd.encore.dev.encoreplus").cancelled(cancelledCallback)

            // InAppPurchase2.when("co.uncvrd.encore.dev.encoreplus").finished(finishedCallback)
            registerProducts({
                variables: {
                    productIds: PRODUCT_SKUS
                }
            });
        }

        return () => {
            // InAppPurchase2.off(approvedCallback)
            // InAppPurchase2.off(verifiedCallback)
            // InAppPurchase2.off(errorCallback)
            // InAppPurchase2.off(cancelledCallback)
            // InAppPurchase2.off(finishedCallback)
        }

    }, [registerProducts, onGetProducts, approvedCallback, cancelledCallback, errorCallback, finishedCallback, verifiedCallback, validator])

    return (
        <InAppPurchaseContext.Provider
            value={{
                onPurchase,
                onGetProducts,
                onGetProduct
            }}
        >
            {children}
        </InAppPurchaseContext.Provider>
    )
}