import React, { useCallback } from 'react'
import PayPalButton from '../PayPalButton/PayPalButton'
import { CheckoutProviderProps } from './context/CheckoutContext';

const CheckoutAddPayPalAccount: React.FC<CheckoutProviderProps> = ({ client, onSubmitPaymentMethod, onGoBack }) => {

  const onSubmit = useCallback(async (nonce: string) => {
    await onSubmitPaymentMethod(nonce);
    onGoBack(true)
  }, [onSubmitPaymentMethod, onGoBack])

  return (
    <>
      {client && <PayPalButton client={client} onSubmit={onSubmit} />}
    </>
  )
}

export default CheckoutAddPayPalAccount
