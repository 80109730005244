import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { List, ListItem } from '@material-ui/core';

const RumorListItemSkeleton: React.FC = () => {
    return (
        <div className="desktop-container">
            <List>
                {Array.from({ length: 10 }).map((_, index) => (
                    <ListItem key={index}>
                        <div className="flex items-center justify-start">
                            <div className="relative py-1">
                                <Skeleton width={64} height={64} variant="circle" />
                            </div>
                            <div className="ml-5 text-left">
                                <Skeleton width={160} />
                                <Skeleton width={120} />
                            </div>
                        </div>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default RumorListItemSkeleton;
