import React, { memo, useCallback } from 'react';
import { ReactComponent as ReleaseHubLogo } from '../assets/social-logos/releasehub.svg';

interface Props {
    size: "sm" | "md" | "lg";
}

const FallbackImage: React.FC<Props> = memo(({ size }) => {

    const getSize = useCallback(() => {
        switch (size) {
            case "sm":
                return "w-6 h-6"
            case "md":
                return "w-[52px] h-[52px]"
            case "lg":
                return "w-20 h-20"
            default:
                return "w-6 h-6"
        }
    }, [size])

    return (
        <div className="flex items-center justify-center w-full h-full">
            <ReleaseHubLogo className={`${ getSize()} reactive-logo`} />
        </div>
    );
});

export default FallbackImage;
