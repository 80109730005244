import React, { createContext, useContext, useState, useCallback, useRef } from "react"
import { IonToast, ToastOptions } from '@ionic/react';

const ToastContext = createContext<(options: ToastOptions) => Promise<void>>(Promise.reject);

export const useToast = () => useContext(ToastContext);

export const ToastProvider: React.FC = ({ children }) => {

    const awaitingPromiseRef = useRef<{
        resolve: () => void;
        reject: () => void;
    }>()

    const [options, setOptions] = useState<ToastOptions>();
    const [showToast, setShowToast] = useState<boolean>(false);

    const onInit = useCallback((options: ToastOptions) => {
        setOptions(options)
        setShowToast(true)
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = {
                resolve,
                reject
            }
        })
    }, [])

    const onReset = useCallback(() => {
        setShowToast(false)
    }, [])

    const onClose = useCallback(() => {
        onReset();
        awaitingPromiseRef.current?.resolve();
    }, [onReset])

    return (
        <>
            <ToastContext.Provider
                value={onInit}
                children={children}
            />
            <IonToast
                {...options}
                isOpen={showToast}
                onDidDismiss={onClose}
            />
        </>

    )
}