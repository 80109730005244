import { Delete } from '@material-ui/icons';
import React, { memo, useCallback, useMemo } from 'react';
import { CheckoutProviderProps } from './context/CheckoutContext';
import { notEmpty } from '../../utils/Helpers';
import { ReactComponent as PayPalLogo } from 'payment-icons/min/single/paypal.svg';
import { BraintreePayPalAccount, PaymentMethod } from '../../generated/graphql';

const CheckoutChangePayPal: React.FC<CheckoutProviderProps> = memo(({ onGoForward, customerData, onGetBraintreePaymentMethod, onSetBraintreePaymentMethod, paymentMethod, onDeleteBraintreePaymentMethod }) => {

    const onDelete = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, token: string | null | undefined) => {
        e.stopPropagation();
        if (token) {
            await onDeleteBraintreePaymentMethod(token);
        }
    }, [onDeleteBraintreePaymentMethod])

    const braintreePaymentMethod = useMemo(() => {
        return onGetBraintreePaymentMethod();
    }, [onGetBraintreePaymentMethod])

    return (
        <>
            <div className="grid grid-cols-1 gap-3 mb-5">
                {customerData?.user?.braintreeCustomer?.paymentMethods
                    ?.filter(notEmpty)
                    .filter((method) => method.__typename === 'BraintreePayPalAccount')
                    .map((method, index) => {

                        const account = method as BraintreePayPalAccount;

                        const emailTeaser = account.email?.slice(0, 3);
                        const emailDomain = account.email?.split('@')[1];

                        return (
                            <div key={index} className={`flex justify-between w-full p-2 border cursor-pointer border-black border-solid rounded ${paymentMethod === PaymentMethod.Braintree && braintreePaymentMethod?.token === account.token ? '' : 'border-opacity-10'}`} onClick={() => onSetBraintreePaymentMethod(account)}>
                                <div className={`flex items-center justify-between w-full pr-2`}>
                                    <PayPalLogo className="w-auto h-10" />
                                    <div className="text-sm text-black">{`${emailTeaser}***@${emailDomain}`}</div>
                                </div>
                                <div className="flex items-center justify-center pl-4 pr-2 -my-2 text-black border-l border-black border-opacity-10" onClick={(e) => onDelete(e, account.token)}>
                                    <Delete color="inherit" />
                                </div>
                            </div>
                        );
                    })}
            </div>
            <button className="w-full black-button" onClick={() => onGoForward('add_paypal_account')}>
                Add Account
            </button>
        </>
    );
});

export default CheckoutChangePayPal;
