import React, { useEffect, useState, useContext, createContext, useCallback } from 'react';
import { app, analytics } from './config';
import firebase from 'firebase/app';
import 'firebase/auth';
import { User, RoleName, useUpsertUserAuthMutation } from '../generated/graphql';
import { useLoggedInUserLazyQuery } from '../generated/graphql';
import { isPlatform } from '@ionic/react';
import { Storage } from '@capacitor/storage';
import { PushNotifications } from '@capacitor/push-notifications';
import crypto from 'crypto-random-string';
import { notEmpty, getAccessToken } from '../utils/Helpers';
import { FirebaseAppAuth, SignInOptions } from 'firebase-app-auth'

type AuthProviderContext = {
    loading: boolean;
    user: User | null | undefined;
    createUserWithEmailAndPassword: (email: string, password: string) => Promise<void>;
    loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
    loginWithAuthProvider: (options: SignInOptions) => Promise<void>;
    sendPasswordResetEmail: (email: string) => Promise<void>;
    authError: string | null;
    hasRole: (roles: RoleName[]) => boolean;
    logout: () => Promise<void>;
    logUserEvent: (eventName: string, key: string, value: any) => void;
    firebaseUser: firebase.User | null | undefined;
    existingSignInMethods: string[];
    onSetExistingSignInMethods: (methods: string[]) => void;
    anonymousAuth: () => Promise<void>;
    onRequestNotificationPermissions: () => Promise<void>;
};

export const AuthContext = createContext<AuthProviderContext>({
    loading: true,
    user: null,
    createUserWithEmailAndPassword: (email: string, password: string) => {
        return new Promise((resolve, reject) => { });
    },
    loginWithEmailAndPassword: (email: string, password: string) => {
        return new Promise((resolve, reject) => { });
    },
    loginWithAuthProvider: (options: SignInOptions) => {
        return new Promise((resolve, reject) => { });
    },
    sendPasswordResetEmail: (email: string) => {
        return new Promise((resolve, reject) => { });
    },
    authError: null,
    hasRole: (roles: RoleName[]) => false,
    logout: () => {
        return new Promise((resolve, reject) => { });
    },
    logUserEvent: (eventName: string, key: string, value: any) => {
        return;
    },
    firebaseUser: undefined,
    existingSignInMethods: [],
    onSetExistingSignInMethods: (methods: string[]) => {
        return;
    },
    anonymousAuth: () => Promise.resolve(undefined),
    onRequestNotificationPermissions: () => Promise.resolve()
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{}> = ({ children }) => {

    const [user, setUser] = useState<User | null | undefined>(null);
    const [firebaseClient] = useState<firebase.auth.Auth>(app.auth());
    const [loading, setLoading] = useState(true);
    const [authError, setAuthError] = useState(null);
    const [existingSignInMethods, setExistingSignInMethods] = useState<string[]>([]);
    const [firebaseUser, setFirebaseUser] = useState<firebase.User | null | undefined>();
    const [creatingUser, setCreatingUser] = useState(false);

    const [getLoggedInUser, { client, data: loggedInUserData }] = useLoggedInUserLazyQuery();
    const [upsertUserAuth] = useUpsertUserAuthMutation();

    const upsert = useCallback(
        async (result: firebase.auth.UserCredential | undefined, newAccount: boolean) => {
            const uid = crypto({
                type: 'url-safe',
                length: 4,
            });

            if (result?.user) {
                const { data } = await upsertUserAuth({
                    variables: {
                        email: result.user.email,
                        uid: result.user.uid,
                        username: result.user.email && newAccount ? `${result.user.email?.split('@')[0]}-${uid}` : undefined,
                    },
                });

                return data;
            }
        },
        [upsertUserAuth]
    );

    const resolveOAuthError = useCallback(async (error: any) => {
        try {
            console.log(error)
            if (error.code === "auth/credential-already-in-use") {

                const oldUser = firebaseClient.currentUser;

                try {
                    const retryUserCredential = await firebase.auth().signInWithCredential(error.credential);

                    if (oldUser?.uid && retryUserCredential?.user?.uid) {
                        // await mergeUsers({
                        //     variables: {
                        //         fromUid: oldUser.uid,
                        //         toUid: retryUserCredential.user.uid
                        //     }
                        // })
                    }
                } catch (error) {
                    console.log(error)
                    const errorCode = error.code;

                    if (errorCode === "auth/account-exists-with-different-credential") {
                        // present signinmethodshere
                        const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(error.email);
                        setExistingSignInMethods(signInMethods);
                    }
                }
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }, [firebaseClient])

    const resolveAuthSuccess = useCallback(async (userCredential: firebase.auth.UserCredential) => {
        // force auth token refresh
        await getAccessToken(true)
        if (userCredential.additionalUserInfo?.isNewUser) {
            await upsert(userCredential, true)
        }
    }, [upsert])

    const anonymousAuth = useCallback(async () => {
        setCreatingUser(true)
        setLoading(true)
        try {
            const userCredential = await firebase.auth().signInAnonymously();
            await resolveAuthSuccess(userCredential);
        } catch (error) {
            console.log(error)
        }
        setCreatingUser(false)
    }, [resolveAuthSuccess]);

    const loginWithEmailAndPassword = useCallback(
        async (email: string, password: string) => {
            setExistingSignInMethods([]);
            setLoading(true);

            try {

                const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);

                if (userCredential) {
                    await resolveAuthSuccess(userCredential);
                }

            } catch (error) {
                console.log(error);
                setAuthError(error.message);
                setLoading(false);
                throw new Error(error.message)
            }

        },
        [resolveAuthSuccess]
    );

    const createUserWithEmailAndPassword = useCallback(
        async (email: string, password: string) => {
            setExistingSignInMethods([]);
            setLoading(true);
            try {

                let userCredential: firebase.auth.UserCredential | undefined;
                const currentUser = firebaseClient.currentUser;

                if (currentUser) {
                    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
                    userCredential = await currentUser.linkWithCredential(credential);
                } else {
                    userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
                }

                if (userCredential) {
                    await resolveAuthSuccess(userCredential);
                    await userCredential.user?.sendEmailVerification();
                } else {
                    throw new Error(`User credential not defined when creating a user`)
                }

            } catch (error) {
                console.log(error);
                setAuthError(error.message);
                if (error.code === "auth/email-already-in-use") {
                    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
                    setExistingSignInMethods(signInMethods);
                }
                setLoading(false);
                throw new Error(error.message)
            }
        },
        [resolveAuthSuccess, firebaseClient.currentUser]
    );

    const sendPasswordResetEmail = async (email: string) => {
        try {
            await firebaseClient.sendPasswordResetEmail(email);
        } catch (error) {
            console.log(error);
        }
    };

    const loginWithAuthProvider = useCallback(
        async ({ platform }: SignInOptions) => {
            setExistingSignInMethods([]);
            setLoading(true);

            const currentUser = firebaseClient.currentUser;
            let credential: firebase.auth.OAuthCredential | undefined;
            let userCredential: firebase.auth.UserCredential | undefined;

            try {

                const capacitorResponse = await FirebaseAppAuth.signIn({
                    platform
                })

                if (capacitorResponse) {
                    const { idToken, accessToken } = capacitorResponse;

                    if (idToken) {
                        switch (platform) {
                            case "apple":
                                const provider = new firebase.auth.OAuthProvider('apple.com');
                                credential = provider.credential(idToken, accessToken);
                                break;
                            default:
                                credential = firebase.auth.GoogleAuthProvider.credential(idToken, accessToken);
                                break;
                        }

                        try {
                            if (currentUser) {
                                userCredential = await currentUser.linkWithCredential(credential)
                            } else {
                                userCredential = await firebase.auth().signInWithCredential(credential);
                            }

                            if (userCredential) {
                                await resolveAuthSuccess(userCredential);
                            } else {
                                throw new Error(`User credential not defined for mobile auth flow`)
                            }

                        } catch (error) {
                            await resolveOAuthError(error)
                        }
                    } else {
                        throw new Error(`idToken not defined in capacitor authentication`)
                    }
                }

            } catch (error) {
                console.log(error)
                setLoading(false);
                throw new Error(error.message)
            }
        },
        [resolveOAuthError, resolveAuthSuccess, firebaseClient.currentUser]
    );

    const logout = useCallback(async () => {
        setLoading(true);
        setUser(undefined)
        setFirebaseUser(null)
        await client?.clearStore();
        await Storage.remove({
            key: 'accessToken',
        });
        await firebaseClient.signOut();
        setLoading(false);
    }, [client, firebaseClient]);

    const hasRole = useCallback(
        (roles: RoleName[]) => {
            if (user && user.roles) {
                const userRoles = user.roles.filter(notEmpty).map((role) => role.name);
                return roles.some((role) => userRoles.includes(role));
            } else {
                return false;
            }
        },
        [user]
    );

    const logUserEvent = useCallback(
        (eventName: string, key: string, value: any) => {
            if (user && user.id) {
                analytics.logEvent(eventName, {
                    [key]: value,
                });
            }
        },
        [user]
    );

    const onSetExistingSignInMethods = useCallback((methods: string[]) => {
        setExistingSignInMethods(methods);
    }, []);

    // Register with Apple / Google to receive push via APNS/FCM
    const onRequestNotificationPermissions = useCallback(async () => {
        if (isPlatform('capacitor')) {
            try {
                const status = await PushNotifications.requestPermissions();
                if (status.receive) {
                    await PushNotifications.register();
                    console.log('notifications granted');
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const userCredential = await firebase.auth().getRedirectResult();
                await resolveAuthSuccess(userCredential);
            } catch (error) {
                await resolveOAuthError(error)
            }
        })()
    }, [resolveOAuthError, resolveAuthSuccess])

    useEffect(() => {
        firebaseClient.onAuthStateChanged(async (fbUser) => {

            // if user not defined, no additional loading is needed
            if (!fbUser) {
                setLoading(false);
            }
            setFirebaseUser(fbUser);
        });
    }, [firebaseClient]);

    useEffect(() => {
        if (firebaseUser?.uid && !creatingUser) {
            getLoggedInUser({
                variables: {
                    uid: firebaseUser.uid,
                },
            });
        }
    }, [firebaseUser, getLoggedInUser, creatingUser])

    useEffect(() => {
        if (loggedInUserData?.loggedInUser) {
            setUser(loggedInUserData.loggedInUser);

            if (loggedInUserData.loggedInUser.id) {
                const id = loggedInUserData.loggedInUser?.id.toString();
                analytics.setUserId(id);
            }
            setAuthError(null);
            setLoading(false);
        } else {
            setUser(null);
        }
    }, [loggedInUserData])

    return (
        <AuthContext.Provider
            value={{
                user,
                loading,
                createUserWithEmailAndPassword,
                loginWithEmailAndPassword,
                loginWithAuthProvider,
                sendPasswordResetEmail,
                authError,
                hasRole,
                logout,
                logUserEvent,
                firebaseUser,
                existingSignInMethods,
                onSetExistingSignInMethods,
                anonymousAuth,
                onRequestNotificationPermissions
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
