import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import {
    useBraintreeCustomerLazyQuery,
    useCreateBraintreePaymentMethodMutation,
    BraintreePaymentMethod,
    useCreateBraintreeCustomerMutation,
    useUpdateBraintreeDefaultPaymentMethodMutation,
    useDeleteBraintreePaymentMethodMutation,
    useCreateBraintreeTransactionMutation,
    useCheckoutProductLazyQuery,
    useCreateBraintreeClientTokenMutation,
    Product,
    PaymentMethod,
    useCreateWalletTransactionMutation,
    BraintreeCustomerQuery
} from '../../../generated/graphql';
import { useAuth } from '../../../firebase/Auth';
import braintree from 'braintree-web';
import { lastNonNullItem, mapIAPToProduct, sleep } from '../../../utils/Helpers';
import { IonLoading, isPlatform } from '@ionic/react';
import Checkout from '../Checkout';
import { useInAppPurchase } from '../../../context/in-app-purchase/InAppPurchaseContext';

export type CheckoutComponent = 'overview' | 'payment_method' | 'total' | 'change_card' | 'add_card' | "change_paypal" | "add_paypal_account" | "receipt" | "error";

export interface CheckoutStatus {
    success: boolean | null | undefined;
    message: string | null | undefined;
}

interface CheckoutOptions {
    productSku: string;
    paymentMethods: Set<PaymentMethod>
}


interface CheckoutResponse {
    paymentMethod: PaymentMethod | null | undefined;
    product: Product | undefined;
}

export interface CheckoutProviderProps {
    customerData: BraintreeCustomerQuery | undefined;
    paymentMethod: PaymentMethod | null | undefined;
    onSetPaymentMethod: (method: PaymentMethod) => void;
    onGetBraintreePaymentMethod: () => BraintreePaymentMethod | null | undefined;
    onSetBraintreePaymentMethod: (method: BraintreePaymentMethod) => void;
    onSubmitPaymentMethod: (nonce: string) => Promise<void>;
    client: braintree.Client | undefined;
    router: CheckoutComponent[];
    currentRoute: CheckoutComponent;
    onGoForward: (component: CheckoutComponent) => void;
    onGoBack: (reset?: boolean) => void;
    onUpdateDefaultPaymentMethod: (token: string) => Promise<void>;
    onDeleteBraintreePaymentMethod: (token: string) => Promise<void>;
    loading: boolean;
    deviceData: string | null | undefined;
    onPurchase: () => Promise<void>;
    status: CheckoutStatus | null;
    onSetStatus: (status: CheckoutStatus) => void;
    product: Product | undefined;
    onReset: () => void;
    onSuccess: (response: CheckoutResponse) => void;
    showCheckout: boolean;
    onClose: () => void;
    options: CheckoutOptions | undefined;
}

const CheckoutContext = createContext<(options: CheckoutOptions) => Promise<CheckoutResponse>>(Promise.reject);

export const useCheckout = () => useContext(CheckoutContext);

export const CheckoutProvider: React.FC = ({ children }) => {

    const awaitingPromiseRef = useRef<{
        resolve: (response: CheckoutResponse) => void;
        reject: (reason?: any) => void;
    }>()

    const { user } = useAuth();
    const { onPurchase: onInAppPurchase, onGetProduct: onGetInAppPurchaseProduct } = useInAppPurchase()

    const [loading, setLoading] = useState(false);
    const [router, setRouter] = useState<CheckoutComponent[]>(["overview"])
    const [currentRoute, setCurrentRoute] = useState<CheckoutComponent>("overview")
    const [client, setClient] = useState<braintree.Client>();
    const [deviceData, setDeviceData] = useState<string>();
    const [status, setStatus] = useState<CheckoutStatus | null>(null);
    // const [customer, setCustomer] = useState<BraintreeCustomer | null | undefined>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null | undefined>();
    const [product, setProduct] = useState<Product>();
    const [showCheckout, setShowCheckout] = useState(false);
    const [options, setOptions] = useState<CheckoutOptions>();

    const [createToken] = useCreateBraintreeClientTokenMutation();
    const [createCustomer] = useCreateBraintreeCustomerMutation();
    const [createPaymentMethod] = useCreateBraintreePaymentMethodMutation();
    const [updateDefaultPaymentMethod] = useUpdateBraintreeDefaultPaymentMethodMutation();
    const [deletePaymentMethod] = useDeleteBraintreePaymentMethodMutation();
    const [createBraintreeTransaction] = useCreateBraintreeTransactionMutation();
    const [createWalletTransaction] = useCreateWalletTransactionMutation();
    const [getBraintreeCustomer, { data: customerData }] = useBraintreeCustomerLazyQuery({
        fetchPolicy: 'network-only',
        async onCompleted({ user: response }) {
            if (response?.braintreeCustomer) {
                // setCustomer(response.braintreeCustomer);
                setPaymentMethod(PaymentMethod.Braintree);
            } else {
                if (user?.email && user.id) {
                    await createCustomer({
                        variables: {
                            user: {
                                email: user.email,
                                userId: user.id,
                            }
                        },
                    });
                }
            }
        }
    });
    const [getWebProduct] = useCheckoutProductLazyQuery({
        onCompleted({ product }) {
            if (!!product) {
                setProduct(product)
            } else {
                setProduct(undefined)
            }
        }
    })

    const onInit = useCallback((options: CheckoutOptions) => {
        setOptions(options)
        setShowCheckout(true)
        if (user?.id && !isPlatform('capacitor')) {
            getBraintreeCustomer({
                variables: {
                    userId: user.id,
                },
            });
        }
        return new Promise<CheckoutResponse>((resolve, reject) => {
            awaitingPromiseRef.current = {
                resolve,
                reject
            }
        })
    }, [getBraintreeCustomer, user?.id])

    const onReset = useCallback(async () => {
        setLoading(false)
        setStatus(null)
        await sleep(500);
        setRouter(["overview"])
    }, [])

    const onSuccess = useCallback((response: CheckoutResponse) => {
        onReset();
        setShowCheckout(false)
        awaitingPromiseRef.current?.resolve(response);
    }, [onReset])

    const onClose = useCallback(() => {
        onReset();
        setShowCheckout(false)
        awaitingPromiseRef.current?.reject();
    }, [onReset])

    const onGoForward = useCallback((component: CheckoutComponent) => {
        setRouter((prev) => {
            const index = prev.findIndex((a) => a === component);
            if (index === -1) {
                return [...prev, component]
            } else {
                return [...prev]
            }
        })
    }, []);

    const onGoBack = useCallback((reset?: boolean) => {
        setRouter((prev) => {
            if (reset) {
                return ["overview"]
            }
            prev.pop();
            return [...prev];
        })
    }, [])

    const onSetPaymentMethod = useCallback((method: PaymentMethod) => {
        setPaymentMethod(method);
    }, []);

    const onSetBraintreePaymentMethod = useCallback((method: BraintreePaymentMethod) => {
        setPaymentMethod(PaymentMethod.Braintree)
    }, []);

    const onSubmitPaymentMethod = useCallback(
        async (nonce: string) => {
            setLoading(true)

            // add payment method to users' vault
            if (customerData && nonce && user?.id) {
                await createPaymentMethod({
                    variables: {
                        paymentMethodNonce: nonce,
                        userId: user.id,
                    }
                });
            }
            setLoading(false)
        },
        [user, createPaymentMethod, customerData]
    );

    const onUpdateDefaultPaymentMethod = useCallback(async (token: string) => {
        setLoading(true);
        if (user?.id) {
            await updateDefaultPaymentMethod({
                variables: {
                    token,
                    userId: user.id
                }
            })
        }
        setLoading(false);
    }, [updateDefaultPaymentMethod, user])

    const onDeleteBraintreePaymentMethod = useCallback(async (token: string) => {
        setLoading(true);
        if (user?.id) {
            await deletePaymentMethod({
                variables: {
                    token,
                    userId: user.id
                }
            })
        }
        setLoading(false);
    }, [deletePaymentMethod, user?.id])

    const onGetBraintreePaymentMethod = useCallback(() => {
        return customerData?.user?.braintreeCustomer?.paymentMethods?.find((paymentMethod) => paymentMethod?.default === true)
    }, [customerData])

    const onPurchase = useCallback(async () => {

        if (user?.id && options?.productSku) {

            setLoading(true);
            setStatus({
                success: null,
                message: "Processing payment"
            })
            try {
                switch (paymentMethod) {
                    case PaymentMethod.Wallet:
                        await createWalletTransaction({
                            variables: {
                                productSku: options.productSku
                            }
                        })
                        break;
                    case PaymentMethod.InAppPurchase:
                        // hide the checkout window while performing native purchase
                        setShowCheckout(false)
                        await sleep(250);
                        try {
                            await onInAppPurchase(options.productSku);
                            await sleep(250);
                            setShowCheckout(true)
                        } catch (error) {
                            await sleep(250);
                            setShowCheckout(true)
                            throw new Error(error.message)
                        }
                        break;
                    default:
                        const braintreePaymentMethod = onGetBraintreePaymentMethod();
                        if (braintreePaymentMethod?.token && deviceData) {
                            await createBraintreeTransaction({
                                variables: {
                                    input: {
                                        sku: options.productSku,
                                        deviceData,
                                        token: braintreePaymentMethod.token,
                                        userId: user.id
                                    }
                                }
                            })
                        } else {
                            throw new Error('Invalid payment method')
                        }
                }

                /**
                 * Check success and progress
                 */
                setStatus((prev) => ({
                    success: true,
                    message: "Success!"
                }))
                onGoForward("receipt");
            } catch (error) {
                console.log(error)
                setStatus({
                    success: false,
                    message: error.message
                })
                onGoForward("error");
            }
            setLoading(false);
        }

    }, [user, onGoForward, options, onGetBraintreePaymentMethod, createBraintreeTransaction, onInAppPurchase, deviceData, paymentMethod, createWalletTransaction])

    const onSetStatus = useCallback((status: CheckoutStatus) => {
        setStatus({
            ...status
        })
    }, [])

    useEffect(() => {
        if (!!customerData?.user?.braintreeCustomer) {
            (async () => {
                const { data } = await createToken({
                    variables: {
                        userId: customerData?.user?.braintreeCustomer?.id ? Number(customerData?.user?.braintreeCustomer.id) : undefined,
                    },
                });
                if (data && data.createBraintreeClientToken) {
                    const token = data.createBraintreeClientToken;
                    const client = await braintree.client.create({
                        authorization: token,
                    });
                    setClient(client);

                    const { deviceData } = await braintree.dataCollector.create({
                        client
                    })
                    setDeviceData(deviceData)
                }
            })();
        }
    }, [createToken, customerData]);

    useEffect(() => {
        setCurrentRoute(lastNonNullItem(router) ?? "overview")
    }, [router])

    useEffect(() => {
        if (options?.productSku) {

            if (isPlatform('capacitor')) {
                const iapProduct = onGetInAppPurchaseProduct(options.productSku);
                if (iapProduct) {
                    setProduct(mapIAPToProduct(iapProduct))
                } else {
                    setProduct(undefined)
                }
            } else {
                getWebProduct({
                    variables: {
                        sku: options.productSku
                    },
                })
            }
        }
    }, [options?.productSku, getWebProduct, onGetInAppPurchaseProduct])

    useEffect(() => {
        if (isPlatform('capacitor')) {
            setPaymentMethod(PaymentMethod.InAppPurchase)
        } else {
            setPaymentMethod(PaymentMethod.Braintree)
        }
    }, [])

    const checkoutProps: CheckoutProviderProps = {
        customerData,
        paymentMethod,
        onSetPaymentMethod,
        onGetBraintreePaymentMethod,
        onSetBraintreePaymentMethod,
        onSubmitPaymentMethod,
        client,
        router,
        currentRoute,
        onGoForward,
        onGoBack,
        onUpdateDefaultPaymentMethod,
        onDeleteBraintreePaymentMethod,
        loading,
        deviceData,
        onPurchase,
        status,
        onSetStatus,
        product,
        onReset,
        onSuccess,
        showCheckout,
        onClose,
        options
    }

    return (
        <>
            <CheckoutContext.Provider
                value={onInit}
                children={children}
            />
            <Checkout
                {...checkoutProps}
            />
            <IonLoading isOpen={loading} message={'Loading...'} />
        </>
    );
};
