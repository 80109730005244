import React from 'react'
import { CheckoutProviderProps } from './context/CheckoutContext';

const CheckoutError: React.FC<CheckoutProviderProps> = ({ status, onReset }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center mb-6">
        {/* <div className="mb-2 text-lg font-bold text-black">Payment Error</div> */}
        <div className="text-sm text-red-600">{status?.message ?? "An error has occurred while processing your payment"}</div>
      </div>
      <button className="w-full black-button focus:outline-none" onClick={onReset}>
        Start Over
      </button>
    </>
  )
}

export default CheckoutError
