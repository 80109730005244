import React from 'react'
import { useAuth } from '../firebase/Auth';
import { Route, RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { RoleName } from '../generated/graphql';
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import RumorListItemSkeleton from '../components/RumorListitem/RumorListItemSkeleton';
import ProfileHeaderSkeleton from '../components/ProfileHeader/ProfileHeaderSkeleton';

interface PrivateRouteProps extends RouteProps {
    component: any;
    roles?: RoleName[]
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, roles, ...rest }) => {

    const { user, loading } = useAuth();

    if (loading) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar></IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding-vertical">
                    <ProfileHeaderSkeleton />
                    <RumorListItemSkeleton />
                </IonContent>
            </IonPage>
        )
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                user ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={`/auth/login`} />
                )
            }
        />
    )
}

export default PrivateRoute;