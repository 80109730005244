import React from 'react';
import ReactDOM from 'react-dom';
// import "./tailwind.generated.css";
import App from './App';
import { AuthProvider } from './firebase/Auth';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import cubejs from '@cubejs-client/core'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache, ApolloLink, Observable } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper/ServiceWorkerWrapper';
import { onError } from '@apollo/client/link/error';
import { Storage } from '@capacitor/storage';
import firebase from 'firebase/app';
import 'firebase/auth';
import { TabProvider } from './context/TabContext';
import { CubeProvider } from '@cubejs-client/react';
import { InAppPuchaseProvider } from './context/in-app-purchase/InAppPurchaseContext';
import { getAccessToken } from './utils/Helpers';
import { ToastProvider } from './context/toast/ToastContext';

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_ENCORE_API}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists

    const _token = await getAccessToken()

    // return the headers to the context so httpLink can read them
    if (_token) {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${_token}`
            }
        }
    } else {
        return {
            headers: {
                ...headers
            },
        };
    }

});

const getNewAccessToken = (): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = await firebase.app().auth().currentUser?.getIdToken(true);
            console.log("🚀 ~ file: index.tsx ~ line 56 ~ returnnewPromise ~ token", token)

            if (token) {
                await Storage.set({
                    key: 'accessToken',
                    value: token,
                });
                resolve(token);
            }
        } catch (error) {
            reject(error);
            console.log(error);
        }

        reject('error: probably no refreshToken in Storage.');
    });
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    console.log('graphQLErrors', graphQLErrors);
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err?.extensions?.code) {
                case 'UNAUTHENTICATED':
                    // error code is set to UNAUTHENTICATED
                    // when AuthenticationError thrown in resolver

                    // modify the operation context with a new token
                    return new Observable((observer) => {
                        getNewAccessToken()
                            .then((response) => {
                                const oldHeaders = operation.getContext().headers;
                                operation.setContext({
                                    headers: {
                                        ...oldHeaders,
                                        authorization: `Bearer ${response}`,
                                    },
                                });
                            })
                            .then(() => {
                                const subscriber = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer),
                                };

                                forward(operation).subscribe(subscriber);
                            })
                            .catch((error) => {
                                observer.error(error);
                            });
                    });
                // retry the request, returning the new observable
                // return forward(operation);
            }
        }
    }
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
    }
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    userRumorFeed: relayStylePagination(),
                    userReleaseFeed: relayStylePagination(),
                    userRumors: relayStylePagination(),
                    userFollowing: relayStylePagination(),
                    userCollection: relayStylePagination(),
                    artistRumors: relayStylePagination(),
                    artistReleases: relayStylePagination()
                },
            },
            UserReleaseFeed: {
                keyFields: ['userId', 'releaseId'],
            },
            LinkedAccount: {
                keyFields: ['userId', 'artistId'],
            },
            ArtistRelease: {
                keyFields: ['releaseId', 'artistId'],
            },
            UserCollection: {
                keyFields: ['userId', 'releaseId'],
            },
            UserArtist: {
                keyFields: ['userId', 'artistId'],
            },
            UserSupport: {
                keyFields: ['userId', 'releaseId'],
            },
            BraintreeCreditCard: {
                keyFields: ['token'],
            },
            BraintreePayPalAccount: {
                keyFields: ['token'],
            },
            BraintreeApplePayCard: {
                keyFields: ['token'],
            },
            WaitList: {
                keyFields: ['userId', 'releaseId', 'method']
            },
            ArtistSuggestion: {
                keyFields: ['userId', 'artistId']
            },
            ArtistSearchHistory: {
                keyFields: ['userId', 'artistId']
            },
            TextPricing: {
                keyFields: ['country']
            },
            ReleaseAddOn: {
                keyFields: ['addOnId', 'releaseId']
            },
            ProductTransaction: {
                keyFields: ['productId', 'transactionId']
            },

        },
    }),
    link: ApolloLink.from([authLink, errorLink, httpLink]),
});

export const cubejsClient = cubejs(async () => {
    return await getAccessToken();
}, {
    apiUrl: `${process.env.REACT_APP_ANALYTICS_API_URL}`
})

ReactDOM.render(
    <LocalizationProvider dateAdapter={MomentUtils}>
        <ApolloProvider client={client}>
            <CubeProvider cubejsApi={cubejsClient}>
                <TabProvider>
                    <AuthProvider>
                        <InAppPuchaseProvider>
                            <ToastProvider>
                                <App />
                                <ServiceWorkerWrapper />
                            </ToastProvider>
                        </InAppPuchaseProvider>
                    </AuthProvider>
                </TabProvider>
            </CubeProvider>
        </ApolloProvider>
    </LocalizationProvider>,
    document.getElementById('root')
);

defineCustomElements(window);
