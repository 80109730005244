import React, { useEffect, useState, memo } from 'react';
import { PushNotifications, PushNotificationSchema, ActionPerformed, Token } from '@capacitor/push-notifications'
import { Device, DeviceInfo } from '@capacitor/device'
import { useAuth } from '../../firebase/Auth';
import { notEmpty } from '../../utils/Helpers';
import { useHistory } from 'react-router-dom';
import { PluginListenerHandle } from '@capacitor/core';

interface Props {
    onUpsertToken: (userId: number, deviceId: string, token: string) => Promise<void>;
}

const NotificationListener: React.FC<Props> = memo(({ onUpsertToken }) => {

    const history = useHistory();

    const { user } = useAuth();

    const [fcmToken, setFcmToken] = useState<string | undefined>();
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | undefined>();

    useEffect(() => {

        let registrationListener: PluginListenerHandle | undefined;
        let registrationErrorListener: PluginListenerHandle | undefined;
        let notificationReceivedListener: PluginListenerHandle | undefined;
        let actionPerformedListener: PluginListenerHandle | undefined;

        (async () => {

            registrationListener = await PushNotifications.addListener('registration', async (token: Token) => {
                console.log('Push registration success, token: ' + token.value);

                setFcmToken(token.value)
            });

            registrationErrorListener = await PushNotifications.addListener('registrationError', (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            });

            notificationReceivedListener = await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                console.log('Push received: ' + JSON.stringify(notification));
            });

            actionPerformedListener = await PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
                console.log('Push action performed: ' + JSON.stringify(notification));

                const data = notification.notification.data;

                let state = {};
                if ('state' in data) {
                    state = JSON.parse(data.state)
                }
                if ('deepLink' in data) {
                    history.push(data.deepLink, state)
                }
            });
        })()

        return () => {
            registrationListener?.remove();
            registrationErrorListener?.remove();
            notificationReceivedListener?.remove();
            actionPerformedListener?.remove();
        };
    }, [history]);

    useEffect(() => {
        (async () => {
            const info = await Device.getInfo();
            setDeviceInfo(info);
        })()
    }, []);

    useEffect(() => {
        if (user?.id && user.fcmTokens && fcmToken && deviceInfo?.uuid) {

            const existingToken = user.fcmTokens.filter(notEmpty).find((userToken) => {
                return userToken.deviceId === deviceInfo.uuid && userToken.token === fcmToken
            })

            if (!existingToken) {
                (async () => {
                    if (user.id) {
                        console.log("Upserting new FCM Token", fcmToken);
                        await onUpsertToken(user.id, deviceInfo.uuid, fcmToken);
                    }
                })()
            }

        }
    }, [fcmToken, user, deviceInfo, onUpsertToken]);

    return null;
});

export default NotificationListener;
