import '@capacitor/core';
import React, { useEffect, useState, useCallback, useRef, lazy, Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonTabs, setupConfig, IonModal, IonHeader, IonToolbar, IonPage } from "@ionic/react";
import { addCircle, search, home, albums, person } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
// import '@ionic/react/css/typography.css';

// /* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/custom.css";
import './index.css';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import PrivateRoute from "./utils/PrivateRoute";
import { useAuth } from "./firebase/Auth";
import { isPlatform } from "@ionic/react";
import { SplashScreen } from '@capacitor/splash-screen';
import { IonReactRouter } from "@ionic/react-router";
import DynamicLinkListener from "./components/DynamicLinkListener/DynamicLinkListener";
import { AccountType, RoleName } from "./generated/graphql";
import WebSplashScreen from "./components/SplashScreen";
import LocalNotificationListener from "./components/LocalNotificationListener";
import NotificationListener from "./components/NotificationListener";
import WebConversionBanner from "./components/MobileConversionBanner";
import { useMediaQuery } from "@material-ui/core";
import { useToast } from './context/toast/ToastContext';
import { CheckoutProvider } from './components/Checkout/context/CheckoutContext';
import ExistingSignInMethods from './components/ExistingSignInMethods/ExistingSignInMethods';

setupConfig({
    animated: !isPlatform("desktop"),
    swipeBackEnabled: isPlatform("capacitor"),
});

const SignUp = lazy(() => import('./pages/Auth/SignUp'));
const Login = lazy(() => import('./pages/Auth/LogIn'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const Home = lazy(() => import('./pages/Home'));
const User = lazy(() => import('./pages/User'));
const Search = lazy(() => import('./pages/Search'));
const Artist = lazy(() => import('./pages/Artist'));
const UserSettings = lazy(() => import('./pages/User/UserSettings'));
const ArtistRumors = lazy(() => import('./pages/Artist/ArtistRumors'));
const ArtistReleases = lazy(() => import('./pages/Artist/ArtistReleases'));
const UserCollection = lazy(() => import('./pages/User/UserCollection'));
const UserFollowing = lazy(() => import('./pages/User/UserFollowing'));
const UserRumors = lazy(() => import('./pages/User/UserRumors'));
const Release = lazy(() => import('./pages/Release'));
const AddArtist = lazy(() => import('./pages/Create/AddArtist'));
const ConnectAlbum = lazy(() => import('./pages/Release/ConnectAlbum'));
const CreateForm = lazy(() => import('./pages/Create/CreateForm'));
const UpcomingFeed = lazy(() => import('./pages/Feed/UpcomingFeed'));
const ReleasesFeed = lazy(() => import('./pages/Feed/ReleasesFeed'));
const Analytics = lazy(() => import('./pages/Analytics'));
const UserEncore = lazy(() => import('./pages/User/UserEncore/UserEncore'));

const App: React.FC = () => {
    const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);
    const createModalRef = useRef<HTMLIonModalElement | null>(null);
    const artistModalRef = useRef<HTMLIonModalElement | null>(null);

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const { firebaseUser, user, loading: authLoading } = useAuth();
    const showToast = useToast();

    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showAddArtist, setShowAddArtist] = useState(false);
    const [editReleaseId, setEditReleaseId] = useState<number | undefined>();
    const [createFormArtistId, setCreateFormArtistId] = useState<number | undefined>();

    const onShowCreateForm = useCallback(
        async (show: boolean) => {
            if (user) {
                if (!show) {
                    setEditReleaseId(undefined);
                    setCreateFormArtistId(undefined);
                    await createModalRef.current?.dismiss();
                } else {
                    await createModalRef.current?.present();
                }
                setShowCreateForm(show);
            } else {
                await showToast({
                    message: "Log in to submit rumors!",
                    duration: 3000,
                    color: "dark",
                    position: "top"
                })
            }

        },
        [showToast, user]
    );

    const onShowAddArtist = useCallback(async (show: boolean) => {
        if (!show) {
            await artistModalRef.current?.dismiss();
        } else {
            await artistModalRef.current?.present();
        }
        setShowAddArtist(show);
    }, []);

    const onTabsWillChange = useCallback(
        async (event: CustomEvent<{ tab: string }>) => {
            if (event.detail.tab === "create") {
                await onShowCreateForm(true);
            }
        },
        [onShowCreateForm]
    );


    const onShowCreateFormEventCallback = useCallback(async (e: Event) => {
        const event = e as CustomEvent<{
            releaseId: number | undefined;
            artistId: number | null
        }>;

        if (event.detail?.artistId) {
            setCreateFormArtistId(event.detail.artistId);
        }

        if (event.detail?.releaseId) {
            setEditReleaseId(event.detail.releaseId);
        }

        await onShowCreateForm(true);
    }, [onShowCreateForm])

    const onShowAddArtistEventCallback = useCallback(async (e: Event) => {
        await onShowAddArtist(true);
    }, [onShowAddArtist])

    useEffect(() => {
        const hideSplashScreen = async () => {
            setShowSplashScreen(false);
            await SplashScreen.hide();
        };

        if (!authLoading && firebaseUser !== undefined) {
            hideSplashScreen();
        }
    }, [firebaseUser, user, authLoading]);

    useEffect(() => {
        window.addEventListener("onShowCreateForm", onShowCreateFormEventCallback);

        return () => {
            window.removeEventListener("onShowCreateForm", onShowCreateFormEventCallback);
        };
    }, [onShowCreateFormEventCallback]);

    useEffect(() => {
        window.addEventListener("onShowAddArtist", onShowAddArtistEventCallback);

        return () => {
            window.removeEventListener("onShowAddArtist", onShowAddArtistEventCallback);
        };
    }, [onShowAddArtistEventCallback]);

    const theme = React.useMemo(() => {

        const mainPrimaryColor = prefersDarkMode ? "#A78BFA" : "#7C3AED";
        const mainSecondaryColor = prefersDarkMode ? "#0a84ff" : "#007aff";
        const bgStep50 = prefersDarkMode ? "#1e1e1e" : "#f2f2f2";
        const bgStep100 = prefersDarkMode ? "#2a2a2a" : "#e6e6e6";

        return createMuiTheme({
            palette: {
                primary: {
                    main: mainPrimaryColor,
                },
                secondary: {
                    main: mainSecondaryColor,
                },
                type: prefersDarkMode ? "dark" : "light",
            },
            bgSteps: {
                step50: bgStep50,
                step100: bgStep100,
            },
            typography: {
                button: {
                    textTransform: "none",
                },
            },
            props: {
                MuiButtonBase: {
                    disableRipple: true,
                },
                MuiButton: {
                    disableElevation: true,
                },
            },
            overrides: {
                MuiListItem: {
                    button: {
                        "&:active": {
                            backgroundColor: `${bgStep50} !important`,
                        },
                    },
                },
                MuiButton: {
                    root: {
                        borderRadius: '4px'
                    }
                }
            },
        });
    }, [prefersDarkMode]);

    return (
        <ThemeProvider theme={theme}>
            <IonApp>
                <IonReactRouter>
                    {<WebSplashScreen show={showSplashScreen} />}
                    {isPlatform("capacitor") && <DynamicLinkListener />}
                    {isPlatform("capacitor") && <NotificationListener />}
                    <LocalNotificationListener />
                    {isPlatform("mobileweb") && <WebConversionBanner />}
                    <ExistingSignInMethods />
                    <Suspense
                        fallback={(
                            <IonPage>
                                <IonHeader>
                                    <IonToolbar>
                                        {/* <IonButtons slot="start">
                                        <IonBackButton color="dark" defaultHref={`/${tab}`} />
                                    </IonButtons>
                                    <IonTitle>{data?.artist?.name}</IonTitle> */}
                                    </IonToolbar>
                                </IonHeader>
                            </IonPage>
                        )}>
                        <IonTabs onIonTabsWillChange={onTabsWillChange}>
                            <IonRouterOutlet ref={routerRef}>

                                <PrivateRoute path="/release/:releaseId/connect" component={ConnectAlbum} roles={[RoleName.Admin]} exact />

                                {/* Home */}
                                <Route path="/:tab(home)" component={Home} exact />
                                <Route path="/:tab(home)/release/:releaseId" component={Release} exact />
                                <Route path="/:tab(home)/release/:releaseId/analytics" component={Analytics} exact />
                                <Route path="/:tab(home)/artist/:artistId" component={Artist} exact />
                                <Route path="/:tab(home)/artist/:artistId/releases" component={ArtistReleases} exact />
                                <Route path="/:tab(home)/artist/:artistId/rumors" component={ArtistRumors} exact />

                                {/* Search */}
                                <Route path="/:tab(search)" component={Search} exact />
                                <Route path="/:tab(search)/release/:releaseId" component={Release} exact />
                                <Route path="/:tab(search)/release/:releaseId/analytics" component={Analytics} exact />
                                <Route path="/:tab(search)/artist/:artistId" component={Artist} exact />
                                <Route path="/:tab(search)/artist/:artistId/releases" component={ArtistReleases} exact />
                                <Route path="/:tab(search)/artist/:artistId/rumors" component={ArtistRumors} exact />

                                {/* Create */}
                                <Route path="/:tab(create)" component={CreateForm} exact />
                                <Route path="/:tab(create)/release/:releaseId" component={CreateForm} exact />
                                <Route path="/:tab(create)/artist" component={AddArtist} exact />

                                {/* Feed */}
                                <Route path="/:tab(feed)" component={UpcomingFeed} exact />
                                <Route path="/:tab(feed)/releases" component={ReleasesFeed} exact />
                                <Route path="/:tab(feed)/release/:releaseId" component={Release} exact />
                                <Route path="/:tab(feed)/release/:releaseId/analytics" component={Analytics} exact />
                                <Route path="/:tab(feed)/artist/:artistId" component={Artist} exact />
                                <Route path="/:tab(feed)/artist/:artistId/releases" component={ArtistReleases} exact />
                                <Route path="/:tab(feed)/artist/:artistId/rumors" component={ArtistRumors} exact />

                                {/* User */}
                                <PrivateRoute path="/:tab(profile)" component={User} exact />
                                <PrivateRoute path="/:tab(profile)/:userId/settings" component={UserSettings} exact />
                                <PrivateRoute path="/:tab(profile)/:userId/encore" component={UserEncore} exact />
                                <PrivateRoute path="/:tab(profile)/:userId/rumors" component={UserRumors} exact />
                                <PrivateRoute path="/:tab(profile)/:userId/collection" component={UserCollection} exact />
                                <PrivateRoute path="/:tab(profile)/:userId/following" component={UserFollowing} exact />
                                <PrivateRoute path="/:tab(profile)/release/:releaseId" component={Release} exact />
                                <PrivateRoute path="/:tab(profile)/release/:releaseId/analytics" component={Analytics} exact />
                                <PrivateRoute path="/:tab(profile)/artist/:artistId" component={Artist} exact />
                                <PrivateRoute path="/:tab(profile)/artist/:artistId/releases" component={ArtistReleases} exact />
                                <PrivateRoute path="/:tab(profile)/artist/:artistId/rumors" component={ArtistRumors} exact />

                                {/* Auth */}
                                <Route path="/:tab(auth)/login" component={Login} exact={true} />
                                <Route path="/:tab(auth)/signup" component={SignUp} exact={true} />
                                <Route path="/:tab(auth)/forgot-password" component={ForgotPassword} exact={true} />

                                <Redirect exact from="/r/:releaseId" to="/home/release/:releaseId" />
                                <Redirect exact from="/a/:artistId" to="/home/artist/:artistId" />
                                <Redirect exact from="/" to="/home" />
                            </IonRouterOutlet>
                            <IonTabBar slot="bottom">
                                <IonTabButton tab="home" href="/home">
                                    <IonIcon icon={home} />
                                    <IonLabel>Home</IonLabel>
                                </IonTabButton>
                                <IonTabButton tab="search" href="/search">
                                    <IonIcon icon={search} />
                                    <IonLabel>Search</IonLabel>
                                </IonTabButton>
                                <IonTabButton tab="create" href="#" className={!user ? 'ion-hide' : ''}>
                                    <IonIcon icon={addCircle} />
                                    <IonLabel>Create</IonLabel>
                                </IonTabButton>
                                <IonTabButton tab="feed" href="/feed" className={!user ? 'ion-hide' : ''}>
                                    <IonIcon icon={albums} />
                                    <IonLabel>Feed</IonLabel>
                                </IonTabButton>
                                <IonTabButton
                                    className={!user ? 'ion-hide' : ''}
                                    tab="profile"
                                    href={"/profile"}>
                                    <IonIcon icon={person} />
                                    <IonLabel>Profile</IonLabel>
                                </IonTabButton>
                                <IonTabButton
                                    className={!!user ? 'ion-hide' : ''}
                                    tab="auth"
                                    href={"/auth/login"}>
                                    <IonIcon icon={person} />
                                    <IonLabel>Login</IonLabel>
                                </IonTabButton>
                            </IonTabBar>
                        </IonTabs>
                    </Suspense>
                    <IonModal ref={createModalRef} cssClass="modal-full-width" isOpen={showCreateForm} backdropDismiss={false}>
                        <CheckoutProvider>
                            <CreateForm releaseId={editReleaseId} artistId={createFormArtistId} onShowCreateForm={onShowCreateForm} accountType={user?.type ?? AccountType.Fan} />
                        </CheckoutProvider>
                    </IonModal>
                    <IonModal ref={artistModalRef} cssClass="modal-full-width" swipeToClose={false} isOpen={showAddArtist} onDidDismiss={() => setShowAddArtist(false)}>
                        <AddArtist onShowArtistModal={onShowAddArtist} />
                    </IonModal>
                </IonReactRouter>
            </IonApp>
        </ThemeProvider>
    );
};

export default App;
