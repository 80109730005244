import React, { useEffect } from 'react';
import { LocalNotifications } from '@capacitor/local-notifications';
import { useHistory } from 'react-router-dom';
import { PluginListenerHandle } from '@capacitor/core';

const LocalNotificationListener: React.FC = () => {

    const history = useHistory();

    useEffect(() => {

        let actionPerformedListener: PluginListenerHandle | undefined;

        (async () => {
            actionPerformedListener = await LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction) => {
                const extra = notificationAction.notification.extra;
                if (extra && 'deepLink' in extra) {
                    history.push(extra.deepLink);
                }
            });
        })()

        return () => {
            actionPerformedListener?.remove();
        }
    }, [history]);

    return null;
};

export default LocalNotificationListener;
