import React, { memo, useCallback } from 'react';
import { Close, ChevronLeft } from '@material-ui/icons';
import { CheckoutComponent, CheckoutProviderProps } from './context/CheckoutContext';

const CheckoutHeader: React.FC<CheckoutProviderProps> = memo(({ onGoBack, currentRoute, onClose }) => {

    const getText = useCallback((component: CheckoutComponent) => {
        switch (component) {
            case 'overview':
                return 'Checkout';
            case 'add_card':
                return 'Add Card';
            case 'change_card':
                return 'Change Card';
            case 'payment_method':
                return 'Payment Method';
            case 'total':
                return 'Total';
            case 'change_paypal':
                return 'Change Account';
            case 'add_paypal_account':
                return 'Add Account';
            case 'receipt':
                return 'Receipt';
            case 'error':
                return 'Error';
            default:
                return 'No Header';
        }
    }, []);

    const getHeader = useCallback(() => {
        switch (currentRoute) {
            case "overview":
                return (
                    <div className="flex items-start justify-between w-full mb-5">
                        <div className="text-xl font-bold uppercase">{getText(currentRoute)}</div>
                        <div className="cursor-pointer" onClick={onClose}>
                            <Close />
                        </div>
                    </div>
                )
            case "receipt":
                return (
                    <>
                    </>
                )
            case "error":
                return (
                    <div className="flex items-start justify-between w-full mb-5">
                        <div className="text-xl font-bold uppercase">{getText(currentRoute)}</div>
                    </div>
                )
            default:
                return (
                    <div className="flex items-center w-full mb-5">
                        <div className="cursor-pointer" onClick={() => onGoBack()}>
                            <ChevronLeft />
                        </div>
                        <div className="ml-3 text-xl font-bold uppercase">{getText(currentRoute)}</div>
                    </div>
                )
        }
    }, [currentRoute, getText, onGoBack, onClose])

    return getHeader();
});

export default CheckoutHeader;
