import React, { useEffect, useRef, memo } from 'react'
import braintree from 'braintree-web';

interface Props {
  client: braintree.Client;
  onSubmit: (nonce: string) => Promise<void>
}

const PayPalButton: React.FC<Props> = memo(({ client, onSubmit }) => {

  const paypalButtonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {

    (async () => {
      try {
        const instance = await braintree.paypalCheckout.create({
          client,
        })
        await instance.loadPayPalSDK({
          vault: true
        })
        // @ts-ignore
        window.paypal.Buttons({
          // @ts-ignore
          fundingSource: paypal.FUNDING.PAYPAL,
          createBillingAgreement: () => {
            return instance.createPayment({
              // @ts-ignore
              flow: "vault"
            })
          },
          onApprove: (data: any, actions: any) => {
            return instance.tokenizePayment(data).then(async (payload: any) => {
              // Submit `payload.nonce` to your server
              await onSubmit(payload.nonce)
            });
          },

          onCancel: (data: any) => {
            console.log('PayPal payment cancelled', JSON.stringify(data));
          },

          onError: (err: any) => {
            console.error('PayPal error', err);
          }
        })
          .render(paypalButtonRef.current)
      } catch (error) {
        console.log(error)
      }

    })()
  }, [client, onSubmit])

  return <div ref={paypalButtonRef}></div>
})

export default PayPalButton
