import { IonSpinner } from '@ionic/react';
import React, { useEffect } from 'react';
import { CheckoutProviderProps } from './context/CheckoutContext';
import { sleep } from '../../utils/Helpers';

const CheckoutReceipt: React.FC<CheckoutProviderProps> = ({ status, onSuccess, paymentMethod, product }) => {

    useEffect(() => {
        (async () => {
            if (status?.success) {
                await sleep(3000)
                onSuccess({
                    paymentMethod,
                    product
                })
            }
        })()
    }, [status?.success, onSuccess, paymentMethod, product])

    return (
        <>
            <div className="flex flex-col items-center justify-center">
                <div className="mb-2 text-lg font-bold text-black">Payment Confirmed</div>
                <div className="flex items-center">
                    <IonSpinner name="lines-small" />
                    <div className="ml-1 text-xs opacity-70">Finishing up...</div>
                </div>
            </div>
        </>
    );
};

export default CheckoutReceipt;
