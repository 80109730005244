import React from 'react';
import { ReactComponent as ReleaseHubLogo } from '../assets/social-logos/releasehub.svg';
import { IonSpinner } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';

interface Props {
    show: boolean;
}

const SplashScreen: React.FC<Props> = ({ show }) => {

    return (
        <CSSTransition
            in={show}
            timeout={300}
            classNames="fade"
            unmountOnExit
            
        >
            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center w-full h-full" style={{
                backgroundColor: "var(--ion-background-color)"
            }}>
                <ReleaseHubLogo className="h-10 mb-6 reactive-logo" />
                <IonSpinner color="dark"/>
            </div>
        </CSSTransition>
    );
};

export default SplashScreen;
