import { Drawer, List, ListSubheader, ListItem, ListItemIcon, SvgIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { useAuth } from "../../firebase/Auth";
import { ReactComponent as GoogleLogo } from "../../assets/social-logos/google.svg";
import { ReactComponent as FacebookLogo } from "../../assets/social-logos/facebook.svg";
import { ReactComponent as AppleLogo } from "../../assets/social-logos/apple-black.svg";
import { isPlatform } from "@ionic/react";
import { Email } from '@material-ui/icons';
import { useHistory } from 'react-router';

interface Props {
}

const ExistingSignInMethods: React.FC<Props> = () => {
  const { existingSignInMethods, loginWithAuthProvider, onSetExistingSignInMethods } = useAuth();
  const { push } = useHistory();

  return (
    <Drawer anchor={"bottom"} open={existingSignInMethods.length !== 0} onClose={() => onSetExistingSignInMethods([])}>
      <div role="presentation" onClick={() => onSetExistingSignInMethods([])} onKeyDown={() => onSetExistingSignInMethods([])}>
        <div className="desktop-container">
          <List subheader={<ListSubheader component="div">Email is already in use with the following providers:</ListSubheader>}>
            {existingSignInMethods.includes("google.com") && (
              <ListItem
                button
                onClick={async () => {
                  onSetExistingSignInMethods([]);
                  await loginWithAuthProvider({
                    platform: "google"
                  });
                }}
              >
                <ListItemIcon>
                  <SvgIcon>
                    <GoogleLogo />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary={"Sign in with Google"} />
              </ListItem>
            )}
            {existingSignInMethods.includes("apple.com") && (
              <>
                {!isPlatform("android") && (
                  <ListItem
                    button
                    onClick={async () => {
                      onSetExistingSignInMethods([]);
                      await loginWithAuthProvider({
                        platform: "apple"
                      });
                    }}
                  >
                    <ListItemIcon>
                      <SvgIcon>
                        <AppleLogo />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={"Sign in with Apple"} />
                  </ListItem>
                )}
              </>
            )}
            {existingSignInMethods.includes("password") && (
              <ListItem
                button
                onClick={async () => {
                  onSetExistingSignInMethods([]);
                  push(`/auth/login`)
                }}
              >
                <ListItemIcon>
                  <SvgIcon>
                    <Email />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary={"Sign in with Email"} />
              </ListItem>
            )}
            {existingSignInMethods.includes("facebook.com") && (
              <ListItem
                button
                onClick={() => {
                  onSetExistingSignInMethods([]);
                  // loginWithAuthProvider({
                  //   platform: ""
                  // });
                }}
              >
                <ListItemIcon>
                  <SvgIcon>
                    <FacebookLogo />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary={"Sign in with Facebook"} />
              </ListItem>
            )}
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default ExistingSignInMethods;
