import React, { memo, useCallback } from 'react';
import { ReactComponent as VisaLogo } from 'payment-icons/min/single/visa.svg';
import { ReactComponent as AmExLogo } from 'payment-icons/min/single/amex.svg';
import { ReactComponent as DiscoverLogo } from 'payment-icons/min/single/discover.svg';
import { ReactComponent as MasterCardLogo } from 'payment-icons/min/single/mastercard.svg';
import { ReactComponent as MaestroLogo } from 'payment-icons/min/single/maestro.svg';
import { ReactComponent as UnionPayLogo } from 'payment-icons/min/single/unionpay.svg';
import { ReactComponent as JCBLogo } from 'payment-icons/min/single/jcb.svg';
import { ReactComponent as DefaultLogo } from 'payment-icons/min/outline/default.svg';

type CreditCardType = 'American Express' | 'Carte Blanche' | 'China UnionPay' | 'Discover' | 'Elo' | 'JCB' | 'Laser' | 'Maestro' | 'MasterCard' | 'Solo' | 'Switch' | 'Visa' | 'Unknown' | string;

interface Props {
    type: CreditCardType | null | undefined;
    size: "sm" | "md" | "lg"
}

const CreditCardLogo: React.FC<Props> = memo(({ type, size }) => {

    const getSize = useCallback(() => {
        switch (size) {
            case "sm":
                return "w-auto h-8"
            case "md":
                return "w-auto h-10"
            case "lg":
                return "w-auto h-12"
            default:
                return "w-auto h-8"
        }
    }, [size])

    switch (type) {
        case 'American Express':
            return <AmExLogo className={`${getSize()}`} />;
        case 'China UnionPay':
            return <UnionPayLogo className={`${getSize()}`} />;
        case 'Discover':
            return <DiscoverLogo className={`${getSize()}`} />;
        case 'JCB':
            return <JCBLogo className={`${getSize()}`} />;
        case 'Maestro':
            return <MaestroLogo className={`${getSize()}`} />;
        case 'MasterCard':
            return <MasterCardLogo className={`${getSize()}`} />;
        case 'Visa':
            return <VisaLogo className={`${getSize()}`} />;
        default:
            return <DefaultLogo className={`${getSize()}`} />;
    }
});

export default CreditCardLogo;
