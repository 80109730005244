import crypto from 'crypto-random-string'
import { IAPProduct } from '@ionic-native/in-app-purchase-2';
import { Product } from '../generated/graphql';
import { Storage } from '@capacitor/storage';
import firebase from 'firebase/app';
import 'firebase/auth';

export const PRODUCT_SKUS = process.env.REACT_APP_IN_APP_PURCHASE_PRODUCTS?.split(",") ?? [];

export const clamp = (min: number, n: number, max: number) => {
    return Math.max(min, Math.min(n, max));
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function findAllByKey(obj: Record<string, any>, keyToFind: string): any {
    return Object.entries(obj)
        .reduce((acc, [key, value]) => (key === keyToFind)
            ? acc.concat(value)
            : (typeof value === 'object')
                ? acc.concat(findAllByKey(value, keyToFind))
                : acc
            , [])
}

export const firstNonNullItem = <T>(args: (T | null | undefined)[] | null | undefined) => {
    if (!!args) {
        const items = args.filter(notEmpty);
        if (items.length) {
            return items[0]
        } else {
            return
        }
    } else {
        return
    }
}

export const lastNonNullItem = <T>(args: (T | null | undefined)[] | null | undefined) => {
    if (!!args) {
        const items = args.filter(notEmpty);
        if (items.length) {
            return items[items.length - 1]
        } else {
            return
        }
    } else {
        return
    }
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const dataURLtoBlob = (dataurl: string) => {

    const arr = dataurl.split(',');
    const mimeArray = arr[0].match(/:(.*?);/);

    if (mimeArray) {
        const mime = mimeArray[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
}

//**blob to dataURL**
export const blobToDataURL = (blob: Blob, callback: (result: string | ArrayBuffer | null | undefined) => void) => {
    var a = new FileReader();
    a.onload = function (e) { callback(e.target?.result); }
    a.readAsDataURL(blob);
}

export const generateRandomKey = () => {
    return crypto({
        type: 'alphanumeric',
        length: 10,
    });
}

export const removeInputValuePrefix = (value: string, prefix: string) => {
    if (prefix) {
        value = value.slice(prefix.length)
        if (value[0] === ' ') {
            value = value.slice(1)
        }
    }
    return value
}

export const mapIAPToProduct = (iapProduct: IAPProduct): Product => {
    // android adds the application id to the title in parenthese, so as a hack,
    // using split takes anything before that.
    return {
        name: iapProduct.title?.split("(")[0],
        price: BigInt(iapProduct.priceMicros),
        sku: iapProduct.id
    }
}

export const isTokenExpired = (exp?: number) => {
    if (!exp) {
        return false;
    }

    return Date.now() > exp;
};

export const getTokenExpirationDate = (jwtToken?: string): number | undefined => {
    if (!jwtToken) {
        return;
    }

    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

    // multiply by 1000 to convert seconds into milliseconds
    return (jwt?.exp && jwt.exp * 1000) ?? null;
};

export const getAccessToken = async (force?: boolean) => {

    let _token;

    let { value: token } = await Storage.get({
        key: "accessToken"
    });

    _token = token;

    if (!_token || isTokenExpired(getTokenExpirationDate(_token)) || force) {

        _token = await firebase.app().auth().currentUser?.getIdToken(true);

        if (_token) {
            await Storage.set({
                key: 'accessToken',
                value: _token,
            });
        }
    }

    return _token ?? ""
}

export const formatMoney = (money: bigint | number | null | undefined, maximumFractionDigits: number = 2) => {

    const value = money ? Number((BigInt(money) * BigInt(10000)) / BigInt(1000000)) / Number(10000) : undefined;

    const isNegative = value && Math.sign(value) === -1 ? true : false

    return `${isNegative ? "-" : ""}$${value ? Math.abs(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits
    }) : "0"}`;
}

// formats list of items into grammatical syntax: ex: ['john', 'json', 'jeff'] to john, jason, and jeff.
export const formatStringItems = (items: (string | null | undefined)[] | null | undefined) => {

    if (!!items) {
        let mappedItems = items.flatMap((item) => {
            return !!item ? [item] : []
        })
        return mappedItems.join(', ').replace(/,(?!.*,)/gmi, ' and');
    } else {
        return "N/A";
    }

}